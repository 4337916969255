import React, { useState, useEffect } from "react";
import { InputText } from "commons/input-controls";
import moment from "moment";
import s from "./filters-item.module.scss";

const FiltersItem = (props) => {

    const [filterName, setFilterName] = useState(props.myFilter.name);
    const [myFilter, setMyFilter] = useState(props.myFilter)
    const [tags, setTags] = useState(props.tags ? props.tags.tags : null);
    const [isException, setIsException] = useState(false)

    useEffect(()=>{
        setFilterName(props.myFilter.name)
        setMyFilter(props.myFilter)
        setTags(props.tags ? props.tags.tags : null);
        //setIsException(false)
    }, [props.myFilter, props.tags]);

    const changeFilterName = () =>{

    }

    const addIsException = (isExcpetion) => {
        setIsException(isExcpetion)
    }

    const getFormatDate = (date) => {
        return moment(date).format('DD-MM-YYYY HH:mm')
    }

    const getTagName = (tagUUId) => {
        let h = tagUUId[0]+''+tagUUId[1]
        let not = ''
        let yes = ''
        if(h == '\\!'){
            tagUUId.slice(1);
            tagUUId = tagUUId.replace(/^.{2}/, '')
            not = '-'//props.getValue('WITHOUT_TAG')
        }
        else if(h === '\\*'){
            yes = '+'//props.getValue('WITH_TAG')
        }
        let t = tags ? tags.filter(f => f.uuid === tagUUId) : ''
        let name = (t && t.length) ? t[0].name : ''
        return (name || not || yes) ? (yes+not+name) : ''
    }

    const since = getFormatDate(myFilter.since);

    const till = getFormatDate(myFilter.since);

    const keyWords = myFilter.keywords ? myFilter.keywords.map(k => <span>{k}</span>) : [];

    const getValueByType = (item, type) => {
        switch(type){
            case 'since':
            case 'till':
                return getFormatDate(item);
            case 'tags':
                return item ? getTagName(item) : ''
            case 'type':                
                return item === 'POST' ? props.getValue('POST_2') : props.getValue(item)
            default:
                return props.getValue(item)
        }
    }

    const getClauses = (clauses) => {
        let res = {...clauses}
        let ret = []
        if(Object.values(res).length){
            ret = Object.values(res).map(k => { 
                let sb = k.searchExpression;
                if(k.subclauses){
                    sb += k.subclauses.map(s => {
                        let dr = '\n   '+s.searchExpression
                        if(s.subclauses){
                            dr += '' + s.subclauses.map(t => {
                                let rt = '\n      '+t.searchExpression 
                                return rt
                            })
                        }
                        return dr
                    })
                }
                return sb
            })
        }
        return ret
    }

    const getFilterListItem = (item, index) => {
        let isArray = Array.isArray(item[1])
        if(item[0] === 'clauses') {
            let r = getClauses(item[1]);
            return <div key={index}>
                        <span className={s.labelText}>{props.getValue('TEXT_FILTER')}</span>
                        <div className={s.FiltersItemList}>
                            
                            {r.map((k,i) => <span key={i}>{k}</span>)}
                        </div>
                </div>
        }
        else{
            return <div className={s.FiltersItemBlock} key={index}>
                        <span className={s.labelText}>{props.getValue(item[0].toUpperCase())}</span>
                        <div className={s.FiltersItemList}>
                            {isArray 
                                    ? item[1].map((f, i) => <span key={i+index}>{getValueByType(f, item[0])}</span>) 
                                    : <span key={index}>{getValueByType(item[1], item[0])}</span>}
                        </div>
                    </div> 
        }
    }

    const filtersList = myFilter.map((k, i) => getFilterListItem(k, i))
    return (
        <div className={s.FiltersItem}>
            { filtersList }
        </div>
    )
}

export default FiltersItem