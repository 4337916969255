import { SubscriptionsService } from "../connection/subscription-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_TELEGRAM_ITEMS = "SET-TELEGRAM-ITEMS";
const SET_TELEGRAM_ITEM = "SET-TELEGRAM-ITEM";
const SET_SELECTED_TELEGRAM_ITEM = "SET-SELECTED-TELEGRAM-ITEM";
const SET_SELECTED_TELEGRAM_TOPIC = "SET-SELECTED-TELEGRAM-TOPIC";
const SET_SELECTED_TELEGRAM_SUBTOPIC = "SET-SELECTED-TELEGRAM-SUBTOPIC";
const SET_SUBSCRIPTIONS = "SET-SUBSCRIPTIONS";
const SET_SUBSCRIPTION_ID = "SET-SUBSCRIPTION-ID";
const SET_SUBSCRIBERS = "SET-SUBSCRIBERS";
const SET_SUBSCRIPTION_ALERT_POLICY = "SET-SUBSCRIPTION-ALERT-POLICY";
const SET_REPLACE_SUBSCRIBERS = "SET-REPLACE-SUBSCRIBERS";
const SET_UPDATED = "SET-UPDATED";
const SET_DELETED_POLICIES = "SET-DELETED-POLICIES";
const SET_DELETED_POLICIES_LIST = "SET-DELETED-POLICIES-LIST";
const SET_SUBSCRIPTION_EMAILS = "SET-SUBSCRIPTION-EMAILS";
const SET_DELETE_EMAILS = "SET-DELETE-EMAILS";

const mediaTypes = [
        {name: 'social', title: 'SOCIAL_NETWORKS'},
        {name: 'news_agency', title: 'NEWS_AGENCIES_2'},
        {name: 'print', title: 'PRINT_MAS_MEDIA'},
        {name: 'internet', title: 'WEBSITES'},
        {name: 'tv', title: 'TELEVISION_2'}
]

const telegramPolicies = [
    /*null,
    'EVERY_PUBLICATION_TELEGRAM', 
    'NEGATIVE_SENTIMENT_COUNT_TELEGRAM', 
    'NEUTRAL_SENTIMENT_COUNT_TELEGRAM', 
    'POSITIVE_SENTIMENT_COUNT_TELEGRAM', 
    'UNDEFINED_SENTIMENT_COUNT_TELEGRAM', 
    'NEGATIVE_SENTIMENT_PERCENT_TELEGRAM', 
    'NEUTRAL_SENTIMENT_PERCENT_TELEGRAM', 
    'POSITIVE_SENTIMENT_PERCENT_TELEGRAM', 
    'UNDEFINED_SENTIMENT_PERCENT_TELEGRAM',
    ,
    'EVERY_PUBLICATION_PERCENT_TELEGRAM',*/
    "EVERY_PUBLICATION",
    
    "AGGREGATE_PERCENT",
    "AGGREGATE_COUNT",
    "AGGREGATE_ABSOLUTE_COUNT"
    /*blocked by task [MM20-1122|https://jira.alem.kz/browse/MM20-1122]*/
   /* "EVERY_PUBLICATION_PERCENT",
    "SENTIMENT_PERCENT",*/
];

const metricTypes = [
    {name: 'ABSOLUTE_COUNT', list: 'ABSOLUTE_COUNT'},
    {name: 'COUNT', list: 'COUNT'},
    {name: 'PERCENT', list: 'PERCENT'},
]

const telegramSentiments = [
    null,
    "NEUTRAL",
    "POSITIVE",
    "NEGATIVE",
]

const groupings = [
    {name: 'HOURLY', list: 'HOURS'},
    {name: 'DAILY', list: 'DAYS'},
    {name: 'WEEKLY', list: 'WEEKS'},
    {name: 'MONTHLY', list: 'MONTHS'}
]

const telegramData = {
    telegramItems: null,
    selectedTelegramItem: null,
    telegramItem: null,
    selectedTelegramTopic: null,
    selectedTelegramTopicUUId: null,
    selectedTelegramSubTopic: null,
    tableHeader: ['N', 'TOPIC_NAME', 'SUBTHEME_NAME', 'DATE_OF_CREATION', 'EDIT_DATE', 'SAVED_FILTER_SETTINGS'],
    controlButtons: ["edit", "show", "copy", "delete"],
    telegramSentiments: telegramSentiments,
    subscriptions: [],
    mediaTypes: mediaTypes,
    subscribers: [],
    subscriptionId: null,
    telegramPolicies: telegramPolicies,
    metricTypes: metricTypes,
    groupings: groupings,
    updated: false,
    alertPolicy: null,
    deletedPolicies: [],
    subscriptionEmails: [],
    deleteEmails: []
}

const telegram = (state = telegramData, action) => {
    switch(action.type){
        case SET_TELEGRAM_ITEMS:
            return {...state, telegramItems: action.telegramItems}
        case SET_TELEGRAM_ITEM:
            return {...state, telegramItem: action.telegramItem}
        case SET_SELECTED_TELEGRAM_ITEM:
            return {...state, selectedTelegramItem: action.selectedTelegramItem}
        case SET_SELECTED_TELEGRAM_TOPIC:
            return {...state, selectedTelegramTopic: action.selectedTelegramTopic, selectedTelegramTopicUUId: action.selectedTelegramTopicUUId}
        case SET_SELECTED_TELEGRAM_SUBTOPIC:
            return {...state, selectedTelegramSubTopic: action.selectedTelegramSubTopic}
        case SET_SUBSCRIPTIONS:
            return {...state, subscriptions: action.subscriptions}
        case SET_SUBSCRIPTION_ID: 
            return {...state, subscriptionId: action.subscriptionId}
        case SET_SUBSCRIBERS:
            return {...state, subscribers: [...state.subscribers, action.subscribers]}
        case SET_REPLACE_SUBSCRIBERS:
            return {...state, subscribers: action.subscribers}
        case SET_SUBSCRIPTION_ALERT_POLICY:

            let pol = action.policy //? {...action.policy[0]} : null
            //if(action.subscriptionId) pol.subscriptionId = action && action.subscriptionId
            return {...state, alertPolicy: action.policy}
        case SET_DELETED_POLICIES:
            return {...state, deletedPolicies: [...state.deletedPolicies, action.deletedPolicies]}
        case SET_DELETED_POLICIES_LIST:
            return {...state, deletedPolicies: action.deletedPolicies}
        case SET_SUBSCRIPTION_EMAILS:
            return {...state, subscriptionEmails: (action.emails && action.emails.content) ? action.emails.content : []}
        case SET_UPDATED:
            return {...state, updated: action.updated}
        default:
            return {...state}
    }
}

export default telegram;

export const setUpdated = (updated) => {
    return {
        type: SET_UPDATED,
        updated: updated
    }
}

export const setTelegramItems = (telegramItems) => {
    return {
        type: SET_TELEGRAM_ITEMS,
        telegramItems: telegramItems
    }
}

export const setTelegramItem = (telegramItem) => {
    return {
        type: SET_TELEGRAM_ITEM,
        telegramItem: telegramItem
    }
}

export const setSelectedTelegramItem = (selectedTelegramItem) => {
    return {
        type: SET_SELECTED_TELEGRAM_ITEM,
        selectedTelegramItem: selectedTelegramItem
    }
}

export const setSelectedTelegramTopic = (selectedTelegramTopic, selectedTelegramTopicUUId) => {
    return {
        type: SET_SELECTED_TELEGRAM_TOPIC,
        selectedTelegramTopic: selectedTelegramTopic,
        selectedTelegramTopicUUId: selectedTelegramTopicUUId
    }
}

export const setSelectedTelegramSubTopic = (selectedTelegramSubTopic, selectedTelegramTopicUUId) => {
    return {
        type: SET_SELECTED_TELEGRAM_SUBTOPIC,
        selectedTelegramSubTopic: selectedTelegramSubTopic,
        selectedTelegramTopicUUId: selectedTelegramTopicUUId
    }
}

export const setSubscriptions = (subscriptions) => {
    return {
        type: SET_SUBSCRIPTIONS,
        subscriptions: subscriptions
    }
}

export const setSubscriptionId = (subscriptionId) => {
    return {
        type: SET_SUBSCRIPTION_ID,
        subscriptionId: subscriptionId
    }
}

export const setSubscribers = (subscribers) => {
    return {
        type: SET_SUBSCRIBERS,
        subscribers: subscribers
    }
}

export const setReplaceSubscribers = subscribers => {
    return {
        type: SET_REPLACE_SUBSCRIBERS,
        subscribers: subscribers
    }
}

export const setSubscriptionPolicy = (subscriptionId, policy) => {
    return {
        type: SET_SUBSCRIPTION_ALERT_POLICY,
        policy: policy,
        subscriptionId: subscriptionId
    }
}

export const setDeletedPolicies = (deletedPolicies) => {
    return {
        type: SET_DELETED_POLICIES,
        deletedPolicies: deletedPolicies
    }
}

export const setDeletedPoliciesList = (deletedPolicies) => {
    return {
        type: SET_DELETED_POLICIES_LIST,
        deletedPolicies: deletedPolicies
    }
}

export const setSubscriptionEmails = (subscriptionId, emails) => {
    return {
        type: SET_SUBSCRIPTION_EMAILS,
        emails: emails,
        subscriptionId: subscriptionId
    }
}

export const createSubscription = (topicUUid, filterId, daysLimit, emails, policy = null, /*grouping = null, policyCount = null*/) => {
    return (dispatch) => {
        SubscriptionsService.createSubscription(topicUUid, daysLimit).then(data =>{
            if(filterId) dispatch(attachFiltersToSubscription(data.id, filterId));
            if(policy) dispatch(addAlertPolicy(data.id, policy));
            if(emails && emails.length) dispatch(addEmailToSubscription(data.id, emails))
            dispatch(getSubscriptions())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const createSubtopicSubscription = (topicUUid, subtopicId, filterId, daysLimit, emails, policy /*policy = null, grouping = null, policyCount = null*/) => {
    return (dispatch) => {
        SubscriptionsService.createSubtopicSubscription(topicUUid, subtopicId, daysLimit).then( data => {
            if(filterId) dispatch(attachFiltersToSubscription(data.id, filterId));
            if(policy) dispatch(addAlertPolicy(data.id, policy))
            if(emails) dispatch(addEmailToSubscription(data.id, emails))
            dispatch(getSubscriptions())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateSubscription = (subscriptionId, topicId, subtopicId, filterId, daysLimit, emails, emailsChanged, policy, deletedPolicies, removeFilter) => {
    return (dispatch) => {
        SubscriptionsService.updateSubscription(subscriptionId, topicId, subtopicId, daysLimit).then( data => {
            if(removeFilter) 
                dispatch(deleteFiltersFromSubscription(subscriptionId, filterId))
            else{
                if(filterId) dispatch(attachFiltersToSubscription(subscriptionId, filterId));
            }
            if(policy) dispatch(addAlertPolicy(subscriptionId, policy))
            if(deletedPolicies && deletedPolicies.length){
                dispatch(deleteAlertPolicies(subscriptionId, deletedPolicies))
            }
            if(emailsChanged) {
                dispatch(updateSubscriptionEmails(subscriptionId, emails));
            }
            //if(selectedPolicyName) dispatch(addAlertPolicy(subscriptionId, selectedPolicyName, grouping, policyCount))
            dispatch(getSubscriptions())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateSubtopicSubscription = (subscriptionId, topicId, subtopicId, filterId, daysLimit, emails, emailsChanged, policy, deletedPolicies, removeFilter) => {
    
    return (dispatch) => {
        SubscriptionsService.updateSubtopicSubscription(subscriptionId, topicId, subtopicId, daysLimit).then( data => {
            if(removeFilter) 
                dispatch(deleteFiltersFromSubscription(subscriptionId, filterId))
            else{
                if(filterId) dispatch(attachFiltersToSubscription(subscriptionId, filterId));
            }
            if(policy) dispatch(addAlertPolicy(subscriptionId, policy))
            if(deletedPolicies){
                dispatch(deleteAlertPolicies(subscriptionId, deletedPolicies))
            }
            if(emailsChanged) {
                dispatch(updateSubscriptionEmails(subscriptionId, emails));
            }
            
            //if(selectedPolicyName) dispatch(addAlertPolicy(subscriptionId, selectedPolicyName, grouping, policyCount))
            dispatch(getSubscriptions())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const updateSubscriptionEmails = (subscriptionId, emails) => {
    return (dispatch) => {
        //emailsId.forEach( emailId => {
            SubscriptionsService.updatedSubscriptionEmails(subscriptionId, emails).then( data => {
                dispatch(getSubscriptionEmails(subscriptionId))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        //})
        
    }
}

export const deleteSubtopicSubscriptionAndCreateSubscription = (subscriptionId, topicUUid, filterId, policy = null, grouping = null, policyCount = null) => {
    return (dispatch) => {
        SubscriptionsService.deleteSubscription(subscriptionId).then( data => {
            dispatch(createSubscription(topicUUid, filterId, policy, grouping, policyCount))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getSubscriptions = () => {
    return (dispatch) => {
        SubscriptionsService.getSubscriptions().then(data=>{
            dispatch(setTelegramItems(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getSubscriptionEmails = (subscriptionId) => {
    return (dispatch) => {
        dispatch(setUpdated(true))
        SubscriptionsService.getSubscriptionEmails(subscriptionId).then(data => {
            dispatch(setSubscriptionEmails(subscriptionId, data));
            dispatch(setUpdated(false));
        }).catch(error => {
            dispatch(setUpdated(false));
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const deleteSubscription = (subscriptionId) => {
    return (dispatch) => {
        SubscriptionsService.deleteSubscription(subscriptionId).then(data=>{
            dispatch(getSubscriptions())
        }).catch(error => {
            dispatch(setUpdated(false))
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const addSubscriberToSubscription = (subscriptionId) => {
    return (dispatch) => {
        SubscriptionsService.addSubscriberToSubscription(subscriptionId).then(data => {
            dispatch(setSubscribers(data))
        }).catch(error => {
            dispatch(setUpdated(false))
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const getSubscriptionAlertPolicies = (subscriptionId) => {
    return (dispatch) => {
        dispatch(setUpdated(true))
        SubscriptionsService.getSubscriptionAlertPolicies(subscriptionId).then(data => {
            dispatch(setSubscriptionPolicy(subscriptionId, data))
            dispatch(setUpdated(false))
        }).catch(error => {
            dispatch(setUpdated(false))
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const attachFiltersToSubscription = (subscriptionId, filterId) => {
    return (dispatch) => {
        SubscriptionsService.attachFiltersToSubscription(subscriptionId, filterId).then(data => {
            getSubscriptions()
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const deleteFiltersFromSubscription = (subscriptionId, filterId) => {
    return (dispatch) => {
        SubscriptionsService.removeFilterFromSubscription(subscriptionId, filterId).then(data => {
            
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

const getPolicyName = (alertPolicy) => {
    return alertPolicy.includes('AGGREGATE') ? 'AGGREGATE' : alertPolicy
    
}

export const addAlertPolicy = (subscriptionId, policies) => {
    return (dispatch) => {
        policies.forEach((policy) => {
            
            SubscriptionsService.addAlertPolicy(subscriptionId, getPolicyName(policy.alertPolicy), policy.deliveryType, policy.target, policy.sentiment, policy.grouping, policy.metricType, policy.policyStartTime).then(data => {
                dispatch(setSubscriptionPolicy(subscriptionId, [data]))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        });
    } 
}

export const copySubscription = (subscriptionId, topicUUid, subtopicUIId = null, filterId, daysLimit, alertPolicy) => {
    return (dispatch) => {
        SubscriptionsService.getSubscriptionAlertPolicies(subscriptionId).then(data => {
            if(data){
                let policy = null;
                let grouping = null;
                let policyCount = null;
                if(data.length){
                    policy = data[0].policyType
                    grouping = data[0].grouping
                    policyCount = data[0].target ? data[0].target : null
                }
                //(topicUUid, filterId, daysLimit, emails, policy = null, 
                if(!subtopicUIId) dispatch(createSubscription(topicUUid, filterId, daysLimit, [], alertPolicy));
                //topicUUid, subtopicId, filterId, daysLimit, emails, policy
                else dispatch(createSubtopicSubscription(topicUUid, subtopicUIId, filterId, daysLimit, [], alertPolicy))
            }
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const deleteAlertPolicies = (subscriptionId, policies) => {
    return (dispatch) => {
        policies.forEach((policy)=>{
            SubscriptionsService.deleteAlertPolicy(subscriptionId, getPolicyName(policy.policy), policy.id).then(data => {
                dispatch(setDeletedPoliciesList([]))
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            })
        });
    }
}

export const addEmailToSubscription = (subscriptionId, emails) => {
    return (dispatch) => {
        SubscriptionsService.addSubscriptionEmails(subscriptionId, emails).then(data => {
            dispatch(getSubscriptionEmails(subscriptionId));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
    }
}

export const resumePauseSubscription = (subscriptionId, type) => {
    return (dispatch) => {
        SubscriptionsService.resumePauseSubscription(subscriptionId, type).then( data => {
            dispatch(getSubscriptions())
        })
    }
}