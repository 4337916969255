import React from "react";
import { useField } from 'formik';
import s from "../assets/styles/input-block.module.scss";
import b from "../assets/styles/standart-button.module.scss"
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg";

export const InputField = ({label, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error;
    return(
        <div className={hasError ? s.inputBlock+' '+s.inputError : s.inputBlock}>
            <FormField {...props}><input autoComplete="off" {...field} {...props} placeholder={label}></input></FormField>
            {hasError && (<span className={s.formSummaryError}>{meta.error}</span>)}
        </div>
    )
}

export const TextareaField = ({label, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error;
    return(
        <div className={hasError ? s.textareaBlock+' '+s.inputError : s.textareaBlock}>
            <FormField {...props}><textarea {...field} {...props} placeholder={label} /></FormField>
            {hasError && (<span className={s.formSummaryError}>{meta.error}</span>)}
        </div>    
    )
}

export const InputPasswordField = ({label, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error;
    return(
        <div className={hasError ? s.inputBlock+' '+s.inputError : s.inputBlock}>
            <FormField {...props}>
                <input autoComplete="off" {...field} name={props.name} type={!props.showPassword ? 'password' : 'text'} placeholder={label}></input>
                <ShowPassword setFieldValue={props.setFieldValue} {...props} {...field} />
            </FormField>
            {hasError && (<span className={s.formSummaryError}>{meta.error}</span>)}
        </div>
    )
}

export const FormField = (props) => {
    return (
        <div className={s.inputWrapper}>
            {props.children}
        </div>
    )
}

export const ShowPassword = ({...props}) => {
    let showPass = (e) => {
        props.onShowPassword(props.name);
        props.handleChange(e)

    }
    return (
        <div className={s.showPasswordCheckbox}>
            <label htmlFor={"show"+props.name}><EyeIcon className={props.showPassword ? s.showPassword : ''}/></label>
            <input id={"show"+props.name} name="checkbox" type="checkbox" onChange={showPass} checked={props.showPasword}/>
        </div>
    )
}

export const StandartButton = ({...props}) => {

    let ButtonIcon = props.icon ? props.icon : null;
    let className = "";
    let buttonClassName = b.standartButton;
    let buttonStyle = {};
    if(props.fill && props.fill === 'white') className += b.whiteFill;
    if(props.stroke === 'white' || props.stroke === "#FFFFFF") className += b.whiteStroke; else if(props.stroke === 'dark') className += b.darkStroke;
    if(props.setClass) buttonClassName += " "+b[props.setClass];
    if(!props.title) buttonClassName += " "+b.short;
    if(props.width) buttonStyle.width = `${props.width}px`;
    if(props.height) buttonStyle.height = `${props.height}px`;
    if(props.height && props.height < 20) buttonStyle.padding = `4px`;
    if(props.height && props.height < 20) buttonStyle.borderRadius = `4px`;
    if(props.color) buttonStyle.color = props.color;
    if(props.background){
        buttonStyle.background = props.background;
        if(props.background === 'white'){
            buttonStyle.color = '#1D1D46';
        }
        else if(props.background === 'var( --tableItem )'){
            buttonStyle.color = 'var(--caption)';
        }
    }
    if(props.selected){
        buttonStyle.background = 'var(--selectedButton)';
        buttonStyle.color = 'white'
    } 
 
    let onButtonClick = () => {
        props.callback && props.callback()
    }
    return  <button disabled={props.disabled} type={props.type} className={buttonClassName} onClick={onButtonClick} style={buttonStyle !== '' && buttonStyle} title={props.name}>
                {ButtonIcon && <ButtonIcon stroke={props.stroke && 'white'} className={className}/>}
                {props.title}
            </button>
}