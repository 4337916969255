import React from "react";
import s from "../side-bar.module.scss";
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const RightMenu = (props) => {
    let rightMenu = props.rightMenuComponent && props.rightMenuComponent.map((c, i) => <div className={s.rightBlock} key={i}>{ i !== 0 && c.componentTitle ? <div className={s.Header}><span className={s.RightBlockTitle}>{c.componentTitle}</span></div> : null}{c.component}</div>)
    const setShowRightMenu = () => {
        props.closeMenu ? props.closeMenu('close') : null
        props.setShowRightMenu(false);
    }
    return (
        <div className={props.showMenu ? s.RightMenu+' '+s.showMenu : s.RightMenu}>
            {props.withHeader 
                ?   <div className={s.RightBlock}>
                        <div className={s.Header}>
                            <span className={s.RightBlockTitle}>{props.rightMenuTitle}</span>
                            <button className={s.Close} onClick={setShowRightMenu}><CloseIcon /></button>
                        </div>
                        {rightMenu}
                    </div> 
                : rightMenu}           
        </div>
    )
}

export default RightMenu;