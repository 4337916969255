import React, { useState, useEffect } from "react";
import { StandartButton } from "commons/form-controls";
import ControlButtons from "items/control-buttons/control-buttons"
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import CompanyTopicsTableContainer from "./company-topics-table/company-topics-table-container";
import CompaniesTableContainer from "./companies-table/companies-table-container";
import s from "../admin.module.scss";
import AdminAccountsContainer from "../admin-accounts/admin-accounts-container";
import CompanyContainer from "./company/company-container";

const AdminCompanies = (props) => {

    const [companies, setCompanies] = useState(props.companies ? props.companies : null);
    const [topics, setTopics] = useState(props.topics ? props.topics : null)
    const [companyId, setCompanyId] = useState(props.isSuperadmin && props.adminCompanyId ? props.adminCompanyId : props.companyId)
    const [accounts, setAccounts] = useState(null)

    useEffect(()=>{
        setCompanies(props.companies ? props.companies : null);
        //setCompanyTopics(props.companyTopics ? props.companyTopics : null)
        setCompanyId(props.isSuperadmin && props.adminCompanyId ? props.adminCompanyId : props.companyId)
        setTopics(props.topics ? props.topics : null);
        setAccounts(props.accounts ? props.accounts : null);
    }, [props.companies ,props.companyId, props.isSuperadmin, props.adminCompanyId])

    const getCompanyContainer = (role) => {
        
        switch(role){
            case 'ROLE_SUPER_ADMIN':
                return <CompaniesTableContainer />
            case 'ROLE_ADMIN':
                return <div>
                    {/*<CompanyContainer />*/}
                </div>
        }
    }


    return (
        <div className={s.Admin}>
            <div className={s.companiesList}>
                <div>
                    {getCompanyContainer(props.role)}
                </div>
            </div>
            { companyId ? <div>
                                <div className={s.titleRow}>
                                    {props.getValue('COMPANY')}{' '}
                                    {<CompanyContainer />}
                                </div>
                                <div className={s.tableBlock}>
                                    <span>{props.getValue('ACCOUNTS')}{' '}{props.companyName}</span>
                                    <AdminAccountsContainer/>
                                    {props.accountId ? 
                                    <div className={s.tableBlock}>
                                        <span>{props.getValue('ACCOUNT_TOPICS')}</span>
                                        <CompanyTopicsTableContainer isAccountTopics={true}/>
                                    </div>
                                    : null}
                                </div>
                                <div className={s.tableBlock}>
                                    <span>{props.getValue('COMPANY_TOPICS')}{' '}{props.companyName}</span>
                                    <CompanyTopicsTableContainer />
                                    {props.topicId ? 
                                        <div className={s.tableBlock}>
                                            <span>{props.getValue('TOPIC_ACCOUNTS')}</span>
                                            <AdminAccountsContainer isTopicAccounts={true}/>
                                        </div> 
                                        : null}
                                </div>
                            </div>
             : null }
        </div>
    )
}

export default AdminCompanies;