import ShortAnalyticPageController from "./short-analytic-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../hoc/AuthRedirect";
import { getValue } from "../../store/languages";
import { getMyTopics, setThemeTableData } from "../../store/theme-settings";
import { getTopicDropdownFromState, getTopics } from "../../selectors/theme-selector";
import { getShortAnalytic } from "../../store/short-analytic";
import { getSubtopics, setSubtopicsUpdated, setSubtopicItem, setSubtopics } from "../../store/topic-item";
import { setTreeSelectedUUId, setToNull } from "store/tree";

let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        topicData: getTopics(state),
        language: state.languages.selectedLanguage,
        //data: state.ShortAnalytic.data,
        headerItems: state.ShortAnalytic.headerItems,
        /*dataTotal: state.ShortAnalytic.dataTotal,
        dataSocial: state.ShortAnalytic.dataSocial,
        dataMedia: state.ShortAnalytic.dataMedia,*/
        isShortAnalyticFetching: state.ShortAnalytic.isShortAnalyticFetching,
        toNull: state.Tree.toNull
    }
}

export default compose(
    connect(mapStateToProps, {getValue, getMyTopics, getShortAnalytic, setThemeTableData, setSubtopics, setTreeSelectedUUId, setToNull}),
    withAuthRedirect
)(ShortAnalyticPageController)