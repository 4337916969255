import { TopicsComparisionService } from "connection/topics-comaprision";
import { StorageService } from "../connection/storage-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
import Menu from "assets/menu/menu";

const SET_RANGE_DATA = "SET-RANGE-DATA";
const SET_SENTIMENT_DATA = "SET-SENTIMENT-DATA";
const SET_LANGUAGE_DATA = "SET-LANGUAGE-DATA";
const SET_SPECIAL_MARKS_DATA = "SET-SPECIAL-MARKS-DATA";
const SET_MEDIA_TYPE_DATA = "SET-MEDIA-TYPE-DATA";
const SET_MEDIA_SCALES_DATA = "SET-MEDIA-SCALES-DATA";
const SET_PROFILE_LOCATIONS = "SET-PROFILE-LOCATIONS";
const SET_COMPARISON_UPDATED = "SET-COMPARISON-UPDATED";

const SET_ANALYTIC_IS_FETCHING = "SET-ANALYTIC-IS-FETCHING";

const AnalyticData = {
    //rangeData: [],
    sentimentData: [],
    languageData: [],
    specialMarks: [],
    mediaTypes: [],
    mediaScales: [],
    profileLocations: [],
    isFetchingAnalytic: false,
    comparisonUpdated: false
}

let Analytic = (state = AnalyticData, action) =>{
    switch(action.type){
        case SET_RANGE_DATA:
            return {...state, rangeData: action.rangeData}
        case SET_SENTIMENT_DATA:
            return {...state, mediaTypes: action.sentimentData}
        case SET_LANGUAGE_DATA:
            return {...state, languageData: action.languageData}
        case SET_SPECIAL_MARKS_DATA:
            return {...state, specialMarks: action.specialMarks}
        case SET_MEDIA_TYPE_DATA:
            let data = {}
            data.data = action.mediaTypes;
            data.dataType = action.dataType;
            data.topicIDs = action.topicIDs;
            return {...state, mediaTypes: data}
        case SET_MEDIA_SCALES_DATA:
            return {...state, mediaScales: action.mediaScales}
        case SET_PROFILE_LOCATIONS:
            return {...state, profileLocations: action.profileLocations}
        case SET_ANALYTIC_IS_FETCHING:
            return {...state, isFetchingAnalytic: action.isFetchingAnalytic}
        case SET_COMPARISON_UPDATED:
            return {...state, comparisonUpdated: action.updated}
        default:
            return {...state}
    }
}

export default Analytic;

export const setComparisonUpdated = (updated) => {
    return {
        type: SET_COMPARISON_UPDATED,
        updated: updated
    }
}

export const setRangeData = (data) => {
    return {
        type: SET_RANGE_DATA,
        rangeData: data
    }
}

export const setSentimentData = (data, type) => {
    return {
        type: SET_SENTIMENT_DATA,
        sentimentData: data,
        dataType: type
    }
}

export const setLanguageData = (data) => {
    return {
        type: SET_LANGUAGE_DATA,
        languageData: data
    }
}

export const setSpecialMarksData = (data) => {
    return {
        type: SET_SPECIAL_MARKS_DATA,
        specialMarks: data
    }
}

export const setMediaTypeData = (data, type, topicIDs) => {
    return {
        type: SET_MEDIA_TYPE_DATA,
        mediaTypes: data,
        dataType: type,
        topicIDs: topicIDs
    }
}

export const setMediaScalesData = (data) => {
    return {
        type: SET_MEDIA_SCALES_DATA,
        mediaScales: data
    }
}

export const setProfileLocationsData = (data) => {
    return {
        type: SET_PROFILE_LOCATIONS,
        profileLocations: data
    }
}

export const setAnalyticIsFetching = (isFetching) => {
    return {
        type: SET_ANALYTIC_IS_FETCHING,
        isFetchingAnalytic: isFetching
    }
}

export const getTopicComparisonData = (type, selectedType, topicsIds, since, till, grouping, filters, isNotMatched) => {
    
    return (dispatch) => {
        //dispatch(setComparisonUpdated(true));
        
        dispatch(setAnalyticIsFetching(false))
        switch(type){
            case 'MEDIA_TYPES':
                dispatch(getComparisonMediaTypes(selectedType, topicsIds, since, till, grouping, filters, isNotMatched));
                break;
            case 'SENTIMENTS':
                dispatch(getComparisonSentiment(selectedType, topicsIds, since, till, grouping, filters, isNotMatched));
                break;
            case 'SPECIAL_MARKS':
                dispatch(getComparionSpecialMarks(selectedType, topicsIds, since, till, grouping, filters, isNotMatched));
                break;
            case 'LANGUAGES':
                dispatch(getComparisonLanguages(selectedType, topicsIds, since, till, grouping, filters, isNotMatched));
                break;
            case 'MEDIA_SCALES':
                dispatch(getMediaScales(selectedType, topicsIds, since, till, grouping, filters, isNotMatched));
                break;
            case 'PROFILE_LOCATIONS':
                dispatch(getProfileLocations(selectedType, topicsIds, since, till, grouping, filters, isNotMatched));
                break;
            default:
                dispatch(getComparisonMediaTypes(selectedType, topicsIds, since, till, grouping, filters, isNotMatched));
                break;
        }
        
        
    }
}

export const getComparisonSentiment = (type, topicId, since, till, grouping, filters) => {
    return (dispatch) => {
        TopicsComparisionService.getComparisionSentiments(type, topicId, since, till, grouping, filters).then(data => {
            dispatch(setMediaTypeData(data, 'SENTIMENTS', topicId));
            dispatch(setComparisonUpdated(true))
            dispatch(setAnalyticIsFetching(false))
        }).catch( error => {    

        })
    }
}

export const getComparionSpecialMarks = (type, topicId, since, till, grouping, filters) => {
    return (dispatch) => {
        TopicsComparisionService.getComparisionSpecialMarks(type, topicId, since, till, grouping, filters).then(data => {
            dispatch(setMediaTypeData(data, 'SPECIAL_MARKS', topicId));
            dispatch(setComparisonUpdated(true))
            dispatch(setAnalyticIsFetching(false))
        }).catch( error => {    
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setAnalyticIsFetching(false))
        })
    }
}

export const getComparisonMediaTypes = (type, topicId, since, till, grouping, filters, isNotMatched) => {
    return (dispatch) => {
        TopicsComparisionService.getComparisonMediaTypes(type, topicId, since, till, grouping, filters, isNotMatched).then(data => {
            dispatch(setMediaTypeData(data, 'MEDIA_TYPES', topicId));
            dispatch(setComparisonUpdated(true))
            dispatch(setAnalyticIsFetching(false))
            //dispatch(setComparisonUpdated(false))
        }).catch( error => {    
            dispatch(setComparisonUpdated(false))
            //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            //dispatch(setAnalyticIsFetching(false))
        })
    }
}

export const getComparisonLanguages = (type, topicId, since, till, grouping, filters) => {
    return (dispatch) => {
        TopicsComparisionService.getComparisionLanguages(type, topicId, since, till, grouping, filters).then(data => {
            dispatch(setAnalyticIsFetching(false))
            dispatch(setComparisonUpdated(true))
            dispatch(setMediaTypeData(data, 'LANGUAGES', topicId));
            //dispatch(setAnalyticIsFetching(false))
        }).catch( error => {    
            //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setAnalyticIsFetching(false))
        })
    }
}

export const getMediaScales = (type, topicId, since, till, grouping, filters) => {
    return (dispatch) => {
        TopicsComparisionService.getComparisionMediaScales(type, topicId, since, till, grouping, filters).then(data => {
            dispatch(setMediaTypeData(data, 'MEDIA_SCALES', topicId));
            dispatch(setComparisonUpdated(true))
            dispatch(setAnalyticIsFetching(false))
        }).catch( error => {    
            //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            //dispatch(setAnalyticIsFetching(false))
        })
    }
}

export const getProfileLocations = (type, topicId, since, till, grouping, filters) => {
    return (dispatch) => {
        TopicsComparisionService.getComparisionProfileLocations(type, topicId, since, till, grouping, filters).then(data => {
            dispatch(setMediaTypeData(data, 'PROFILE_LOCATIONS', topicId));
            dispatch(setComparisonUpdated(true))
            dispatch(setAnalyticIsFetching(false))
        }).catch( error => {    
            //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            //dispatch(setAnalyticIsFetching(false))
        })
    }
}
