import React, {useState, useEffect} from "react";
import s from "./digest-month-days.module.scss"
import { InputNumber } from "../../../../../commons/input-controls";
import { StandartButton } from "../../../../../commons/form-controls";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import DayMonthItem from "./day-month-item/day-month-item";

const DigestMonthDays = (props) => {

    const [dayButtons, setDayButtons] = useState(props.monthDays);
    const [dayButtonValue, setDayButtonValue] = useState(1);

    useEffect(()=>{
        setDayButtons(props.monthDays)
    })

    const setNewButtonValue = (value) => {
        setDayButtonValue(value)
    }

    const addDaysButtons = () => {
        let n = dayButtons;
        if(n.indexOf(dayButtonValue) === -1){
            props.addDayMonth(dayButtonValue)
        }
    }

    const removeDayButton = (item) => {
        props.removeDayMonth(item)
    }

    const DayButtonsList = dayButtons.map((k,i)=>{return <DayMonthItem key={i} item={k} index={i+1} removeDayButton={removeDayButton}/>})

    return (
        <div className={s.DigestMonthDays}>
            <div className={s.DigestRow}>
                <InputNumber value={dayButtonValue} max={31} min={1} callback={setNewButtonValue}/> <StandartButton title={props.getValue('ADD')} icon={PlusIcon} fill={'white'} callback={addDaysButtons}/>
            </div>
            <div className={s.DaysBlock}>
                {DayButtonsList}
            </div>
        </div>
    )
}

export default DigestMonthDays;