import React, {useState, useEffect} from "react";
import s from "./dropdown-with-search.module.scss";
import Badge from "../badge/badge";
import { ReactComponent as AngelDown } from '../../assets/icons/angle-down.svg';

import DropdownItems from "./dropdown-items/dropown-items";

const DropdownWithSearch = (props) => {

    const [indexKey, setIndexKey] = useState(props.indexkey)

    const [items, setItems] = useState(props.items);
    const [checked, setChecked] = useState(false);
    const [selected, setSelected] = useState([{indexKey: props.indexkey}]);

    useEffect(() => {
        setItems(props.items);
        setIndexKey(props.indexkey)
      }, [props.items]);


    const setSelectedItems = (item, indexKy) => {
        props.setFiltersItemsSelected(item, indexKy)
    }

    const setSelectedItemItems = (item, indexKy, kIndex) => {
        props.setFiltersItemsItemsSelected(item, indexKy, kIndex)
    }

    return (
        
        <div className={s.DropdownWithSearch}>

            <div className={s.DropdownWithSearchBody} >
               {
                <DropdownItems 
                    filters={props.filters} 
                    isChanged={props.isChanged} 
                    indexKey={props.indexkey} 
                    selectedItemItems={props.selectedItemItems} 
                    selected={selected} clearAll={props.clearAll} 
                    setSelectedItems={setSelectedItems} 
                    setSelectedItemItems={setSelectedItemItems}
                    setMySelectedFilter={props.setMySelectedFilter}
                    setAddSelectedMyFilter={props.setAddSelectedMyFilter}
                    applyedFilters={props.applyedFilters}
                    getValue={props.getValue} 
                    items={items} 
                    setClearSelected={props.setClearSelected}
                    selectedMyFilter={props.selectedMyFilter}
                    exceptions={props.exceptions}
                    name={props.name}
                    filterTags={props.filterTags}
                    setSelectedTopics={props.setSelectedTopics}
                    setException={props.setException}
                    isException={props.isException}
                    pageName={props.pageName}
                    withSelectAll={props.withSelectAll}
                    locations={props.locations}
                />
                }
            </div>
        </div>
        )
}

export default DropdownWithSearch;