import React from "react";
import Datepicker from "./datepicker";
import moment from "moment";

class DatepickerController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            startDate: moment(props.startDate).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(props.endDate).format("YYYY-MM-DD HH:mm:ss"),
            selectedStartDate: moment(props.startDate).format("YYYY-MM-DD HH:mm:ss"),
            selectedEndDate: moment(props.endDate).format("YYYY-MM-DD HH:mm:ss"),
            disabledButton: true,
            format: ''
        }
    }

    closeDatePicker = () => {
        this.props.modalShowToggle('false')
    }

    getMonth = (date) => {
        return moment(date).format('M')
    }

    getMonthYear = (date) => {
        return moment(date).format('M-YYYY')
    }

    getFormatDate = (date) => {
        return moment(date).format('DD-MM-YYYY')
    }

    getStartWeekDay = (isFirst) => {
        let day;
        let first = this.getMonth(this.state.startDate);
        let second = this.getMonth(this.state.endDate);
        
        if(isFirst){
             if(isFirst && moment(this.state.startDate).startOf('month').isAfter(moment(this.state.endDate).startOf('month').subtract(1, 'months'))){ 
                
                let d = moment(this.state.startDate).subtract(1, 'months').format("YYYY-MM-DD HH:mm:ss")

                //if(!isFirst){
                    this.setState({
                        //selectedStartDate: d,
                        startDate: d
                    })
                //}

                
                day = Number(moment(d, "YYYY-MM-DD HH:mm:ss").startOf('month').format('d'));
                
            }
            else{
                day = Number(moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").startOf('month').format('d'))
            }
        }
        else{
            
            if(second === first){
                day = Number(moment().startOf('month').format('d'));
            }
            else{
                let d = moment(this.props.endDate).add(1, 'months').format("YYYY-MM-DD HH:mm:ss")
                
                day = Number(moment(this.state.endDate, "YYYY-MM-DD HH:mm:ss").startOf('month').format('d'))
            }
        }
        
        return day === 0 ? 7 : day
    }

    previousMonth = (month) => {
        let previousMonth = this.getMonth(this.state.startDate)
    }

    selectStartDay = (day, month, year) => {
        let isFirst = this.state.selectedStartDate && this.state.selectedEndDate ? true : false
        let startDay = isFirst ? moment(year+'-'+month+'-'+(day < 10 ? '0'+day : day)).format('YYYY-MM-DD HH:mm:ss') : this.state.selectedStartDate;
        let endDay = isFirst ? null : moment(year+'-'+month+'-'+(day < 10 ? '0'+day : day))
        
        if(endDay){
            endDay.set('hour', '23');
            endDay.set('minute', '59');
            endDay.set('second', '59');
            endDay = endDay.format('YYYY-MM-DD HH:mm:ss');
        } 

        if(moment(endDay).isBefore(moment(startDay))){
            return
        }
        this.setState({
            selectedStartDate: startDay,
            selectedEndDate: endDay
        })
        if(!isFirst){
            this.setState({
                //startDate: startDay,
                //endDate: endDay,
                selectedStartDate: startDay,
                selectedEndDate: endDay
            })
        }
        else{
            this.setState({
                selectedStartDate: startDay,
                selectedEndDate: endDay
            })
        }
        this.setState({
            disabledButton: false
        })
        /*if(startDay && endDay){
            this.setState({
                startDate: startDay,
                endDate: endDay
            })
        }*/
    }

    selectEndDay = (daySelected) => {

        this.setState({
            endDate: moment(this.state.endDate).set('date', daySelected).format('YYYY-MM-DD HH:mm:ss')
        })
    }

    nextPreviousStartMonth = (type) => {
        let m = this.getMonthYear(this.state.startDate)
        m = moment(m).set("date", 2)
        if(type === 'next' && m === this.getMonthYear(moment().subtract(1, 'months'))){
            return
        }
        else if(type === 'previous' && moment(this.state.startDate).isBefore(moment("02-01-"+this.props.minYear).format("YYYY-MM-DD"))){
            return
        }
        /*else if(type === 'previous' && this.state.startDate){

        }*/

        let nextMonth = type === 'next' ? moment(this.state.startDate).add(1, 'months').format('YYYY-MM-DD HH:mm:ss')
                                        : moment(this.state.startDate).subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss');

        this.setState({
            startDate: nextMonth,
        })
        if(moment(nextMonth).format("YYYY-MM") === moment(this.state.endDate).format("YYYY-MM")){
            this.setState({
                endDate: type === 'next' ? moment(this.state.endDate).add(1, 'months').format('YYYY-MM-DD HH:mm:ss') : moment(this.state.endDate).subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss')
            })
        }
    }

    nextPreviousEndMonth = (type) => {
        if(type === 'next' && this.getMonthYear(this.state.endDate) === this.getMonthYear(moment())){
            return
        }
        else if(type === 'previous' && moment(this.state.endDate).isBefore(moment("03-02-"+this.props.minYear).format("YYYY-MM-DD"))){
            return
        }
        let nextMonth = type === 'next' ? moment(this.state.endDate).add(1, 'months').format('YYYY-MM-DD HH:mm:ss')
                                        : moment(this.state.endDate).subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss')
        this.setState({
            endDate: nextMonth
        })
        if(moment(nextMonth).format("YYYY-MM") === moment(this.state.startDate).format("YYYY-MM")|| moment(nextMonth).isBefore(moment(this.state.startDate))){
            this.setState({
                startDate: type === 'next' ? moment(this.state.endDate).subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss') : moment(this.state.startDate).subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss')
            })
        }
    }

    setStartYear = (dateSelected) => {
        this.setState({
            startDate: moment(this.state.startDate).set('year', dateSelected)//.format('YYYY-MM-DDTHH:mm:ss')+'Z'
        })
    }

    setEndYear = (dateSelected) => {
        let year = moment(this.state.endDate).set('year', dateSelected)//.format('YYYY-MM-DDTHH:mm:ss')+'Z';
        if(dateSelected === this.props.minYear && year.month() === 0){
            year.add(1, 'months')
        }
        this.setState({
            endDate: year
        })
    }

    setStartTime = (type, time) => {
        if(type === 'first'){
            let hour = moment(this.state.selectedStartDate).set('hour', time);
            this.setState({
                startDate: hour.format('YYYY-MM-DD HH:mm:ss'),
                selectedStartDate: hour.format('YYYY-MM-DD HH:mm:ss')
            })
        }
        else{
            let minute = moment(this.state.selectedStartDate).set('minute', time);
            this.setState({
                startDate: minute.format('YYYY-MM-DD HH:mm:ss'),
                selectedStartDate: minute.format('YYYY-MM-DD HH:mm:ss')
            })
        }
        this.setState({
            disabledButton: false
        })
    }

    setEndTime = (type, time) => {
        if(type === 'first'){
            let hour = moment(this.state.selectedEndDate).set('hour', time);
            this.setState({
                endDate: hour.format('YYYY-MM-DD HH:mm:ss'),
                selectedEndDate: hour.format('YYYY-MM-DD HH:mm:ss'),
            })
        }
        else{
            let minute = moment(this.state.selectedEndDate).set('minute', time);
            this.setState({
                endDate: minute.format('YYYY-MM-DD HH:mm:ss'),
                selectedEndDate: minute.format('YYYY-MM-DD HH:mm:ss'),
            })
        }
        this.setState({
            disabledButton: false
        })
    }

    isMonthlyGrouping = (startDay, endDay) => {
        let e = endDay
        let s = moment(startDay).add(2, 'months')
        let ret = moment(e).isAfter(s) ? true : false

        return ret
    }

    isWeeklyGrouping = (startDay, endDay) => {
        let e = endDay
        let s = moment(startDay).add(1, 'months')
        let ret = moment(e).isAfter(s) ? true : false

        return ret
    }

    isDailyGrouping = (startDay, endDay) => {
        let e = endDay
        let s = moment(startDay).add(48, 'hours')
        let ret = moment(e).isAfter(s) ? true : false

        return ret
    }

    isHourGrouping = (startDay, endDay) => {
        let e = endDay
        let s = moment(startDay).add(48, 'hours')
        let ret = moment(e).isBefore(s) ? true : false

        return ret
    }

    setGrouping = (startDay, endDate) => {
        if(this.isMonthlyGrouping(startDay, endDate)){
            this.props.setGrouping("MONTHLY");
        }
        else if(this.isWeeklyGrouping(startDay, endDate)){
            this.props.setGrouping("WEEKLY");
        }
        else if(this.isDailyGrouping(startDay, endDate)){
            this.props.setGrouping("DAILY");
        }
        else if(this.isHourGrouping(startDay, endDate)){
            this.props.setGrouping("HOURLY");
        }
        else{
            this.props.setGrouping("DAILY");
        }
    }

    setPeriod = () => {
        
        let startDay = moment(this.state.selectedStartDate).set('second', '00').format('YYYY-MM-DD HH:mm:ss');
        let endDate = moment(this.state.selectedEndDate).format('YYYY-MM-DD HH:mm:ss')
        if(!this.state.selectedEndDate){
            endDate = moment(this.state.selectedStartDate);
            endDate.set('hour', '23');
            endDate.set('minute', '59');
            //endDate.set('second', '59');
            endDate.format('YYYY-MM-DD HH:mm:ss');
        }
        
        this.props.setGroupingByPeriod(startDay, endDate)

        
        /*else{
            this.props.setGrouping('DAILY')
        }*/
        this.props.setStartDate(new Date(startDay));
        this.props.setEndDate(new Date(endDate));
        this.closeDatePicker();
    }

    render(){
        return  <Datepicker 
                                getValue={this.props.getValue}
                                getMonth={this.getMonth}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                months={this.props.months}
                                days={this.props.days}
                                minYear={this.props.minYear}
                                nextPreviousEndMonth={this.nextPreviousEndMonth}
                                nextPreviousStartMonth={this.nextPreviousStartMonth}
                                setStartYear={this.setStartYear}
                                setEndYear={this.setEndYear}
                                selectStartDay={this.selectStartDay}
                                selectEndDay={this.selectEndDay}
                                selectedStartDate={this.state.selectedStartDate}
                                selectedEndDate={this.state.selectedEndDate}
                                getStartWeekDay={this.getStartWeekDay}
                                setStartTime={this.setStartTime}
                                setEndTime={this.setEndTime}
                                setPeriod={this.setPeriod}
                                closeDatePicker={this.closeDatePicker}
                                disabledButton={this.state.disabledButton}
                />
    }

}

export default DatepickerController;