import { FilterService } from "../connection/filter-service";
import { CompanyService } from "../connection/company-service";
import { StorageService } from "../connection/storage-service";
import FilterList from "assets/filter-list/filter-list";
import { getClausesFromFilter } from "connection/header";
import { checkErrorMessage, getErrorMessage } from "./errors";
import { setSavedSearchList } from "./search-text";
import { setGrouping } from './calendar'
import { GeoplaceService } from "connection/geoplace-service"
import axios from "axios";

const SET_FILTERS_LIST_CHANGED = "SET-FILTERS-LIST-CHANGED";
const SET_MY_FILTERS = "SET-MY-FILTERS";
const SET_MY_FILTER = "SET-MY-FILTER";
const SET_MEDIA_TYPES = "SET-MEDIA-TYPES";
const SET_TAGS = "SET-TAGS";
const SET_KEYWORDS = "SET-KEYWORDS";
const SET_FILTERS_SELECTED = "SET-FILTERS-SELECTED";
const SET_FILTERS_ITEMS_SELECTED = "SET-FILTERS-ITEMS-SELECTED";
const SET_FILTERS_ITEM_ITEMS_SELECTED = "SET-FILTERS-ITEM-ITEMS-SELECTED";
const SET_TOPIC_FILTERS = "SET-TOPIC-FILTERS";
const SET_SEARCH_TITLE = "SET-SEARCH-TITLE";
const SET_CLEAR_SELECTED_ITEMS = "SET-CLEAR-SELECTED-ITEMS";
const SET_SELECTED_MY_FILTER = "SET-SELECTED-MY-FILTER";
const SET_EXCEPTION = "SET-EXCEPTION";
const SET_MY_SELECTED_TOPICS_FILTER = "SET-MY-SELECTED-TOPICS-FILTER";
const SET_ADD_SELECTED_MY_FILTER = "SET-ADD-SELECTED-MY-FILTER";
const SET_SELECTED_MY_FILTER_MENU = "SET-SELECTED-MY-FILTER-MENU";
const SET_SELECTED_TOPICS = "SET_SELECTED_TOPICS";
const SET_PAGE_NAME = "SET_PAGE_NAME";
const SET_FILTERS_ITEMS_LIST_CHANGED = "SET-FILTERS-ITEMS-LIST-CHANGED";
const SET_LOCATIONS = "SET-LOCATIONS";


const geoplace = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"geoplace/api/v1/"
})


const filtersState = {
    selectedMyFilter: {},
    myFilters: [],
    myFilter: {},
    filters: FilterList.filtersList,
    mediaType: FilterList.mediaType,
    type: FilterList.type,
    tags: [],
    sentiment: FilterList.sentiment,
    keywords: [],
    language: FilterList.language,
    topics: [],
    verificationStatus: FilterList.verificationStatus,
    sortFilters: FilterList.sortFilters,
    selected: [],
    isChanged: false,
    selectedItems: [],
    selectedItemItems: [],
    searchTitle: [],
    topicFilters: [],
    clearAll: false,
    selectedMyFiltersMenu: null,
    since: null,
    till: null,
    grouping: null,
    applyedFilters: {},
    selectedTopics: [],
    selectedTopicsIds: [],
    pageName: null,
    exceptions: {},
    itemsListChanged: false,
    locations: []
}

let filters = (state = filtersState, action) =>{
    switch(action.type){
        case SET_MEDIA_TYPES:
            return {...state, mediaType: action.mediaType}
        case SET_TAGS:
            return {...state, tags: action.tags}
        case SET_KEYWORDS:
            return {...state, keywords: action.keywords}
        case SET_FILTERS_SELECTED:
            let item = state.selected.indexOf(action.selected);
            if(item !== -1) return {...state, selected: state.selected.filter(k => k !== action.selected)}
            else return {...state, selected: [...state.selected, action.selected]}
        case SET_FILTERS_LIST_CHANGED:
            return {...state, isChanged: action.isChanged, applyedFilters: action.isChanged ? {...state.selectedMyFilter} : {...state.selectedMyFilter}}
        case SET_FILTERS_ITEMS_SELECTED:
            return {...state, selectedItems: action.selectedItems}
        case SET_FILTERS_ITEM_ITEMS_SELECTED:
            return {...state, selectedItemItems: action.selectedItemItems}
        case SET_TOPIC_FILTERS:
            return {...state, topicFilters: action.topicFilters}
        case SET_MY_FILTERS:
            let filters = [];
            filters = action.myFilters;
            return {...state, myFilters: action.myFilters}
        case SET_MY_FILTER:
            return {...state, myFilter: action.myFilter}
        case SET_SELECTED_MY_FILTER:
            let s = {...state.selectedMyFilter, ...action.selectedMyFilter};
            Object.keys(s).forEach(function(key) {
                if(typeof s[key] === "object"){
                    if(s[key] && s[key] !== undefined) s[key] = s[key].filter(k => k !== "\\!")
                    if(s[key]) s[key] = s[key].filter(k => k !== "\\*")
                    if(s[key]) s[key] = s[key].filter(k => k !== "ANY")
                    if(s[key]) s[key] = s[key].filter(k => k !== "UNVERIFIED")
                        if(!s[key] || !s[key].length || typeof s[key] !== 'object'){
                            delete s[key]
                        }
                }
                if(key === 'isAdvertising'){
                    let adv = s.isAdvertising || s.isAdvertising === false ? s.isAdvertising : 'null'
                    switch(adv){
                        case true:
                            s.is_advertising = ['WITH_ADVERTISING'];
                            break;
                        case false:
                            s.is_advertising = ['WITHOUT_ADVERTISING'];
                            break;
                        case 'null':
                            s.is_advertising = ['WITHOUT_ADVERTISING_FILTERS'];
                            break;
                    }
                    delete s.isAdvertising
                }
                if(key === 'isSwearing'){
                    let swe = s.isSwearing || s.isSwearing === false ? s.isSwearing : 'null'
                    switch(swe){
                        case true:
                            s.is_swearing = ['WITH_SWEARING'];
                            break;
                        case false:
                            s.is_swearing = ['WITHOUT_SWEARING'];
                            break;
                        /*case 'null':
                            s.is_swearing = ['WITHOUT_SWEARING_FILTERS'];
                            break;*/
                    }
                    delete s.isSwearing
                }
                
            });
            return {...state, selectedMyFilter: s, isChanged: true}
        case SET_MY_SELECTED_TOPICS_FILTER:
            return {...state, selectedMyFilter: action.selectedMyFilter}
        case SET_CLEAR_SELECTED_ITEMS:
            let selected = [];
            selected.topics = state.selectedMyFilter.topics ? state.selectedMyFilter.topics : [];
            //return {...state}
            return action.clearAll ? {...state, selectedMyFilter: selected, clearAll: action.clearAll, selectedMyFiltersMenu: null, applyedFilters: {}} : {...state, clearAll: false}
        case SET_ADD_SELECTED_MY_FILTER:
            let selectedFiltersArr = state.selectedMyFilter;
            let itemF = state.selectedMyFilter[action.name] ? [...state.selectedMyFilter[action.name]] : [] ;
            
            if(itemF.indexOf(action.selectedF) !== -1){
                itemF = itemF.filter(k => k !== action.selectedF)
            }
            else{
                itemF = itemF.filter(k => k !== "\\!")
                itemF = itemF.filter(k => k !== "\\*")
                itemF = itemF.filter(k => k !== "ANY")
                itemF = itemF.filter(k => k !== "UNVERIFIED")
                if(action.selectedF === "\\!" || action.selectedF === "\\*" || action.selectedF === 'ANY' || action.selectedF === 'UNVERIFIED' || action.name === "is_advertising" || action.name === "is_swearing"){
                    if(itemF.indexOf(action.selectedF) === -1){
                        itemF = [];
                        itemF.push(action.selectedF)
                    }
                    else{
                        itemF = [];
                    }
                }
                else{
                    let r = action.selectedF.replace('\\!', '')
                    if(itemF.indexOf("\\!"+r) === -1){
                        itemF = itemF.filter(k => k !== r)
                        itemF = itemF.filter(k => k !== action.selectedF)
                        itemF.push(action.selectedF)
                    }
                    else{
                        itemF = itemF.filter(k => k.replace('\\!', '') !== r)
                    }
                    
                }

            }
            let listChanged = action.name !== 'topics' ? true : false

                selectedFiltersArr[action.name] = itemF               
                if(!selectedFiltersArr[action.name].length)
                    delete selectedFiltersArr[action.name]
            
            return {...state, selectedMyFilter: {...selectedFiltersArr}, itemsListChanged: listChanged}
        case SET_FILTERS_ITEMS_LIST_CHANGED:
            
            return {...state, itemsListChanged: action.itemsListChanged}
        case SET_EXCEPTION:
            let selectedExceptions = state.exceptions;
            let itemExc = state.exceptions[action.name] ? [...state.exceptions[action.name]] : [] ;
            if(itemExc.indexOf(action.selectedMyFilter) === -1)
                itemExc.push(action.selectedMyFilter)
            else
                itemExc = itemExc.filter(k => k !== action.selectedMyFilter)
                selectedExceptions[action.name] = itemExc
            if(!selectedExceptions[action.name].length)
                delete selectedExceptions[action.name]
            return {...state, exceptions: {...selectedExceptions}}
        case SET_SELECTED_MY_FILTER_MENU:
            return { ...state, selectedMyFiltersMenu: action.selectedMyFiltersMenu}
        case SET_SELECTED_TOPICS:
            return {...state, selectedTopics: action.selectedTopics, selectedTopicsIds: action.selectedTopicsIds}
        case SET_PAGE_NAME:
            return {...state, pageName: action.pageName}
            default:
            return {...state}
        case SET_LOCATIONS:
            return {...state, locations: action.locations}
    }
}

export default filters;

const setLocationsName = (locacations) => {
    return {
        type: SET_LOCATIONS,
        locations: locacations
    }
}

export const setFiltersItesListChanged = (itemsListChanged) => {
    return {
        type: SET_FILTERS_ITEMS_LIST_CHANGED,
        itemsListChanged: itemsListChanged
    }
}

export const setMediaTypes = (mediaTypes) => {
    return {
        type: SET_MEDIA_TYPES,
        mediaTypes: mediaTypes
    }
}

export const setTags = (tags) => {
    return {
        type: SET_TAGS,
        tags: tags
    }
}

export const setKeywords = (keywords) => {
    return {
        type: SET_KEYWORDS,
        keywords: keywords
    }
}

export const setAddSelectedMyFilter = (name, selectedF, isException) => {
    return {
        type: SET_ADD_SELECTED_MY_FILTER,
        selectedF: selectedF,
        name: name,
        isException: isException
    }
}

export const setMyFilters = (filters) => {
    return {
        type: SET_MY_FILTERS,
        myFilters: filters
    }
}

export const setMyFilter = (filter) => {
    return {
        type: SET_MY_FILTER,
        myFilter: filter
    }
}

export const setMySelectedFilter = (selectedMyFilter,name) => {
    return {
        type: SET_SELECTED_MY_FILTER,
        selectedMyFilter: selectedMyFilter,
        name: name
    }
}

export const setMySelectedTopicsFilter = (selectedMyFilter,name) => {
    return {
        type: SET_MY_SELECTED_TOPICS_FILTER,
        selectedMyFilter: selectedMyFilter,
        name: name
    }
}

export const setException = (name, selectedMyFilter) => {
    return {
        type: SET_EXCEPTION,
        selectedMyFilter: selectedMyFilter,
        name: name
    }
}

const getFilters = (data) => {
    let topicsData = {...data.data}
    let d = []
    if(topicsData.common_location_country_id){
        d = topicsData.common_location_country_id.filter(k => parseInt(k.value)).map(k => k.value)
    }
    return d
}

export const getMediaTypes = () => {
    return (dispatch) => {
        FilterService.getMediaTypes().then(data=>{
            //dispatch(setTags(data));
        })
    }
}

export const createNewFilter = (name, since, till, grouping, filters) => {
    return (dispatch) => {
        FilterService.createNewFilter(name, since, till, grouping, filters).then(data=>{
            dispatch(getAllFilters())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

//updateFilter

export const updateMyFilter = (id, name, since, till, grouping, filters) => {
    return (dispatch) => {
        FilterService.updateFilter(id, name, since, till, grouping, filters).then(data=>{
            dispatch(getAllFilters())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const deleteMyFilter = (id) => {
    return (dispatch) => {
        FilterService.deleteFilter(id).then(data=>{
            dispatch(getAllFilters())
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getAllFilters = () => {
    return (dispatch) => {
        FilterService.getAllFilters().then(data=>{
            dispatch(setMyFilters(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getMyFilter = (id, isMyFilter = false) => {
    return (dispatch) => {
        if(id){
            FilterService.getFilter(id).then(data => {
                let f = getClausesFromFilter(data)
                dispatch(setSavedSearchList(f))
                dispatch(isMyFilter ? setMyFilter(data) : setMySelectedFilter(data))
                if(data.grouping){
                    dispatch(setGrouping(data.grouping))
                }
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            });
        }
        else{
            //dispatch(setClearSelected(true)), dispatch(setClearSelected(false))
        }
        setTimeout(()=>{dispatch(filtersChanged(true))},500) 
    }
}

export const getCompanyTags = (companyId) => {
    
    return (dispatch) => {
        CompanyService.getCompanyTags(companyId).then(data=>{
            dispatch(setTags(data.content));
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getGeoplaceDictionary = (locationsIds) => {
    return (dispatch) => {
        GeoplaceService.getGeoplaceDictionary(locationsIds).then( data=> {
            dispatch(setLocationsName(data))
        })
    }
}

export const getTopicsFilters = (dateFrom, dateTo, topicId, fields, filters, subtopics) => {

    return (dispatch) => {
        StorageService.getTopicsFiltersStatistics(dateFrom, dateTo, topicId, fields, filters, subtopics).then(data=>{
            let n = getFilters(data)
            dispatch(getGeoplaceDictionary(n))
            dispatch(setTopicFilters(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getBlackListFilters = (dateFrom, dateTo, topics, fields, filters) => {
    return (dispatch) => {
        StorageService.getBlackListFiltersStatistics(dateFrom, dateTo, topics, fields, filters).then(data=>{
            dispatch(setTopicFilters(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getDeletedFilters = (dateFrom, dateTo, topics, fields, filters) => {
    return (dispatch) => {
        StorageService.getDeletedFiltersStatistics(dateFrom, dateTo, topics, fields, filters).then(data=>{
            dispatch(setTopicFilters(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getFavouriteManualFilters = (dateFrom, dateTo, topics, fields, filters) => {
    return (dispatch) => {
        StorageService.getFavouriteManualFiltersStatistics(dateFrom, dateTo, topics, fields, filters).then(data=>{
            dispatch(setTopicFilters(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getFavouriteAutoFilters = (dateFrom, dateTo, topics, fields, filters) => {
    return (dispatch) => {
        StorageService.getFavouriteAutoFiltersStatistics(dateFrom, dateTo, topics, fields, filters).then(data=>{
            dispatch(setTopicFilters(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}


export const setFiltersSelected = (selected) => {
    return {
        type: SET_FILTERS_SELECTED,
        selected: selected
    }
}

export const filtersChanged = (isChanged) => {
    return {
        type: SET_FILTERS_LIST_CHANGED,
        isChanged: isChanged
    }
}

//dispatch(filtersChanged(isChanged));

export const setClearSelected = (clearAll) => {
    return {
        type: SET_CLEAR_SELECTED_ITEMS,
        clearAll: clearAll
    }
}

export const setIsFilterChanged = (isChanged, dateFrom, dateTo, topicId, fields, filters) => {
    return (dispatch) => {
        dispatch(filtersChanged(isChanged));
        //isChanged && dispatch(getTopicsFilters(dateFrom, dateTo, topicId, fields, filters))
    }
}

export const setFiltersItemsSelected = (selectedItems) => {
    return {
        type: SET_FILTERS_ITEMS_SELECTED,
        selectedItems: selectedItems
    }
}

export const setFiltersItemItemsSelected = (selectedItemItems) => {
    return {
        type: SET_FILTERS_ITEM_ITEMS_SELECTED,
        selectedItemItems: selectedItemItems
    }
}

export const setTopicFiltersUpdated = (topicFilters) => {
    return {
        type: SET_TOPIC_FILTERS,
        topicFilters, topicFilters
    }
}

export const setTopicFilters = (topicFilters) => {
    return {
        type: SET_TOPIC_FILTERS,
        topicFilters, topicFilters
    }
    /*return (dispatch) => {
        dispatch(filtersChanged(true));
        dispatch(setTopicFiltersUpdated(topicFilters))
        dispatch(filtersChanged(false))
    }*/
}

export const setSelectedMyFilterMenu = (selectedMyFiltersMenu) => {
    return {
        type: SET_SELECTED_MY_FILTER_MENU,
        selectedMyFiltersMenu: selectedMyFiltersMenu
    }
}

export const setSelectedTopics = (selectedTopics, selectedTopicsIds) => {
    return (dispatch) => {
        dispatch(setSelectedTopicsChanged(selectedTopics, selectedTopicsIds))
    }
    
}

export const setSelectedTopicsChanged = (selectedTopics, selectedTopicsIds) => {
    return {
        type: SET_SELECTED_TOPICS,
        selectedTopics: selectedTopics,
        selectedTopicsIds: selectedTopicsIds
    }
}

export const setPageName = (pageName) => {
    return {
        type: SET_PAGE_NAME,
        pageName: pageName
    }
}

export const setPageNameClear = (pageName) => {
    return (dispatch) => {
        dispatch(setClearSelected(true));
        dispatch(setPageNameClear(pageName))
    }
}