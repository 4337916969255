import React from "react";
import PanelListOfPublication from "./panel-list-of-publications";

class PanelListOfPublicationController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            newPostsCount: props.newPostsCount,
            selectedUUId: props.selectedUUId,
            counter: 0
        }
    }

    componentDidMount(){
        if((this.props.selectedUUId)){
            this.props.getNewPublicationsCount(this.props.selectedUUId, true);}
    }

    componentDidUpdate(){
        if(this.props.selectedUUId && this.props.selectedUUId !== this.state.selectedUUId){
            this.props.getNewPublicationsCount(this.props.selectedUUId, true);
            this.setState({
                newPostsCount: this.props.newPostsCount,
                selectedUUId: this.props.selectedUUId 
            })
        }
    }

    render(){
        if(this.props.isAuth)
            return <PanelListOfPublication {...this.props} counter={this.state.counter}/>
    }

}

export default PanelListOfPublicationController;