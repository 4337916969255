import React, { useState, useEffect } from "react";
import s from "./keywords.module.scss";
import { InputText, TextArea } from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import {ReactComponent as PlusIcon} from "assets/icons/plus.svg";
import {ReactComponent as CloseIcon} from "assets/icons/close.svg";
import {ReactComponent as SearchIcon} from "assets/icons/search.svg";
import CheckArchiveContainer from "./check-archive/check-archive-container";
import MessageTextContainer from "./message-text/message-text-container";

const Keywords = (props) => {

    const [keyword, setKeyword] = useState('');
    const linkRegExp = /(https?:\/\/)?([\w-]+\.)+\w+\/.+?/

    const oneSymbolwithStar = /(\s\S\*)|(^\S\*)/
    const noAmpersands = /(\S&&)|(&&\S)|(^&&)|(&&(\s+)?$)/
    const noStarOnStartOrEnd = /(\*\S)|([^\p{L}\d]\*)/
    const starsOnCenterOrEnd = /(\s\*)|(^\*)|([^\p{L}\d]\*)/gu
    const noCharachters = /[:;!,%()\/\\@]/
    const threeAmpersands = /&{3,}/
    const singleStar = /(\s|^)[*?]+(\s|$)/
    //(^\*)|
    /*/(\*\S)|([^\p{L}\d]\*)&{3,}/*/
    const [message, setMessage] = useState('');
    const [rows, setRows] = useState(1);
    const [resize, setResize] = useState(false);
    const [pasteKeywords, setPasteKeywords] = useState(null);
    const [reRender, setRerender] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const setMessageError = (error) => {
        setTimeout(() => {setErrorMessage(error); setRerender(!reRender)}, 500);
        
    }

    const checkKeyWords = (keyword) => {
            if(keyword.match(singleStar)){
                setMessageError(props.getValue('SINGLE_STAR'));
                return true;
            }
            else if(keyword.match(starsOnCenterOrEnd)){
                setMessageError(props.getValue('STARS_ON_CENTER_OR_END'));
                return true;
            }
            else if(keyword.match(threeAmpersands)){
                setMessageError(props.getValue('THREE_AMPERSANDS'));
                return true;
            }
            else if(keyword.match(noAmpersands)){
                setMessageError(props.getValue('NO_AMPERSANDS'));
                return true;
            }
            else if(keyword.match(oneSymbolwithStar)){
                setMessageError(props.getValue('ONE_SYMBOL_WITH_STAR'));
                return true;
            }
            else if(keyword.match(noCharachters)){
                setMessageError(props.getValue('NO_CHARACHTERS'));
                return true;
            }
            else{
                setMessageError(null)
                return false;
            }
        
    }

    const checkLink = (link, type) => {
        switch(type){
            case 'socnetProfiles':
                return !link || link && link.match(linkRegExp) ? true : false;
            case 'resources':
                let res = props.domains.filter(k => k.name === link);
                return res.length ? false : true;
            case 'keywords':
            case 'stopwords':
                if(!link || link === ''){
                    setMessageError(null)
                    return false
                }
                return !link || link && !checkKeyWords(link) ? true : false;
                //return ks
        }

    }

    let getByType = (type) => {
        switch(type){
            case 'keywords':
                return props.keywords;
            case 'stopwords':
                return props.stopwords;
            case 'socnetProfiles':
                return props.profiles;
            case 'resources':
                return props.resources;
            default:
                return props.keywords
        }
    }

    const [keywords, setKeywordsByType] = useState(getByType(props.type));

    useEffect(() => {
        setKeywordsByType(getByType(props.type));
        setRerender(!reRender)
        //if(props.showOnly)
        //    setPasteKeywords(null)
        //setMessage(props.getValue(props.message));
    }, [props]);

    const changePasteKeywordsItems = (item, index, e) => {
        let keyList = pasteKeywords;
        if(keyList[index]){
            keyList[index] = e.target.value;
        }

        setPasteKeywords(keyList)
        setRerender(!reRender)
    }

    const onPasteText = (e) => {
        let pasteKeywords = e && e.length ? e.split('\n') : [];
        let keyList = []
        if(Array.isArray(pasteKeywords) && pasteKeywords.length > 1){
            pasteKeywords.forEach((k, i) => {
                if(k && k.length){

                    keyList.push(k)
                }
                 
            })
            setPasteKeywords(keyList)
        }

    }

    const newKeyWord = (keyword) => {
        keyword = keyword.trim()
        //if(keyword !== '' && keyword !== ' '){
            
            setKeyword(keyword)
            if(!keyword || keyword === '') checkLink(keyword, props.type)
            setResize(!resize);

        //}
    }

    const goto = (e) => {
        addKeyword(e)
    }

    const addKeyword = (value) => {
        let keys = pasteKeywords//keyword.split('\n')
        if(keys && keys.length){

            keys.forEach(k => {
                if(k && k.length && k !== ''){
                    props.setItemByType(props.type, k.trim())
                }
            })
        }
        else{
            if(checkLink(keyword, props.type)){
                props.setItemByType(props.type, keyword)
            }
            else{
                setTimeout(() => {setKeyword(keyword)}, 20) 
                return
            }
            
        }

        if(pasteKeywords){
            setPasteKeywords(null)
        }
        setKeyword('');
        setRerender(!reRender)
    }

    const getValueByType = (type) => {
        switch(type){
            case 'socnetProfiles':
                return 'url'
            case 'resources':
                return 'domain'
            default:
                return 'keyword'
        }
    }

    const deleteKeyword = (value) => {
        props.removeItemByType(props.type, value[getValueByType(props.type)])
    }

    const getItemByType = (type) => {
        switch(type){
            case 'socnetProfiles':
                return 'url'
            case 'resources':
                return 'domain'
            default:
                return 'keyword'
        }
    }

    const drawTabel = (k, i) => {
        return <tr key={i}>
            <td>{i+1}</td>
            <td>{k[getItemByType(props.type)]}</td>
            {!props.showOnly ? <td><button onClick={deleteKeyword.bind(this, k)} className={s.deleteKeyword}><CloseIcon /></button></td> : null}
        </tr>
    }

    const checkItem = () => {
        props.check(keyword)
    }

    const setTextMessage = (message) => {
        setMessage(message);
    }

    const getTitleByType = (type) => {
        switch(type){
            case 'keywords':
                return props.getValue('KEYWORDS');
            case 'stopwords':
                return props.getValue('STOPWORDS');;
            case 'socnetProfiles':
                return props.getValue('PROFILES');;
            case 'resources':
                return props.getValue('RESOURCES');;
            default:
                return props.getValue('WORDS');
        }
    }

    const keyUp = (index, key) => {
        if(key.key === 'Enter'){
            let pkeywords = pasteKeywords;
            let keys = key.target.value.split('\n');
            keys.forEach((k, i) => {
                if(k && k.length){
                    if(i === 0 )
                        pkeywords.splice(index, 1, k)
                    else 
                        pkeywords.splice(index+i, 0, k)
                }
            })

            setPasteKeywords(pkeywords)
            setRerender(!reRender)
        }
    }

    const pasteKeywordsList = pasteKeywords && pasteKeywords.length 
            ? pasteKeywords.map((k,i) => {
                let checked = checkLink(k, props.type)
                return <textarea key={i} onKeyUp={keyUp.bind(this, i)} className={checked ? s.checkedInput : s.nonCheckedInput} onChange={changePasteKeywordsItems.bind(this, k, i)} value={k}/>
            })
            : []

    return (
        <div className={s.Keywords}>
            {!props.showOnly ? <div className={s.Row}>
                {(pasteKeywords && pasteKeywords.length > 1) ? <div className={s.pasteKeywords}>
                                    {pasteKeywordsList}
                                </div>
                                : (props.type === 'keywords' || props.type === 'stopwords') 
                                                                                        ? <TextArea onPasteText={onPasteText} resize={false} rows={rows} title={props.title ? props.title : null} placeholder={props.type === 'socnetProfiles' ? 'example.com/value' : props.getValue('ENTER_NEW_WORD')} callback={newKeyWord} value={keyword} goto={addKeyword} />
                                                                                        : <InputText resize={false} rows={rows} title={props.title ? props.title : null} placeholder={props.type === 'socnetProfiles' ? 'example.com/value' : props.getValue('ENTER_NEW_WORD')} callback={newKeyWord} value={keyword} />
                }
                {props.type === 'socnetProfiles' || props.type === 'resources'
                    ? <CheckArchiveContainer type={props.type} resource={keyword} isDisabled={(keyword && !message) ? false : true} setTextMessage={setTextMessage}/>//<StandartButton disabled={(keyword && !message) ? false : true} icon={SearchIcon} callback={checkItem} fill={'white'}/>
                    : null
                }
                <StandartButton disabled={(!keyword || keyword === '') || (props.reg ? !checkLink(pasteKeywords && pasteKeywords.length > 1 ? pasteKeywords.join(' ') : keyword, props.type) : false)} name={props.getValue('ADD')} icon={PlusIcon} fill="white" callback={addKeyword}/>
            </div> : null }
            { props.type === 'socnetProfiles' ? <MessageTextContainer type={props.type}/> : null}
            { props.type === 'resources' ? <MessageTextContainer type={props.type}/> : null}
            { props.type === 'keywords' || props.type === 'stopwords' && errorMessage ? <div className={s.errorMessage}>{errorMessage}</div> : null}
            <div className={s.KeywordsList}>
                <table>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>{getTitleByType(props.type)}</th>
                            {!props.showOnly ? <th></th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {keywords && keywords.map((k,i) => {
                            return drawTabel(k,i)
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Keywords

//link.match(oneSymbolwithStar+'|'+noAmpersands+'|'+starsOnStartCenterOrEnd+'|'+noCharachters+'|'+threeAmpersands+'|'+singleStar) === null)