import React from "react";
import AdminCompanies from "./admin-companies";
import CreateCompany from "./create-company/create-company";

class AdminCompaniesController extends React.Component{

    getCompanyIdByRole = (props) => {
        if(props.role){
            if(props.role === 'ROLE_SUPER_ADMIN'){
                if(props.adminCompanyId){
                    return props.adminCompanyId
                }
                else if(props.companyId){
                    return props.companyId;
                }
                else{
                    return null;
                }
            }
            else if(props.role === 'ROLE_ADMIN')
                return props.adminCompanyId
        }
        else return null

    }

    constructor(props){
        super(props);
        this.state = {
            companyId: this.getCompanyIdByRole(this.props),
            topicId: this.props.topicId,
            accountId: this.props.accountId,
            role: this.props.role,
            currentPage: this.props.currentPage,
            showedItemsCount: this.props.showedItemsCount
        }
    }

    CreateComapany = (name, bin, maxNumTopics) => {
        this.props.createCompany(name, bin, maxNumTopics)
    }

    editCompany = (companyId, companyName, companyBin, companyTopicsCount, companyStatus) => {
        this.props.updateCompany(companyId, companyName, companyBin, companyTopicsCount, companyStatus)
    }

    closeRightMenu = () => {        
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    getCompanies = () => {
        this.props.getSuperadminCompanies('ALL', this.props.currentPage, this.props.showedItemsCount);
    }

    componentDidMount(){
        this.props.isSuperadmin && this.getCompanies()
        if(this.state.companyId){
            this.props.getCompanyAccounts(this.state.companyId, this.props.isSuperadmin);
            this.props.getCompanyTopics(this.state.companyId, this.props.isSuperadmin);
        }
        
    }

    /*checkCompanyId = () => {
        if(this.props.isSuperadmin){
            if(this.props.adminCompanyId){
                this.props.adminCompanyId !== this.state.companyId
            }
            else{
                return this.props.companyId !== this.state.companyId
            }
        }
        else
            return this.props.adminCompanyId !== this.state.companyId
    }*/

    getAccountById = (accountId) => {
        return this.props.accounts ? this.props.accounts.find(k => k.id === accountId) : null
    }


    componentDidUpdate(){
        if(this.props.isSuperadmin && !this.props.isFetching){
            if((!this.props.companies || this.props.currentPage !== this.state.currentPage || this.props.showedItemsCount !== this.state.showedItemsCount)){ 
                this.getCompanies();
                this.props.getMarkers();
                
                if(this.props.currentPage !== this.state.currentPage || this.props.showedItemsCount !== this.state.showedItemsCount){
                    this.closeRightMenu();
                    this.props.setCompanyId(null)
                    //this.props.setTopicId(null)
                    this.setState({
                        companyId: null,
                        currentPage: this.props.currentPage,
                        showedItemsCount: this.props.showedItemsCount
                        //showedItemsCount: this.props.showedItemsCount
                    })
                }
            }
            if(this.props.companies && this.props.itemsCount !== this.props.companiesCount){
                this.props.setItemsCount(Number(this.props.companiesCount) && this.props.companiesCount)
            }
        }

        if( (this.getCompanyIdByRole(this.props) && this.getCompanyIdByRole(this.props) !== this.state.companyId )|| this.props.topicId !== this.state.topicId || this.props.accountId !== this.state.accountId){ 
            let companyId = this.getCompanyIdByRole(this.props)
            this.setState({
                companyId: companyId,
                topicId: this.props.topicId,
                accountId: this.props.accountId,
                role: this.props.role,
            })
            if(this.props.topicId){
                if(companyId)
                    this.props.getTopicAccounts(companyId, this.props.topicId, this.props.isSuperadmin)
            }

            if(this.props.accountId){
                let account = this.getAccountById(this.props.accountId);
                if(account && account.role && account.role === 'ROLE_MARKER'){
                    this.props.getMarkerTopics(this.props.accountId)
                }
                else{
                    this.props.getAccountTopics(companyId, this.props.accountId, this.props.isSuperadmin)
                }
            }
            if(companyId){
                this.props.getCompanyAccounts(companyId, this.props.isSuperadmin);
                this.props.getCompanyTopics(companyId, this.props.isSuperadmin);
            }
        
        }
        if(this.props.topicId){
            let companyId = this.getCompanyIdByRole(this.props)
            if(companyId)
                this.props.getTopicAccounts(companyId, this.props.topicId, this.props.isSuperadmin)
        }
        if(this.props.role === 'ROLE_ADMIN'){
            this.props.getCompany()
        }
    }

    render(){
        return (
                !this.props.isFetching && <AdminCompanies 
                                getValue={this.props.getValue}
                                companies={this.props.companies}
                                companyId={this.getCompanyIdByRole(this.props)} 
                                topics={this.props.topics}
                                accounts={this.props.accounts}
                                topicId={this.props.topicId}
                                accountId={this.props.accountId}
                                role={this.props.role}
                                showRightMenu={this.showRightMenu}
                                isSuperadmin={this.props.isSuperadmin}
                                companyName={this.props.companyName}
                                adminCompanyId={this.props.adminCompanyId}/>
        )
    }
shouldComponentUpdate(nextProps, nextState){
    if(!nextProps.isFetching){
        return true;
    }
    else return false
}

    componentWillUnmount(){
        this.closeRightMenu()
        /*this.props.setItemsCount(null)
        this.props.setCurrentPage(0)
        this.props.setShowedItemsCount(10)*/
        this.props.setCompanyId(null)
        this.props.setToDefaultPaginator();
    }
}

export default AdminCompaniesController;