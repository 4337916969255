import React, { useState, useEffect } from "react";
import s from "./telegram-item.module.scss";
import { InputNumber, InputText, SelectDropdown } from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as AngleUpIcon } from 'assets/icons/angle-up.svg';
import { ReactComponent as AngleDownIcon } from 'assets/icons/angle-down.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as RecycleBinIcon } from 'assets/icons/recycle-bin.svg';
import EmailsList from "items/emails-list/emails-list";
import TelegramBots from "../telegram-bots/telegram-bots";
import EveryPublication from "./politics/every-publication/every-publication";
import moment from "moment";

const TelegramItem = (props) => {
    
    const getTopicByUUId = (topicUUId, type) => {
        let n = props.topics.findIndex(k=> Number(topicUUId) ? Number(k.id) === Number(topicUUId) : k.uuid === topicUUId);
        if(n !== -1)
            return type ? props.topics[n][type] : props.topics[n]
        else return null
    }

    const getFilters = () => {
        let f = [...props.myFilters];
        f.unshift({name: null, id: null})
        return f;
    }

    const getSubtopicName = (id, type) => {
        if(props.subtopics && props.subtopics.length){
            let n = props.subtopics.findIndex(k => k.uuid === id)
            let subtopic = null
            if(n >= 0)
                subtopic = props.subtopics[n]
            return subtopic ? subtopic[type] : null
        }
        else return null
    }

    const getPolicies = (policies) => {
        return policies.map((k,i) => {return /*i === 0 ? [{name: null, id: null},{name: props.getValue(k), list: k}] : */{name: props.getValue(k), list: k} })
    }

    const getMetricTypes = (metricTypes) => {
        return metricTypes.map((k,i) => {return {name: props.getValue(k.name), list: k.list} })
    }

    /*const getPolicyName = (name, type) => {
        return telegramPolicies.findIndex(k => type ? k[type] === name : k.name === name)
    }

    const getGrouping = (name, type = null) => {
        let grouping = props.groupings.findIndex(k => k[type] ? k[type] === name : k.name === name)
        return grouping > 0 ? props.groupings[grouping].list : null
    }

    const getPolicyMinCount = (policyName) => {
        return policyName ? policyName.includes('PERCENT') : false
    }*/

    const [selectedTopic, setSelectedTopic] = useState(props.selectedTelegramTopicUUId ? getTopicByUUId(props.selectedTelegramTopicUUId, 'name') : null);
    const [selectedSubtopic, setSelectedSubtopic] = useState(props.topic.subtopicId ? getSubtopicName(props.topic.subtopicId, 'name') : null);
    const [selectedSubtopicId, setSelectedSubtopicId] = useState(props.topic.subtopicId ? getSubtopicName(props.topic.subtopicId, 'uuid') : null)
    const [telegramPolicies, setTelegramPolicies] = useState(props.telegramPolicies ? getPolicies(props.telegramPolicies) : []);
    const [telegramSentiments, setTelegramSentiments] = useState(props.telegramSentiments ? props.telegramSentiments.map((k,i) => {return i === 0 ? [{name: null, id: null},{name: props.getValue(k), list: k}] : {name: props.getValue(k), list: k} }) : []);
    const [groupings, setGroupings] = useState(props.groupings ? props.groupings.map(k => {return {name: props.getValue(k.list), list: k.name}}) : [])
    const [selectedTopicUUId, setSelectedTopicUUId] = useState(props.selectedTelegramTopicUUId ? props.selectedTelegramTopicUUId : null);
    const [subscriptionId, setSubscriptionId] = useState(props.selectedTelegramItem ? props.selectedTelegramItem.id : null);
    const [selectedFilter, setSelectedFilter] = useState((props.selectedTelegramItem && props.selectedTelegramItem.filter)? props.selectedTelegramItem.filter.name : null);
    const [selectedFilterId, setSelectedFilterId] = useState((props.selectedTelegramItem && props.selectedTelegramItem.filter)? props.selectedTelegramItem.filter.id : null);
    const [daysLimit, setDaysLimit] = useState(/*(props.selectedTelegramItem && props.selectedTelegramItem.daysLimit) ? props.selectedTelegramItem.daysLimit : */null)
    const [emails, setEmails] = useState(props.emails ? props.emails.map(k => k.email) : [])
    const [policyList, setPolicyList] = useState(props.alertPolicy ? props.alertPolicy : [])
    const [filters, setFilters] = useState(getFilters());
    const [removeFilter, setRemoveFilter] = useState(false);
    const [showOnly, setShowOnly] = useState(props.selectedTelegramItem && props.selectedTelegramItem.showType === 'show' ? true : false)
    const [showPolicyItem, setShowPolicyItem] = useState([0])
    const [emailsChanged, setEmailsChanged] = useState(false)
    const [metricTypes, setMetricTypes] = useState(props.metricTypes ? getMetricTypes(props.metricTypes) : null )
    const [rerender, setRerender] = useState(false);

    useEffect(()=>{
        setSubscriptionId(props.selectedTelegramItem ? props.selectedTelegramItem.id : null);
        setSelectedTopic(props.selectedTelegramTopicUUId ? getTopicByUUId(props.selectedTelegramTopicUUId, 'name') : null);
        setSelectedTopicUUId(props.selectedTelegramTopicUUId ? props.selectedTelegramTopicUUId : null);
        setSelectedSubtopic(props.topic.subtopicId ? getSubtopicName(props.topic.subtopicId, 'name') : null);
        setSelectedSubtopicId(props.topic.subtopicId ? getSubtopicName(props.topic.subtopicId, 'uuid') : null)
        setSelectedFilter((props.selectedTelegramItem && props.selectedTelegramItem.filter) ? props.selectedTelegramItem.filter.name : null);
        setSelectedFilterId((props.selectedTelegramItem && props.selectedTelegramItem.filter) ? props.selectedTelegramItem.filter.id : null);
        setDaysLimit(/*(props.selectedTelegramItem && props.selectedTelegramItem.daysLimit) ? props.selectedTelegramItem.daysLimit : */null)
        setEmails(props.emails ? props.emails.map(k => k.email) : []);
        setPolicyList(props.alertPolicy ? props.alertPolicy : [])
        setShowOnly(props.selectedTelegramItem && props.selectedTelegramItem.showType === 'show' ? true : false);
        setMetricTypes(props.metricTypes ? getMetricTypes(props.metricTypes) : null )
        setRerender(!rerender);
    }, [(props.selectedTelegramItem && props.selectedTelegramItem.topicId) ? getTopicByUUId(props.selectedTelegramItem.topicId) : null, props.subscriptionId, props.alertPolicy, props.selectedTelegramItem && props.selectedTelegramItem.showType, props.topic.subtopicId ])

    const selectTopic = (selectedTopic, index) => {
        setSelectedTopic(selectedTopic.name);
        setSelectedTopicUUId(selectedTopic.uuid)
        setSelectSubtopic(null)
        props.setSelectedTelegramTopic(selectedTopic.id, selectedTopic.uuid)
        
    }

    const setSelectSubtopic = (subtopic) => {
        setSelectedSubtopic((subtopic && subtopic.name) ? subtopic.name : null)
        setSelectedSubtopicId((subtopic && subtopic.uuid) ? subtopic.uuid : null)
    }

    const setPolicy = (policy, i) => {
        let p = policyList;

        p[i].alertPolicy = policy.list
        if(!p[i].grouping || p[i].grouping === ''){
            p[i].grouping = 'DAILY';
        }
        if(p[i].alertPolicy.includes('AGGREGATE')){
            p[i] = getAlertPolicy(p[i], true)
        }
        if(!p[i].metricType || p[i].metricType === ''){
            p[i].metricType = 'COUNT';
        }
        if(!p[i].target || p[i].target === 0){
            p[i].target = 1;
        }
        else{
            
        }
        setPolicyList(p);
        setRerender(!rerender)

    }

    const createSubscription = () => {
        let e = emails.filter(k => k)
        let newPolicies = policyList.filter(k => !k.id)
        newPolicies = newPolicies.map(k => getAlertPolicy(k))
        if(selectedSubtopicId) 
            props.createSubtopicSubscription(selectedTopicUUId, selectedSubtopicId, selectedFilterId, daysLimit, e, newPolicies /*selectedPolicyName, selectedGroupingName, countOfPolicy*/)
        else 
            props.createSubscription(selectedTopicUUId, selectedFilterId, daysLimit, e, newPolicies  /*selectedPolicyName, selectedGroupingName, countOfPolicy*/)
        props.setShowRightMenu(false);
        props.setRightMenuComponent(null);
        props.setRemoveRightMenuComponent();
    }

    const getAlertPolicy = (policy, noAlertPolicy = false) => {
        switch(policy.alertPolicy){
            case 'AGGREGATE_PERCENT':
                if(!noAlertPolicy) policy.alertPolicy = "AGGREGATE";
                policy.metricType = "PERCENT";
                return policy
            case 'AGGREGATE_COUNT':
                if(!noAlertPolicy) policy.alertPolicy = "AGGREGATE";
                policy.metricType = "COUNT";
                if(policy.target <= 0 ) policy.target = 1
                return policy
            case 'AGGREGATE_ABSOLUTE_COUNT':
                if(!noAlertPolicy) policy.alertPolicy = "AGGREGATE";
                if(policy.target <= 0 ) policy.target = 1
                policy.metricType = "ABSOLUTE_COUNT";
                return policy
            default:
                return policy
        }
    }

    const updateSubscription = () => {
        let f = selectedFilterId ? selectedFilterId : props.selectedTelegramItem.filterId
        let e = emails.filter(k => k)
        props.setShowRightMenu(false);
        props.setRightMenuComponent(null);
        props.setRemoveRightMenuComponent();
        let newPolicies = policyList.filter(k => !k.id)
        newPolicies = newPolicies.map(k => getAlertPolicy(k))
        if(!selectedSubtopic && props.selectedTelegramItem.subtopicId){
            
            props.updateSubscription(props.selectedTelegramItem.id, selectedTopicUUId, null, f, daysLimit, e, emailsChanged, newPolicies, props.deletedPolicies,/*selectedPolicyName, selectedGroupingName, countOfPolicy,*/ removeFilter)
            //props.deleteSubtopicSubscriptionAndCreateSubscription(props.selectedTelegramItem.id, selectedTopicUUId, f, daysLimit, e, emailsChanged ? props.emails : null, newPolicies, props.deletedPolicies/*selectedPolicyName, selectedGroupingName, countOfPolicy*/)
        }
        else{
            if(selectedSubtopic)
                props.updateSubtopicSubscription(props.selectedTelegramItem.id, selectedTopicUUId, selectedSubtopicId, f, daysLimit, e, emailsChanged ? props.emails : null, newPolicies, props.deletedPolicies, removeFilter)
            else
                props.updateSubscription(props.selectedTelegramItem.id, selectedTopicUUId, null, f, daysLimit, e, emailsChanged, newPolicies, props.deletedPolicies,/*selectedPolicyName, selectedGroupingName, countOfPolicy,*/ removeFilter)
        }
    }

    const selectFilter = (filter) => {
        setSelectedFilter(filter.name)
        setSelectedFilterId(filter.id)
        !filter.name ? setRemoveFilter(true) : setRemoveFilter(false)
    }

    /*const selectCountOfPolicy = (e) => {
        setCountOfPolicy(e)
        setRerender(!rerender)
    }
    const selectSentiment = (e) => {
        setSelectedSentiment(e.list)
    }*/

    const closeSubscription = () => {
        props.closeSubscription();
    }
    const getSaveButtonStatus = () => {
        //return false
        if(selectedTopic)
            return false//(minCountOfPolicyZero && !selectedGrouping) ? true : countOfPolicy === 0 ? true : false
        else 
            return true
    }

    const updatePolicy = (item, value, index) => {
        let policies = policyList;
        policies[index][item] = value;
        
        if(policies[index].alertPolicy.includes('AGGREGATE')){
            if((policies[index].metricType === 'COUNT' || policies[index].metricType === 'ABSOLUT_COUNT') && policies[index].target <= 0 ){
                    policies[index].target = 1
            }
            if(!policies[index].grouping || policies[index].grouping === ''){
                policies[index].grouping = 'DAILY'
            }
        }                      
        setPolicyList(policies);
        setRerender(!rerender)
    }

    const setStartTime = (startTime) => {
        setPolicyStartTime(startTime)
    }

    const getByPolicy = (policy, i) => {
        let n = showPolicyItem.indexOf(i) === -1 ? false : true;
        if(policy.alertPolicy === 'AGGREGATE'){
            policy.alertPolicy = 'AGGREGATE_'+policy.metricType
        }
        switch(policy.policyType){
            case 'EVERY_PUBLICATION':
                return <EveryPublication updatePolicy={updatePolicy} keyIndex={i} alertPolicy={policy} numberBlock={<InputNumber />} getValue={props.getValue} telegramSentiments={telegramSentiments} groupings={groupings} showPolicyItem={n} metricTypes={metricTypes} />
            default:
                return <EveryPublication updatePolicy={updatePolicy} keyIndex={i} alertPolicy={policy} numberBlock={<InputNumber />} getValue={props.getValue} telegramSentiments={telegramSentiments} groupings={groupings} showPolicyItem={n} metricTypes={metricTypes} />
        }
    }

    const addNewPolicyItem = () => {

        let p = {
            alertPolicy: "EVERY_PUBLICATION",
            policyType: "",
            target: 0,
            deliveryType: "TELEGRAM",
            //policyStartTime: null
        }
        let n = policyList;

        n.push(p)
        setPolicyList(n);
        setRerender(!rerender)
    }

    const removePolicyItem = (index) => {
        if(policyList[index].id){
            props.setDeletedPolicies({id: policyList[index].identificator, policy: policyList[index].alertPolicy})
        }
        let n = policyList;
        n.splice(index, 1);
        setPolicyList(n)

        setRerender(!rerender)
    }

    const showPoliciesItem = (item) => {

        let showPolicies = showPolicyItem;
        let f = showPolicies.indexOf(item)
        if( f !== -1)
            showPolicies.splice(f, 1)
        else
            showPolicies.push(item)
        setShowPolicyItem(showPolicies)
        setRerender(!rerender)
    }

    const setDaysLimitItem = (item) => {
        setDaysLimit(item)
    }

    const setEmailsList = (emails) => {
        setEmails(emails)
        setEmailsChanged(true)
    }

    const addNewPolicyButton = <StandartButton width={34} icon={PlusIcon} stroke={'dark'} background="background" callback={addNewPolicyItem}/>
    
    const getPolicyName = (policy) => {
        if(policy.alertPolicy === 'AGGREGATE'){
            return policy.alertPolicy = 'AGGREGATE_'+policy.metricType
        }
        else return policy.alertPolicy
    }

    const policiesList = (policyList && policyList.length) ? policyList.map((k,i) => {
        let n = showPolicyItem.indexOf(i) === -1 ? false : true;

        let pol = props.getValue(getPolicyName(k))
        return  <div className={s.PolicyItems} key={i} >
                    <div style={{display: 'flex', alignItems: 'flex-end'}}>
                        <SelectDropdown showOnly={ k.id ? true : false} selected={pol} items={telegramPolicies} setSelected={setPolicy} indexKey={i} labelText={props.getValue('POLICY')}/>
                        <div style={{marginLeft: '12px'}}><StandartButton width={34} icon={n ? AngleDownIcon : AngleUpIcon } stroke={'dark'} background="background" callback={showPoliciesItem.bind(this, i)}/></div>
                    </div>
                    {getByPolicy(k, i)}
                    {!showOnly &&  <div className={s.itemRow}>
                        <span className={s.titleText}>{ props.getValue('DELETE_POLICY') }</span>
                        <StandartButton width={34} icon={RecycleBinIcon} stroke={'dark'} background="background" callback={removePolicyItem.bind(this, i)}/>
                    </div>}
                    {/*i === policyList.length -1 ? addNewPolicyButton : null*/}
                </div>
    }) : null

    return (
        <div className={s.TelegramItem}>
            <div className={s.ItemRow}>
                <SelectDropdown showOnly={showOnly} selected={selectedTopic} items={props.topics ? props.topics : []} placeholder={props.getValue('TOPIC_NAME')} labelText={props.getValue('TOPIC_NAME')} setSelected={selectTopic}/>
            </div>
            {(props.subtopics && props.subtopics.length) && <div className={s.ItemRow}>
                <SelectDropdown showOnly={showOnly} disabled={!props.subtopics} selected={selectedSubtopic} items={props.subtopics ? props.subtopics : []} placeholder={props.getValue('SUBTHEME_NAME')} labelText={props.getValue('SUBTHEME_NAME')} setSelected={setSelectSubtopic}/>
            </div>}
            <div className={s.ItemRow}>
                <SelectDropdown showOnly={showOnly} selected={selectedFilter} items={filters ? filters : []} placeholder={props.getValue('SAVED_FILTERS')} labelText={props.getValue('SAVED_FILTERS')} setSelected={selectFilter}/>
            </div>
            <div className={s.ItemRow}>
                    <span className={s.labelText}>{props.getValue('RECIPIENTS_EMAILS')}</span>
                    <EmailsList showOnly={showOnly} emails={emails} callback={setEmailsList}/>
                </div>
                { /*<div className={s.ItemRow}>
                <InputNumber showOnly={showOnly} labelText={props.getValue('DAYS_COUNT')} callback={setDaysLimitItem} value={daysLimit}/>
            </div>

            <div className={s.ItemRow}>
                <SelectDropdown showOnly={showOnly} selected={selectedPolicy} items={telegramPolicies} placeholder={props.getValue('POLICY')} labelText={props.getValue('POLICY')} setSelected={setPolicy}/>
    </div> }
            {(selectedPolicy && minCountOfPolicyZero) && <div className={s.ItemRow}>
                <SelectDropdown showOnly={showOnly} selected={selectedGrouping} items={groupings ? groupings : []} placeholder={props.getValue('GROUP_BY')} labelText={props.getValue('GROUP_BY')} setSelected={setGrouping}/>
            </div>}
            { selectedPolicy && <div className={s.ItemRow}>
                <InputNumber showOnly={showOnly} callback={selectCountOfPolicy} value={countOfPolicy} labelText={props.getValue('COUNT')} min={!minCountOfPolicyZero ? (selectedPolicyName === 'EVERY_PUBLICATION_TELEGRAM' ? 0 : 1) : -10} />
            </div> */}
            
            <div className={s.PolicyItemsBlock}>
                {policiesList}
                
            </div>
            {!showOnly && <div className={s.itemRow}>
                        <span className={s.titleText}>{ props.getValue('ADD_POLICY') }</span>
                        {addNewPolicyButton}
                    </div>}
            
            {/*policyType*/}
            {  (!showOnly && subscriptionId) && <div className={s.ItemRow}>
                <TelegramBots   getValue={props.getValue} 
                                addSubscriberToSubscription={props.addSubscriberToSubscription} 
                                subscriptionId={subscriptionId}
                                subscribers={props.subscribers}/>
            </div> }
            { !showOnly && <div className={s.ItemRow+" "+s.buttonsBlock}>
                <StandartButton disabled={getSaveButtonStatus()} icon={SaveIcon} title={props.getValue('SAVE')} fill={'white'} callback={props.selectedTelegramItem ? updateSubscription : createSubscription} />
                <StandartButton icon={CloseIcon} title={props.getValue('CANCEL')} stroke={'white'} callback={closeSubscription} />
            </div>}
            
        </div>
    )
}

export default TelegramItem;