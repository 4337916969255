import { header, getLanguage } from "./header";

const accounts = header('restapi/api/v1/accounts/');

const setLanguage = () => {
    accounts.defaults.headers.common['Accept-Language'] = getLanguage()
}

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    accounts.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const getLangCode = () => {

}

export const AccountService = {
    getMe(){
        setLanguage();
        getAuth();
        return accounts.get('me').then(response => {
            return response
        })
    },
    locale(lang){
        setLanguage();
        getAuth();
        return accounts.put(`locale/${lang}`)
    },
    getAuthorities(){
        setLanguage();
        getAuth();
        return accounts.get(`authorities`).then( response => {
            return response.data
        })
    }
}