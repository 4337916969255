import { createSelector } from "reselect";
import moment from "moment";
import { getFormatDate } from "service/format-date";

const topicTableFromState = (state) => {
    return state.themeSettings.data;
}

const topicSubtopicsFromState = (state) => {
    return state.TopicItem.subtopics;
}

const formatDate = (date) =>{
    return getFormatDate(date, "HH:mm DD.MM.YY")//moment(date).format("HH:mm DD.MM.YY");
}

const subtopicItem = (state) => {
    return state.themeSettings.subtopicItem;
} 

export const getSubtopicItem = createSelector(subtopicItem, (subtopic) => {
    return subtopic
})

export const getTopicTableFromState = createSelector(topicTableFromState, (table) => {
    let t = []
    if(table){
        t = table.map((item)=>{
            return [item.id, item.status, item.name, formatDate(item.createdAt), formatDate(item.updatedAt), item.keywords, item.stopwords]
        });
    }
    return t
})

export const getAllTopicsName = createSelector(topicTableFromState, (topics) => {
    if(topics && topics.length){
        return topics.map((item)=>{
            return {id: item.id, name: item.name}
        })
    }
})

export const getTopicDropdownFromState = createSelector(topicTableFromState, (table) => {
    if(table){
        return table.map((item)=>{
            return {id: item.id, name: item.name, createdAt: formatDate(item.createdAt), updatedAt: formatDate(item.updatedAt), keywords: item.keywords, stopwords: item.stopwords, uuid: item.uuid, status: item.status} 
        });
    }

})

export const getTopicsFiltersSelectorTree = createSelector(topicTableFromState, (data) => {
    if(data){
        return data.map((item)=>{
            return {field: 'topics', id: item.id, name: item.name, list: item.uuid, uuid: item.uuid} 
        });
    }
})

export const getTopicsFiltersSelector = createSelector(topicTableFromState, (data) => {
    if(data){
        return data.map((item)=>{
            return {field: 'topics', id: item.id, name: item.name, list: item.name, uuid: item.uuid} 
        });
    }
})

export const getTopics = createSelector(topicTableFromState, (topics) => {
    if(topics){
        return topics.map((item)=>{
            return {id: item.id, name: item.name, createdAt: item.createdAt, updatedAt: item.updatedAt, keywords: item.keywords, stopwords: item.stopwords, uuid: item.uuid, status: item.status, subtopics : item.subtopics}  
        });
    }
    else
        return null
})

export const getTopicName = createSelector(topicTableFromState, (table) => {
    if(table){
        return table.map((item)=>{
            return {id: item.id, name: item.name, uuid: item.uuid, subtopics: item.subtopics} 
        });
    }

})

export const getSubtopicTableFromState = createSelector(topicSubtopicsFromState, (table) => {
    let content = (table.topicId && table.data) && table.data.data.content;
    if(table.topicId && table.topicId !== undefined && content.length){
        return content.map((item)=>{
            let n =  [item.id, item.status, item.name, formatDate(item.createdAt), formatDate(item.updatedAt), item.keywords, item.stopwords];
            return n
        });
    }

})

export const getSubtopicsFromState = createSelector(topicSubtopicsFromState, (data) => {
    let content = (data.topicId && data.data) && data.data.data.content;
    if(data.topicId && content.length){
        return content.map((item)=>{
            return {id: item.id, name: item.name, createdAt: formatDate(item.createdAt), updatedAt: formatDate(item.updatedAt), keywords: item.keywords, stopwords: item.stopwords, uuid: item.uuid, status: item.status, topicId: data.topicId}
        });
    }
    else return null
})

export const getSubtopicsDropdownFromState = createSelector(topicSubtopicsFromState, (data) => {
    let content = (data.topicId && data.data) && data.data.data.content;
    if(data.topicId && content.length){
        content.unshift({name: null, id: null})
        return content.map((item)=>{
            let n = {id: item.id, name: item.name, createdAt: formatDate(item.createdAt), updatedAt: formatDate(item.updatedAt), keywords: item.keywords, stopwords: item.stopwords, uuid: item.uuid, status: item.status, topicId: data.topicId}
            return n
        });
    }
    else return null
})
