import React from "react";
import { StandartButton } from "../../commons/form-controls"
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as PasteIcon } from "../../assets/icons/paste.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ReturnIcon} from "../../assets/icons/return.svg";
import { ReactComponent as AddUserIcon } from "../../assets/icons/add_user.svg";
import { ReactComponent as PublicIcon } from "../../assets/icons/public.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { ReactComponent as ToCompanyIcon } from "assets/icons/to-company.svg";
import { ReactComponent as RetryIcon } from "assets/icons/retry.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";

import s from "./control-buttons.module.scss";

const ControlButtonsView = (props) => {

    const getIsDisabled = (disabled) => {
        if(props.disabled && props.disabled.length){
           return props.disabled.indexOf(disabled) !== -1 ? true : false
        }
        else
            return false
    }

    const confirm = (type) => {
        if(type === 'delete'){
            onClickButton('delete', true)
        }
        else if(type === 'ACTIVE'){
            onClickButton('ACTIVE', true)
        }
        else
            props.modalShowToggle(false)
    }

    const onClickButton = (type, isConfirmed = false) => {
        if(type === 'delete' && !isConfirmed){
            props.modalShowToggle(type, true, confirm, props.getValue('DELETE'), props.getValue('SURE_WANT_TO_DELETE'), props.getValue('DELETE'));
        }
        else if(type === 'ACTIVE' && !isConfirmed){
            props.modalShowToggle(type, true, confirm, props.getValue('PAUSE'), props.getValue('SURE_WANT_TO_STOP'), props.getValue('PAUSE'));
        }

        else{
            props.callback(type, props.keyId, props.index, props.isSubtopic);
        }
    }

    const getIcon = (name) => {
        switch(name){
            case 'ACTIVE':
                return PauseIcon;
            case 'PAUSED':
                return PlayIcon;
            case 'edit':
                return EditIcon
            case 'show':
                return EyeIcon;
            case 'copy':
                return PasteIcon;
            case 'delete':
                return CloseIcon;
            case 'return':
                return ReturnIcon;
            case 'add_user':
                return AddUserIcon;
            case 'public':
                return PublicIcon;
            case 'add':
                return PlusIcon;
            case 'remove':
                return MinusIcon;
            case 'company':
                return ToCompanyIcon;
            case 'RETRY':
                return RetryIcon;
            case 'PENDING':
                return ClockIcon
            default:
                return EditIcon;
        }
    }

    const getTitleText = (name) => {
        switch(name){
            case 'ACTIVE':
                return props.getValue('SET_PAUSE');
            case 'PAUSED':
                return props.getValue('SET_ACTIVE');
            case 'edit':
                return props.getValue('SET_EDIT');
            case 'show':
                return props.getValue('SET_VIEW');
            case 'copy':
                return props.getValue('SET_COPY');
            case 'delete':
                return props.getValue('SET_DELETE');
            case 'return':
                return props.getValue('SET_RETURN');
            case 'add_user':
                return props.getValue('SET_EDIT');
            case 'public':
                return props.getValue('SET_EDIT');
            case 'add':
                return props.getValue('SET_ADD');
            case 'remove':
                return props.getValue('SET_REMOVE');
            case 'company':
                return props.getValue('SET_GO_TO_COMPANY');
            case 'RETRY':
                return props.getValue('RETRY');
            case 'PENDING':
                return props.getValue('PENDING');
            default:
                return props.getValue('SET_EDIT');
        }
    }

    const controlButtonsItems = props.items.map((item , i) => <StandartButton name={getTitleText(item)} disabled={getIsDisabled(item)} key={i} type="button" setClass={'controlButton'} icon={getIcon(item)} callback={onClickButton.bind(this, item)} />)

    return (
        
        <div className={s.ControlButtons}>
            {controlButtonsItems}
        </div>
    )
}

export default ControlButtonsView;