import React, {useState, useEffect} from "react";
import s from "../posts.module.scss";
import { InputCheckBox } from "../../../commons/input-controls";
import { ReactComponent as AuthorIcon } from '../../../assets/icons/author.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as SmileIcon } from '../../../assets/icons/smile.svg';
import { ReactComponent as NeutralSmileIcon } from '../../../assets/icons/neutral-smile.svg'; //agressive_smile
import { ReactComponent as SadSmileIcon } from '../../../assets/icons/sad-smile.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as SearchPostIcon } from '../../../assets/icons/search-post.svg';
import { ReactComponent as LikeIcon } from '../../../assets/icons/like.svg';
import { ReactComponent as CommentsIcon } from '../../../assets/icons/comments.svg';
import { ReactComponent as FollowersIcon } from '../../../assets/icons/followers.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as SharesIcon } from '../../../assets/icons/shares.svg';
import { ReactComponent as ReachValueIcon } from '../../../assets/icons/reach_value.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as RecycleBinIcon } from '../../../assets/icons/recycle-bin.svg';
import { ReactComponent as AgressionIcon } from '../../../assets/icons/agressive_smile.svg';
import { ReactComponent as AdvertisingIcon } from '../../../assets/icons/advertising.svg';
import { ReactComponent as SwearingIcon } from '../../../assets/icons/swearing.svg';
import { ReactComponent as OkIcon } from '../../../assets/icons/ok.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as BlackListIcon } from '../../../assets/icons/black-list.svg';
import { ReactComponent as favoriteIcon } from '../../../assets/icons/favorite.svg';
import { ReactComponent as favouriteAlredyIcon } from '../../../assets/icons/favourite_alredy.svg';
import { ReactComponent as FavouriteStarIcon } from '../../../assets/icons/favourite_star.svg';

import ButtonDropdown from "./button-dropdown";
import axios from "axios";
import { StandartButton } from "../../../commons/form-controls";
import moment from "moment";
import useGeolocation from "hooks/useGeolocation";
import { getFormatDate } from "service/format-date";

const geoplace = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"geoplace/api/v1/"
})

const Post = (props) => {

    const getLocationId = () => {
        return props.commonLocation ? props.commonLocation.id : null
        let n = (props.mediaType === "social")   ? (props.profile && props.profile.location && props.profile.location.id) ? props.profile.location.id : null
                                                : props.mediaPlaceLocationId !== undefined ? props.mediaPlaceLocationId : null
                                                return isNaN(n) ? null : n 
    }

    const [post, setPost] = useState(props);
    
    const [showMoreText, setshowMoreText] = useState(false);
    const [selected, setSelected] = useState(props.selectedPost)
    const [showDeletedPanel, setShowDeletedPanel] = useState(false);
    const [showFavourite, setShowFavourite] = useState(false);
    const [isFavourite, setIsFavourite] = useState(props.isFavouriteManual);
    const [isFavouriteAuthors, setIsFavouriteAuthors] = useState(props.isFavouriteAuto);
    const [isBlackList, setIsBlackList] = useState(props.isBlacklist);
    const [geoplace, setGeoplace] = useState(props.geoplace)
    const [language, setLanguage] = useState(props.selectedLanguage);
    const [pressedBlackListButton, setPressedBlackListButton] = useState(false);
    const [rerender, setRerender] = useState(false);
    let {geolocation, setGeolocation} = useGeolocation(getLocationId());
    
    useEffect(() => {
        setPost(props);
        setSelected(props.selectedPost);
        setIsFavourite(props.isFavouriteManual);
        setIsFavouriteAuthors(props.isFavouriteAuto);
        setIsBlackList(props.isBlacklist);
        setGeoplace(props.geoplace)
        setLanguage(props.selectedLanguage);
        setPressedBlackListButton(false);

        if(!props.showMoreButton){
            setshowMoreText(false)
        }
        setRerender(!rerender);
    }, [props, props.isFavouriteManual, props.isFavouriteAuto, props.isBlackList, props.selectedLanguage])

    const blackListButtonPressed = (pressed) => {
        setPressedBlackListButton(pressed)
        setRerender(!rerender)
    }

    const setSentimentType = (signature, sentiment) => {
        props.setSentimentType([signature], sentiment)
    }

    const setPostSelected = (signature, tags) => {
        props.setSelectedPostItems(signature, tags)
    }

    const formatDate = (date) =>{
        return getFormatDate(date, "DD.MM.YY HH:mm")// moment(date).format("DD.MM.YY HH:mm ");
    }

    const getLimitedText = (text, limit) => {
        if((text && text.length > limit) && !showMoreText){
            let t = text.slice(0, limit);
            let str = t.split(" ");
            str.pop();
            return str.join(" ")+"...";
        }
        else
            return text
    }

    const showMore = () => {
        let show = !showMoreText
        setshowMoreText(show);
        if(show) props.setShowMoreTextButton(show);

    }

    const closeModal = (e) => {

    }

    const filterByDomain = (e) => {
        props.setAddSelectedMyFilter('domain', e);
        props.filtersChanged(true)

    }

    const filterByAuthor = (e) => {
        props.setAddSelectedMyFilter('author', e);
        props.filtersChanged(true)
    }

    const createTag = () => {
        props.setSelectAllItems(post.tags ? [{signature: post.signature, tags: post.tags}] : [])
        props.modalShowToggle("AddTags", true, closeModal.bind(this, this), props.getValue('ADD_EXISTING_TAGS'), {signature: post.signature, tags: post.tags, type: 'post'})
    }

    const createMarkup = (text) => {
        return {__html: text};
    }

    const removeTagFromDocument = (signature, tagId) => {
        props.removeTagFromDocument(signature, tagId)
    }

    const getPostType = (postType) => {
        switch(postType){
            case 'ARTICLE':
                return props.getValue('ARTICLE_2');
            case 'POST':
                return props.getValue('POST_2');
            case 'SHARE':
                return props.getValue('REPOST_2');
            case 'EXTENDED_SHARE':
                return props.getValue('ADDED_REPOST_2');
            case 'COMMENT':
                return props.getValue('COMMENT_2');
            case 'VIDEO':
                return props.getValue('TELEVISION_2');
        }
    }

    const showOkIcon = post.verificationStatus && (post.verificationStatus === "VERIFIED" && post.autoSentiment === post.sentiment ? <div className={s.icon}><OkIcon /> </div> : <div className={s.icon}><EditIcon /> </div>)

    const postType = getPostType(post.type)

    const highlightText = (props.selectedSubUUId && post.highlightSubtopics) ? getLimitedText(post.highlightSubtopics[props.selectedSubUUId] ? post.highlightSubtopics[props.selectedSubUUId] : post.text, 850) : getLimitedText(post.highlightFull ? post.highlightFull : post.text, 850);

    const author = <div>{post.author !== '' ? post.author : '-'}</div>

    const getFormattedCount = (num) =>{
        return Math.abs(num) > 999 
            ? (Math.abs(num) > 999999 
                ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'KK' 
                : Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K')
            : Math.sign(num)*Math.abs(num)
    }

    const getReachValueCount = () => {
        let fc = post.profile.followersCount ? post.profile.followersCount : 0;
        let lc = post.likesCount ? post.likesCount : 0;
        let cc = post.commentsCount ? post.commentsCount : 0;
        let sc = post.sharesCount ? post.sharesCount : 0;
        return fc + (lc * 100)+(cc * 150)+(sc * 150)
    }

    const getLocation = () => {
        return post.mediaType === "social" ? ((post.profile && post.profile.location) ? props.getLocations(post.profile.location.id) : '-') : props.getLocations(props.mediaPlaceLocationId);
    }

    const getTagName = (tagId) => {
        let tag = props.getTagName(tagId)
        return (tag && tag[0]) ? tag[0] : null 
    }

    const deletePost = (status, signature) => {
        props.setDocumentStatus([signature], status);
        showDeletedType(false);
    }

    const deleteAuthors = (profileUrl, status) => {
        props.setAuthorsStatus(post.topicId, encodeURIComponent(encodeURIComponent(profileUrl)), status);
        showDeletedType(false);
    }

    const toBlackList = (url, name, domain) => {
        if(pressedBlackListButton) return
        props.role === 'ROLE_MARKER'
            ? props.addMarkerProfileToBlackList(props.selectedTopicId ? props.selectedTopicId : props.topicIdNumber, url, domain ,name)
            : props.profileToBlackList(props.selectedTopicId ? props.selectedTopicId : props.topicIdNumber, url, domain, name)
        blackListButtonPressed(true)
    }

    const fromBlackList = (blackListId) => {

    }

    const toFavourite = (domain, url, name) => {
        props.addFavouriteToTopic(props.selectedTopicId ? props.selectedTopicId : props.topicIdNumber, url, domain, name);
        showFavouriteType();
    }

    const fromFavourite = (topicId, favoriteId) => {
        props.RemoveFavouriteResourceProfile(topicId, favoriteId)
    }

    const publicationToFavourite = (status, signature) => {
        props.setDocumentFavouriteStatus(post.topicId, status, [signature]);
        showFavouriteType();
    }

    const showDeletedType = (show) => {
        setShowDeletedPanel(show);
    }

    const showFavouriteType = (show) => {
        setShowFavourite(!showFavourite)
    }

    const setDomainStatus = (topicUUId, domain, status) => {
        props.setDomianStatus(topicUUId, domain, status)
    }

    const removeFromBlackList = (url, domain) => {
        props.profileFromBlackList(post.topicId, url, domain)
    }

    const tags = post.tags ? post.tags.map((t,i) => {
        let tag = getTagName(t)
        if(tag){
            return <span key={tag.id} className={s.tagItem} style={{background: (tag && tag.color) ? tag.color : '', color: (tag && tag.textColor )? tag.textColor : ''}}>{tag.name}<StandartButton callback={removeTagFromDocument.bind(this, post.signature, tag.uuid)} height={24} background={(tag && tag.color) ? tag.color : ''} stroke={(tag && tag.textColor) ? tag.textColor : ''} icon={CloseIcon}/></span>}
        }
    )   : null  
    

    const text = <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={createMarkup(highlightText)}></div>

    const isDomainSelected = ((props.applyedFilters['domain'] && props.applyedFilters['domain'] !== undefined) && props.applyedFilters['domain'].indexOf(post.domain) !== -1) ? true : false

    const isAuthorSelected = ((props.applyedFilters['author'] && props.applyedFilters['author'] !== undefined) && props.applyedFilters['author'].indexOf(post.author) !== -1) ? true : false

    const autoIcons = <div className={s.AutoIcons}>{post.isAggression && <div><AgressionIcon /></div>}{post.isSwearing && <SwearingIcon />}{post.isAdvertising && <AdvertisingIcon />}</div>

    const postButton = <InputCheckBox isChecked={selected} name={post.signature} callback={setPostSelected.bind(this, post.signature, post.tags)} />

    const blackListButton = isBlackList ? null : (post.mediaType === 'social') 
                                                        ? post.profile 
                                                                        ? <StandartButton background={!pressedBlackListButton && 'var( --tableItem )'} title={props.getValue('TO_BLACK_LIST')} icon={BlackListIcon} callback={toBlackList.bind(this, post.profile.url, post.author, null)}/> 
                                                                        : null
                                                        : <StandartButton background={!pressedBlackListButton && 'var( --tableItem )'} title={props.getValue('TO_BLACK_LIST')} icon={BlackListIcon} callback={toBlackList.bind(this, null, null, post.domain)}/>
    
    const favouriteItems = [
        {
            title: props.getValue('PUBLICTION'),
            callback: publicationToFavourite.bind(this, true, post.signature)
        },
        {
            title: props.getValue('RESOURCE'),
            callback: toFavourite.bind(this, post.domain, null)
        },
        {
            title: props.getValue('AUTHOR'),
            callback: toFavourite.bind(this, null, post.profile && post.profile.url, post.profile && post.author),
            show: post.profile ? true : false
        }
    ]

    const favouriteButton = isFavourite 
                                        ? <StandartButton background={'var( --tableItem )'} title={props.getValue('FROM_FAVOURITE')} icon={favouriteAlredyIcon} callback={publicationToFavourite.bind(this, false, post.signature)}></StandartButton> 
                                        : isFavouriteAuthors 
                                            ? <StandartButton background={'var( --tableItem )'} title={props.getValue('TO_FAVORITE')} icon={favoriteIcon} callback={publicationToFavourite.bind(this, true, post.signature)}></StandartButton> 
                                            : <ButtonDropdown callback={showFavouriteType} show={showFavourite} buttonText={props.getValue('TO_FAVORITE')} buttonIcon={favoriteIcon} getValue={props.getValue} items={favouriteItems}/>

    const RecycleBinItems = [
        {
            title: props.getValue('PUBLICTION'),
            callback: post.verificationStatus === 'DELETED' ? deletePost.bind(this, 'RESTORED', post.signature) : deletePost.bind(this, 'DELETED', post.signature)
        },
        {
            title: props.getValue('AUTHOR'),
            callback: deleteAuthors.bind(this, post.profile && post.profile.url, post.verificationStatus === 'DELETED' ? 'RESTORED' : 'DELETED'),
            show: post.profile ? true : false
        },
        {
            title: props.getValue('RESOURCE'),
            callback: setDomainStatus.bind(this, post.topicId, post.domain, post.verificationStatus === "DELETED" ? 'RESTORED' : 'DELETED')
        },
    ]
    const RecycleBinButton = post.verificationStatus === 'DELETED' 
        ? <ButtonDropdown callback={showDeletedType} show={showDeletedPanel} buttonText={props.getValue('FROM_TRASHBIN')} buttonIcon={RecycleBinIcon} getValue={props.getValue} items={RecycleBinItems}/> 
        : <ButtonDropdown callback={showDeletedType} show={showDeletedPanel} buttonText={props.getValue('TO_TRASHBIN')} buttonIcon={RecycleBinIcon} getValue={props.getValue} items={RecycleBinItems}/>

    {/*const RecycleBinButton = <div className={s.deletePostTypePanel}>
        <StandartButton background={'white'} title={props.getValue('TO_TRASHBIN')} icon={RecycleBinIcon} callback={showDeletedType}/>
        {showDeletedPanel && <div className={s.deletedPanel}>
                <span onClick={deletePost.bind(this, 'DELETED', post.signature)}>{ props.getValue('PUBLICTION') }</span>
                {post.profile && <span onClick={deleteAuthor.bind(this, post.profile.url )}>{ props.getValue('AUTHOR') }</span> }
            </div>}
</div>*/}
    
    const postTitle = getLimitedText(post.title, 150)

    return <div className={s.PostItem} >
                    <div className={s.postHeader}>
                        {postButton}
                        {isFavourite ? <FavouriteStarIcon/> : null}
                        <span className={s.postTitle}><a title={post.title} href={post.url} target="_blank" dangerouslySetInnerHTML={createMarkup(postTitle)}>{}</a></span>
                    </div>
                    <div className={s.PostItemAttributes}>
                        <div className={s.Attribute}>
                        { ((!post.author || post.author === '') && isFavouriteAuthors) && <FavouriteStarIcon /> }
                            <span className={s.authorName}>{post.domain}</span>
                            <StandartButton width="16" height="16" icon={SearchPostIcon} background={!isDomainSelected && 'var( --tableItem )'} stroke={isDomainSelected ? 'var( --tableItem )' : ''} setClass={'short'} callback={filterByDomain.bind(this, post.domain)}/>
                        </div>
                        {author && <div className={s.Attribute}>
                            { ((post.author && post.author !== '') && isFavouriteAuthors) && <FavouriteStarIcon /> }
                            { (post.author && post.author !== '') && <AuthorIcon /> }
                            { author }
                            { (post.author && post.author !== '') && <StandartButton width="16" height="16" icon={SearchPostIcon} background={!isAuthorSelected && 'var( --tableItem )'} stroke={isAuthorSelected ? 'var( --tableItem )' : ''} setClass={'short'} callback={filterByAuthor.bind(this, post.author)}/> }
                        </div>}
                        {<div className={s.Attribute}>
                            <LocationIcon/>{geolocation}
                        </div>}
                        {<div className={s.Attribute}>
                            <FollowersIcon/>{(post.mediaType === 'social' && (post.profile && post.profile.followersCount !== undefined)) ? getFormattedCount(post.profile.followersCount) : ( post.coverage !== undefined ? getFormattedCount(post.coverage) : '-')}
                        </div>}
                        {<div className={s.Attribute}>
                            <ReachValueIcon/>{post.profile && (post.profile.followersCount !== undefined || post.likesCount !== undefined || post.commentsCount !== undefined || post.sharesCount !== undefined) ? getFormattedCount(getReachValueCount()) : '-'}
                        </div>}
                        <div className={s.Attribute}>
                            <CalendarIcon/>{formatDate(post.date)}
                        </div>
                        {<div className={s.Attribute}>
                            <LikeIcon/>{post.likesCount !== undefined ? post.likesCount : '-'}
                        </div>}
                        {<div className={s.Attribute}>
                            <SharesIcon/>{post.sharesCount !== undefined ? post.sharesCount : '-'}
                        </div>}
                        {<div className={s.Attribute}>
                            <CommentsIcon/>{post.commentsCount !== undefined ? post.commentsCount : '-'}
                        </div>}
                    </div>
                    <span className={s.postText}>{text} <span onClick={showMore} className={s.showMore}>{showMoreText ? props.getValue('COLLAPSE') : (post.text && post.text.length > 300 ? props.getValue('READ_MORE') : '')}</span></span>
                    <div className={s.row}>
                        <div className={s.PostItemAttributes}>
                            <div className={s.Attribute}>{postType}</div>
                            <div className={s.Attribute}>
                                {showOkIcon}
                                <div className={post.sentiment === 'POSITIVE' ? s.green +' '+ s.IconAttribute : s.IconAttribute} onClick={setSentimentType.bind(this, post.signature, 'POSITIVE')}><SmileIcon /></div>
                                <div className={post.sentiment === 'NEUTRAL' ? s.purple + ' ' + s.IconAttribute : s.IconAttribute} onClick={setSentimentType.bind(this, post.signature, 'NEUTRAL')}><NeutralSmileIcon /></div>
                                <div className={post.sentiment === 'NEGATIVE' ? s.red + ' ' + s.IconAttribute : s.IconAttribute} onClick={setSentimentType.bind(this, post.signature, 'NEGATIVE')}><SadSmileIcon /></div>
                            </div>
                            {autoIcons}
                        </div>
                        <div className={s.controlButtons}>
                            {/*<StandartButton background={'white'} title={props.getValue('ANSWER')} icon={CallbackIcon}/>
                            <StandartButton background={'white'} title={props.getValue('SHARE')} icon={ShareIcon}/>*/}
                            {props.role === 'ROLE_MARKER' ? null : favouriteButton}
                            {blackListButton}
                            {RecycleBinButton}
                            {/*<StandartButton background={'white'} title={props.getValue('TO_TRASHBIN')} icon={RecycleBinIcon} callback={deletePost.bind(this, 'DELETED', post.signature)}/> */}
                        </div>
                    </div>

                    <div>
                    
                    </div>
                    { props.role !== 'ROLE_MARKER'
                            ? <div className={s.postTags}>
                                <div className={s.tagItems}>{tags}</div><StandartButton background={'var( --tableItem )'} color={'var(--caption)'} callback={createTag} title={props.getValue('ADD_TAG')} icon={PlusIcon}/>
                            </div>
                            : null
                    }
                </div>
}

export default Post;