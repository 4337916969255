import React, {useState, useEffect} from "react";
import List from "pages/settings-page/list/list";
import { InputNumber, InputText, SelectDropdown} from "commons/input-controls";

import s from "../edit-account/edit-account.module.scss"
import { StandartButton } from "commons/form-controls";
import AuthoritiesContainer from "items/authorities/authorities-container";

const CreateCompanyAccount = (props) => {

    const getLocale = (item, type, getType) => {
        let loc = props.accountLocales.find(k => k[type] === item)
        return loc[getType]
    }

    const [accountLogin, setAccountLogin] = useState(
        props.account && props.account.login ? props.account.login : ''
    );
    const [accountFio, setAccountFio] = useState(
        props.account && props.account.fio ? props.account.fio : ''
    );
    const [accountEmail, setAccountEmail] = useState(
        props.account && props.account.email ? props.account.email : ''
    );
    const [accountPhone, setAccountPhone] = useState(
        props.account && props.account.phone ? props.account.phone : ''
    );
    const [accountPassword, setAccountPassword] = useState(
        props.account && props.account.password ? props.account.password : ''
    );
    const [companyId, setCompanyId] = useState(
        props.companyId ? props.companyId : ''
    )
    const [accountLocale, setAccountLocale] = useState(
        props.account && props.account.locale ? props.getValue(getLocale(props.account.locale, 'list', 'name')) : ''
    );
    const [accountLocales, setAccountLocales] = useState(
        props.accountLocales && props.accountLocales.map(k => { return {name: props.getValue(k.name), title: k.name, list: k.list}})
    )
    const [accountLocaleList, setAccountLocaleList] = useState(
        props.account && props.account.locale ? props.account.locale : ''
    );
    const [accountRole, setAccountRole] = useState(
        props.account && props.account.role ? props.getValue(props.account.role) : null
    )
    const [accountRoleList, setAccountRoleList] = useState(
        props.account && props.account.role ? props.account.role : null
    )
    const [roles, setRoles] = useState(
        props.roles ? props.roles.map(k => {return {name: props.getValue(k.name), list: k.list}}) : null
    )
    const [selectedAuthorities, setSelectedAuthorities] = useState(
        props.selectedAuthorities ? props.selectedAuthorities : null
    )
    const [emailChanged, setEmailChanged] = useState(false)
    const [companyTopics, setCompanyTopics] = useState(props.companyTopics);
    const [selectedTopics, setSelectedTopics] = useState([])
    
    const phoneRegExp = /^[0-9\-\+]{11,13}$/;
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    useEffect(() => {

        setAccountLogin(props.account && props.account.login ? props.account.login : '')
        setAccountFio(props.account && props.account.fio ? props.account.fio : '')
        setAccountEmail(props.account && props.account.email ? props.account.email : '')
        setAccountPhone(props.account && props.account.phone ? props.account.phone : '')
        setAccountPassword(props.account && props.account.password ? props.account.password : '')
        setAccountLocales(props.accountLocales.map(k => { return {name: props.getValue(k.name), title: k.name, list: k.list}}));
        setAccountLocaleList(props.account && props.account.locale ? props.account.locale : '');
        setCompanyId(props.companyId ? props.companyId : '')
        setRoles(props.roles ? props.roles.map(k => {return {name: props.getValue(k.name), list: k.list}}) : null);
        //setSelectedAuthorities(props.selectedAuthorities ? props.selectedAuthorities : null);
        setEmailChanged(false)
        setCompanyTopics(props.companyTopics);
    }, [props.companyTopics])

    const addAccountLogin = (e) => {
        setAccountLogin(e)
    }

    const addAccountFio = (e) => {
        setAccountFio(e)
    }
    
    const addAccountEmail = (e) => {
        setEmailChanged(true)
        setAccountEmail(e)
    }

    const addAccountPhone = (e) => {
        setAccountPhone(e)
    }

    const addAccountPassword = (e) => {
        setAccountPassword(e)
    }

    const addAccountLocale = (e) => {
        setAccountLocale(props.getValue(e.name))
        setAccountLocaleList(e.list)
    }

    const addAccountRole = (e) => {
        setAccountRole(props.getValue(e.name))
        setAccountRoleList(e.list)
    }

    // const addAccountLocale = (e) => {
    //     setAccountLocale(e)
    // }

    const resetAllList = () => {
        setAccountLogin('');
        setAccountFio('');
        setAccountEmail('');
        setAccountPhone('');
        setAccountPassword('');
        // setAccountLocale('');
        setCompanyId('');
    }

    const CreateCompanyAccount = () => {
        let login = accountLogin.toLowerCase()
        props.callback(props.companyId, login, accountFio, accountEmail, accountPhone+'', accountPassword, accountLocaleList, false, accountRoleList, selectedTopics);
        resetAllList();
    }

    const checkPhoneNumber = () => {
        if(!accountPhone || accountPhone && accountPhone.match(phoneRegExp)){
            return true
        }
        else
            return false
    }

    const checkEmail = () => {
        if(!emailChanged || accountEmail && accountEmail.match(emailRegExp)){
            return true
        }
        else
            return false
    }

    const setSelectedTopic = (topics) => {
        setSelectedTopics(topics)
    }

    return (
        <div className={s.CreateCompanyAccount}>
            <div className={s.formRow}>
                <InputText 
                    callback={addAccountLogin}
                    value={accountLogin}
                    placeholder={props.getValue("LOGIN")}
                    readOnly={props.isEdit === 'show' && true}
                    labelText={props.getValue("LOGIN")}
                />
            </div>
            <div className={s.formRow}>
                <InputText 
                    callback={addAccountFio}
                    value={accountFio}
                    placeholder={props.getValue("FULL_NAME")}
                    readOnly={props.isEdit === 'show' && true}
                    labelText={props.getValue("FULL_NAME")}
                />
            </div>
            <div className={s.formRow}>
                <InputText 
                    showError={!checkEmail()}
                    callback={addAccountEmail}
                    value={accountEmail}
                    placeholder={props.getValue("EMAIL")}
                    readOnly={props.isEdit === 'show' && true}
                    labelText={props.getValue("EMAIL")}
                />
            </div>
            <div className={s.formRow}>
                {/* <InputText 
                    callback={addAccountPhone}
                    value={accountPhone}
                    placeholder={props.getValue("ACCOUNT_PHONE")}
                    readOnly={props.isEdit === 'show' && true}
                    labelText={props.getValue("ACCOUNT_PHONE")}
                /> */}
                <InputText
                    showError={!checkPhoneNumber()}
                    callback={addAccountPhone}
                    value={accountPhone}
                    placeholder={props.getValue("PHONE")}
                    readOnly={props.isEdit === 'show' && true}
                    labelText={props.getValue("PHONE")}
                />
            </div>
            <div className={s.formRow}>
                <InputText 
                    callback={addAccountPassword}
                    value={accountPassword}
                    placeholder={props.getValue("PASSWORD")}
                    readOnly={props.isEdit === 'show' && true}
                    labelText={props.getValue("PASSWORD")}
                />
            </div>
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('ACCOUNT_LOCALE')}</span>
                <SelectDropdown
                    items={accountLocales}
                    selected={accountLocale}
                    setSelected={addAccountLocale}
                    placeholder={props.getValue('SELECT_LANGUAGE')}
                />
            </div>
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('ROLE')}</span>
                <SelectDropdown
                    items={roles}
                    selected={accountRole}
                    setSelected={addAccountRole}
                    placeholder={props.getValue('SELECT_ROLE')}
                />
            </div>
            <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('USERS')}</span>
                <List list={props.topics} setSelected={setSelectedTopic} selected={selectedTopics} showOnly={false}/>
            </div>
            {accountRole === props.getValue('ROLE_USER') ? <div className={s.formRow}>
                <span className={s.labelText}>{props.getValue('AUTHORITIES')}</span>
                <AuthoritiesContainer />
            </div> : null}
            <div className={s.formRow}>
                <StandartButton
                    disabled={!accountLogin || !accountFio || !checkEmail() || !checkPhoneNumber() || !accountPassword}
                    title={props.getValue('CREATE')}
                    callback={CreateCompanyAccount}
                />
            </div>
        </div>
    )
}

export default CreateCompanyAccount