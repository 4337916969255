import PostsController from "./posts-controller";
import { connect } from "react-redux";
import { setShowRightMenu } from "store/side-bar";
import { getValue } from "store/languages";
import { getTopicDocuments, getTopicBlackListDocuments, getDeletedListDocuments, getTopicFavouriteDocuments, getFavouritePublications, setStorageRows, setStoragePage, setSentimentType, setDocumentStatus, setAuthorsStatus, setUpdateStorageData, setDocumentFavouriteStatus, } from "store/storage";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { modalShowToggle } from "store/modal";
import { setIsFilterChanged, getCompanyTags, setAddSelectedMyFilter, filtersChanged, setSelectedMyFilterMenu } from "store/filters";
import { setItemsCount, setCurrentPage } from "store/paginator";
import { getTopicDropdownFromState } from "selectors/theme-selector";
import { removeTagFromDocument } from "store/tags";
import { setTreeSelectedItems } from "store/tree";
//import { getGeoplaceName, setGeoplaceUpdated, setGeoplaces } from "store/geoplace";
//import { setAddSelectedMyFilter, filtersChanged } from "../../store/filters";
import { getLocations, setClearUpdatedDocuments, setSelectedPostItems, setSelectAllItems, setSelectedAllList, getNewPublicationsCount, getSubtopicsNewPublicationsCount, resetCounter, setDomianStatus, setAllShowMoreText } from "store/storage";
import { addFavouriteToTopic, RemoveFavouriteResourceProfile, profileToBlackList, profileFromBlackList } from "store/topic-item";
import { addMarkerProfileToBlackList } from "store/black-list";
import { setGrouping } from "store/calendar";
//import { setGeoplaceUpdated } from "store/geoplace"
//import { getCompanyTags } from "../../store/filters"
let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        companyId: state.Account.companyId,
        posts: state.storage.storageData,
        rows: state.storage.rows,
        page: state.storage.page,
        selectedSentiment: state.storage.selectedSentiment,
        isFetching: state.storage.isFetching,
        selectedTopic: state.Tree.selectedItem,
        selectedId: state.Tree.selectedId,
        selectedSubItem: state.Tree.selectedSubItem,
        selectedId: state.Tree.selectedId,
        selectedUUId: state.Tree.selectedUUId,
        selectedSubId: state.Tree.selectedSubId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        isChanged: state.Filters.isChanged,
        selectedItems: state.Filters.selectedMyFilter,
        selectedItemItems: state.Filters.selectedItemItems,
        clearAll: state.Filters.clearAll,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        topicData: getTopicDropdownFromState(state),
        selectedMyFilter: state.Filters.selectedMyFilter,
        applyedFilters: state.Filters.applyedFilters,
        selectedLanguage: state.languages.selectedLanguage,
        updatedDocuments: state.storage.updatedDocuments,
        tags: state.Filters.tags,
        updateStorageData: state.storage.updateStorageData,
        selectedPostItems: state.storage.selectedPostItems,
        selectedTopics: state.Filters.selectedTopics,
        selectedTopicsIds: state.Filters.selectedTopicsIds,
        newPostsCount: state.storage.newPostsCount,
        newPostsIsFetching: state.storage.newPostsIsFetching,
        statisticsError: state.storage.statisticsError,
        geoplace: state.geoplace.geoplace,
        isGeoplaceUpdated: state.geoplace.isGeoplaceUpdated,
        sort: state.storage.sort,
        allShowMoreText: state.storage.allShowMoreText,
        searchList: state.SearchText.searchList,
    }
}

export default connect(mapStateToProps, {
                                            getValue, 
                                            setShowRightMenu, 
                                            getTopicDocuments,
                                            getTopicBlackListDocuments,
                                            getTopicFavouriteDocuments,
                                            getFavouritePublications,
                                            getDeletedListDocuments,
                                            setStorageRows, 
                                            setStoragePage, 
                                            setSentimentType, 
                                            modalShowToggle, 
                                            setIsFilterChanged, 
                                            setItemsCount, 
                                            setCurrentPage, 
                                            setAddSelectedMyFilter, 
                                            filtersChanged, 
                                            getLocations, 
                                            setClearUpdatedDocuments, 
                                            getCompanyTags,
                                            removeTagFromDocument,
                                            setDocumentStatus,
                                            setAuthorsStatus,
                                            setUpdateStorageData,
                                            setTreeSelectedItems,
                                            setSelectedPostItems,
                                            setSelectAllItems,
                                            setSelectedAllList,
                                            profileToBlackList,
                                            addMarkerProfileToBlackList,
                                            profileFromBlackList,
                                            addFavouriteToTopic,
                                            setDocumentFavouriteStatus,
                                            getNewPublicationsCount,
                                            getSubtopicsNewPublicationsCount,
                                            resetCounter,
                                            setDomianStatus,
                                            setSelectedMyFilterMenu,
                                            setAllShowMoreText,
                                            setGrouping
                                            })(PostsController)