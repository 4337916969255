import TagsController from "./tags-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getCompanyTags, updateCompanyTag, deleteTag } from "store/tags";
import { getTags } from "selectors/tags-selector"

let mapStateToProps = (state) => {
    return {
        companyId: state.Account.companyId,
        tags: getTags(state),
        tableHeader: state.Tags.tableHeader,
        controlButtons: state.Tags.controlButtons,
        colors: state.Tags.colors
    }
}

const TagsContainer = connect(mapStateToProps, {  getValue, 
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent,
                                updateCompanyTag,
                                deleteTag,
                                getCompanyTags})(TagsController)

export default TagsContainer