import React from "react";
import { StandartButton } from "../../commons/form-controls";
import s from "./modal-with-buttons.module.scss";
import { ReactComponent as OkIcon } from '../../assets/icons/ok.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const ModalWithButtons = (props) => {

    const clickOnButton = (e, t) => {
        props.callback(e);
        props.modalShowToggle(e)
    }

    const buttons =  <div className={s.buttonsRow}>
                        <StandartButton type="button" title={props.buttonName} icon={OkIcon} callback={clickOnButton.bind(this, props.modalName)} stroke={'white'}/>
                        <StandartButton type="button" title={props.getValue('CANCEL')} icon={CloseIcon} callback={clickOnButton.bind(this, 'close')} stroke={'white'}/>
                    </div>

    return (
        <div className={s.ModalBlock}>
            <span>{props.body}</span>
            {buttons}
        </div>
    )
}

export default ModalWithButtons;