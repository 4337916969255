import { AnalyticService } from "../connection/analytic-service";
import { PopularWordsService } from "connection/popular-words-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
import { GeoplaceService } from "connection/geoplace-service"
import Menu from "assets/menu/menu";
import axios from "axios";

const SET_CLEAR_DATA = "SET-CLEAR-DATA";
const SET_EXTENDED_ANALYTIC_COMPONENT = "SET-EXTENDED-ANALYTIC-COMPONENT";
const SET_RANGE_DATA = "SET-RANGE-DATA";
const SET_DATA = "SET-DATA";
const SET_ANALYTIC_LANGUAGE_RANGE = "SET-ANALYTIC-LANGUAGE-RANGE";
const SET_ANALYTIC_LANGUAGE = "SET-ANALYTIC-LANGUAGE";
const SET_ANALYTIC_SENTIMENT_RANGE = "SET-ANALYTIC-SENTIMENT-RANGE";
const SET_ANALYTIC_SENTIMENT = "SET-ANALYTIC-SENTIMENT";
const SET_ANALYTIC_PUBLICATION_POSTS_TYPE_RANGE = "SET-ANALYTIC-PUBLICATION-POSTS-TYPE-RANGE";
const SET_ANALYTIC_PUBLICATION_POSTS_TYPE = "SET-ANALYTIC-PUBLICATION-POSTS-TYPE";
const SET_ANALYTIC_AUTHORS_SENTIMENT = "SET-ANALYTIC_AUTHORS-SENTIMENT"
const SET_ANAYTIC_TOP_20_SENTIMENT = "SET-ANAYTIC-TOP-20-SENTIMENT";
const SET_ANAYTIC_TOP_20_LANGUAGES = "SET-ANAYTIC-TOP-20-LANGUAGES";
const SET_ANALYTIC_KEYWORDS_SENTIMENT_RANGE = "SET-ANALYTIC-KEYWORDS-SENTIMENT-RANGE";
const SET_ANALYTIC_KEYWORDS_SENTIMENT = "SET-ANALYTIC-KEYWORDS-SENTIMENT";
const SET_ANALYTIC_MEDIA_TYPES_SENTIMENT = "SET-ANALYTIC-MEDIA-TYPES-SENTIMENT";
const SET_ANALYTIC_IS_FETCHING = "SET-ANALYTIC-IS-FETCHING";
const SET_ANALYTIC_INVOLVEMENT_SENTIMENT = "SET-ANALYTIC-INVOLVEMENT-SENTIMENT";
const SET_ANALYTIC_LOCATION = "SET-ANALYTIC-LOCATION";
const SET_ANALYTIC_FOLLOWERS_COUNT = "SET-ANALYTIC-FOLLOWERS-COUNT";
const SET_ANALYTIC_AUTHORS_AGES = "SET-ANALYTIC-AUTHORS-AGES";
const SET_ANALYTIC_AUTHORS_GENDER = "SET-ANALYTIC-AUTHORS-GENDER";

const SET_ANALYTIC_STARTED = "SET-ANALYTIC-STARTED";
const SET_FIRST_DATA = "SET-FIRST-DATA";
const SET_SECOND_DATA = "SET-SECOND-DATA";
const SET_THIRD_DATA = "SET-THIRD-DATA";
const SET_LOCATIONS_NAME = "SET-LOCATIONS-NAME"

const getDateTimeFormat = (date) => {
    return date//moment(date).format('YYYY-MM-DDTHH:mm:ss')+'Z'
}

const geoplace = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"geoplace/api/v1/"
})

const extendedAnalyticData = {
    menuItems: Menu.AnlyticPages,
    comparisonMenu: Menu.ComparisonPages,
    selectedMenu: 0,
    component: null,
    rangeData: [],
    data: [],
    languageRangeData: [],
    languageData: [],
    sentimentRange: [],
    sentiment: [],
    publictationsTypeRange: [],
    publictationsType: [],
    authorsSentiment: [],
    top20Sentiment: [],
    top20Languages: [],
    keywordsSentimentRange: [],
    keywordsSentiment: [],
    mediaTypesSentiment: [],
    involvementSentiment: [],
    followersCount: [],
    authorLocations: [],
    authorAges: [],
    authorGenders: [],
    tags: [],
    isFetching: false,
    fisrt: [],
    second: [],
    third: [],
    started: false,
    locationsName: null
}

let ExtendedAnalytic = (state = extendedAnalyticData, action) =>{
    switch(action.type){
        case SET_CLEAR_DATA:
            return {...state, first: [], second: []}
        case SET_EXTENDED_ANALYTIC_COMPONENT:
            return {...state, component: action.component}
        case SET_RANGE_DATA:
            return {...state, rangeData: action.rangeData}
        case SET_DATA:
            return {...state, data: action.data}
        case SET_ANALYTIC_LANGUAGE_RANGE:
            return {...state, languageRangeData: action.languageRangeData}
        case SET_ANALYTIC_LANGUAGE:
            return {...state, languageData: action.languageData}
        case SET_ANALYTIC_SENTIMENT_RANGE:
            return {...state, sentimentRange: action.sentimentRange,}
        case SET_ANALYTIC_SENTIMENT:
            return {...state, sentiment: action.sentiment}
        case SET_ANALYTIC_PUBLICATION_POSTS_TYPE_RANGE:
            return {...state, publictationsTypeRange: action.publicationsTypeRange}
        case SET_ANALYTIC_PUBLICATION_POSTS_TYPE:
            return {...state, publictationsType: action.publicationsType}
        case SET_ANALYTIC_AUTHORS_SENTIMENT:
            return {...state, authorsSentiment: action.authorsSentiment}
        case SET_ANALYTIC_FOLLOWERS_COUNT:
            return {...state, followersCount: action.followersCount}
        case SET_ANAYTIC_TOP_20_SENTIMENT:
            return {...state, top20Sentiment: action.top20Sentiment}
        case SET_ANAYTIC_TOP_20_LANGUAGES:
            return {...state, top20Languages: action.top20Languages}
        case SET_ANALYTIC_KEYWORDS_SENTIMENT_RANGE:
            return {...state, keywordsSentimentRange: action.keywordsSentimentRange}
        case SET_ANALYTIC_KEYWORDS_SENTIMENT:
            return {...state, keywordsSentiment: action.keywordsSentiment}
        case SET_ANALYTIC_MEDIA_TYPES_SENTIMENT:
            return {...state, mediaTypesSentiment: action.mediaTypesSentiment}
        case SET_ANALYTIC_IS_FETCHING:
            return {...state, isFetching: action.isFetching}
        case SET_ANALYTIC_INVOLVEMENT_SENTIMENT:
            return {...state, involvementSentiment: action.involvementSentiment}
        case SET_ANALYTIC_LOCATION:
            return {...state, authorLocations: action.authorLocations}
        case SET_ANALYTIC_AUTHORS_AGES:
            return {...state, authorAges: action.authorAges}
        case SET_ANALYTIC_AUTHORS_GENDER:
            return {...state, authorGenders: action.authorGenders}
        case SET_FIRST_DATA:
            return {...state, first: action.fisrt}
        case SET_SECOND_DATA:
            return {...state, second: action.second}
        case SET_THIRD_DATA:
            return {...state, third: action.third}
        case SET_ANALYTIC_STARTED:
            return {...state, started: action.started}
        case SET_LOCATIONS_NAME:
            return {...state, locationsName: action.locationsName }
        default:
            return {...state}
    }
}

export default ExtendedAnalytic;

/*Set analytic data state */

export const setStarted = (started) => {
    return {
        type: SET_ANALYTIC_STARTED,
        started: started
    }
}

const setFirstData = (data) => {
    return {
        type: SET_FIRST_DATA,
        fisrt: data
    }
}

const setSecondData = (data) => {
    return {
        type: SET_SECOND_DATA,
        second: data
    }
}

const setThirdData = (data) => {
    return {
        type: SET_THIRD_DATA,
        third: data
    }
}

export const setRangeData = (rangeData) => {
    return {
        type: SET_RANGE_DATA,
        rangeData: rangeData
    }

}

export const setData = (data) => {
    return {
        type: SET_DATA,
        data: data
    }

}

export const setAnalyticIsFetching = (isFetching) => {
    return {
        type: SET_ANALYTIC_IS_FETCHING,
        isFetching: isFetching
    }
}

export const setClearData = () => {
    return {
        type: SET_CLEAR_DATA,
        data: []
    }
}

export const setExtendedAnalyticComponent = (component) => {
    return {
        type: SET_EXTENDED_ANALYTIC_COMPONENT,
        component: component
    }
}

export const setExtendedAnalyticLanguageRangeData = (languageRangeData) => {
    return {
        type: SET_ANALYTIC_LANGUAGE_RANGE,
        languageRangeData: languageRangeData
    }
}

export const setExtendedAnalyticLanguageData = (languageData) => {
    return {
        type: SET_ANALYTIC_LANGUAGE,
        languageData: languageData
    }
}

export const setSentimentRange = (sentimentData) => {
    return {
        type: SET_ANALYTIC_SENTIMENT_RANGE,
        sentimentRange: sentimentData
    }
}

export const setSentiment = (sentimentData) => {
    return {
        type: SET_ANALYTIC_SENTIMENT,
        sentiment: sentimentData
    }
}

export const setPublicationsTypeRange = (publicationsType) => {
    return {
        type: SET_ANALYTIC_PUBLICATION_POSTS_TYPE_RANGE,
        publicationsTypeRange: publicationsType
    }
}

export const setPublicationsType = (publicationsType) => {
    return {
        type: SET_ANALYTIC_PUBLICATION_POSTS_TYPE,
        publicationsType: publicationsType
    }
}

export const setTop20Sentiment = (top20Sentiment) => {
    return {
        type: SET_ANAYTIC_TOP_20_SENTIMENT,
        top20Sentiment: top20Sentiment
    }
}

export const setTop20Languages = (top20Languages) => {
    return {
        type: SET_ANAYTIC_TOP_20_LANGUAGES,
        top20Languages: top20Languages
    }
}

export const setKeywordsSentimentRange = (keywordsSentimentRange) => {
    return {
        type: SET_ANALYTIC_KEYWORDS_SENTIMENT_RANGE,
        keywordsSentimentRange: keywordsSentimentRange
    }
}

export const setKeywordsSentiment = (keywordsSentiment) => {
    return {
        type: SET_ANALYTIC_KEYWORDS_SENTIMENT,
        keywordsSentiment: keywordsSentiment
    }
}

export const setMediaTypesSentiment = (mediaTypesSentiment) => {
    return {
        type: SET_ANALYTIC_MEDIA_TYPES_SENTIMENT,
        mediaTypesSentiment: mediaTypesSentiment
    }
}

export const setAnalitycAuthorsSentiment = (authorsSentiment) => {
    return {
        type: SET_ANALYTIC_AUTHORS_SENTIMENT,
        authorsSentiment: authorsSentiment
    }
}

export const setAuthorLocations = (authorLocations) => {
    return {
        type: SET_ANALYTIC_LOCATION,
        authorLocations: authorLocations
    }
}

export const setInvolvementSentiment = (involvementSentiment) => {
    return {
        type: SET_ANALYTIC_INVOLVEMENT_SENTIMENT,
        involvementSentiment: involvementSentiment
    }
}

export const setFollowersCount = (followersCount) => {
    return {
        type: SET_ANALYTIC_FOLLOWERS_COUNT,
        followersCount: followersCount
    }
}

export const setAuthorsAges = (authorAges) => {
    return {
        type: SET_ANALYTIC_AUTHORS_AGES,
        authorAges: authorAges
    }
}

export const setAuthorsGender = (authorGenders) => {
    return {
        type: SET_ANALYTIC_AUTHORS_GENDER,
        authorGenders: authorGenders
    }
}

export const setLocationsName = (locationsName) => {
    return {
        type: SET_LOCATIONS_NAME,
        locationsName: locationsName
    }
}

const getLocationsId = (data) => {
    let ret = data.map(k => k.value)
    return ret
}

/*Get analytic data state */

export const getDataType = (type, getData, setData, topicId, since, till, grouping, filters, subtopics, chartType = 'column', chartNumber, chartSettings) => {
    return (dispatch) => {
        getData(topicId, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters, subtopics).then(data => {
            dispatch(setData({data: [...data], topicId: topicId, type: type, chartType: chartType, chartNumber: chartNumber, chartSettings: chartSettings}));
            if(type === 'AuthorsLocations'){
                let locations = getLocationsId(data)
                dispatch(getGeoplaceDictionary(locations))
            }
        }).catch( error => {    
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setAnalyticIsFetching(false))
        })
    }
}

export const getGeoplaceDictionary = (locationsIds) => {
    return (dispatch) => {
        GeoplaceService.getGeoplaceDictionary(locationsIds).then( data=> {
            dispatch(setLocationsName(data))
        })
    }
}

export const getDataByType = (type, topicId, since, till, grouping, filters, subtopics, tags) => {
    return(dispatch)=>{
        dispatch(setAnalyticIsFetching(true));
        dispatch(setStarted(true))
        switch(type){
            case 'MENTIONS':
                //dispatch(setClearData());
                //dispatch(setFirstData(null))
                //dispatch(setSecondData(null))
                dispatch(getDataType('languageRange', AnalyticService.getAnalyticLanguageRange, setFirstData/*setExtendedAnalyticLanguageRangeData*/, topicId, since, till, grouping, filters, subtopics, 'area', 'first',{label: 'languageDate', title: 'MENTIONING_BY_DATE', graphicType: {label: 'date', data: 'language'}}));
                dispatch(getDataType('language', AnalyticService.getAnalyticLanguage, setSecondData/*setExtendedAnalyticLanguageData*/, topicId, since, till, grouping, filters, subtopics, 'Pie', 'second',{label: 'PieValue', title: 'MENTIONING_BY_LANG'}));
                dispatch(setThirdData([]))
                break;
            case 'TONE':
                //dispatch(setClearData());
                dispatch(getDataType('SentimentRange', AnalyticService.getSentimentRange, setFirstData/*setSentimentRange*/, topicId, since, till, grouping, filters, subtopics, 'area', 'first',{label: 'sentiment', title: 'TONE_BY_DATE', graphicType: {label: 'date', data: 'sentiment'}}));
                dispatch(getDataType('Sentiment', AnalyticService.getSentiment, setSecondData/*setSentiment*/, topicId, since, till, grouping, filters, subtopics, 'column', 'second',{label: 'LanguageSentiment', title: 'TONE_BY_LANG'}));
                dispatch(setThirdData([]))
                break;
            case 'TYPES_OF_POSTS':
                dispatch(getDataType('PublicationRange', AnalyticService.getPublicationTypeRange, setFirstData/*setPublicationsTypeRange*/, topicId, since, till, grouping, filters, subtopics, 'area', 'first',{label: 'postType', title: 'POST_TYPES_BY_DATES', graphicType: {label: 'date', data: 'type'}}));
                dispatch(getDataType('Publication', AnalyticService.getPublicationType, setSecondData/*setPublicationsType*/, topicId, since, till, grouping, filters, subtopics, 'column', 'second',{label: 'typeOfPosts', title: 'POST_TYPES_BY_LANG'}));
                dispatch(setThirdData([]))
                break;
            case 'AUTHORS':
                //dispatch(setClearData());
                dispatch(getDataType('AuthorsSentiment', AnalyticService.getAuthorsSentiment, setFirstData/*setAnalitycAuthorsSentiment*/, topicId, since, till, grouping, filters, subtopics, 'column', 'first',{label: 'followers_count', title: 'AUTHORS', graphicType: {label: 'author', data: 'sentiment'}}));
                //dispatch(getDataType('AuthorsInvolvementSentiment2', AnalyticService.getInvolementSentiment, setInvolvementSentiment, topicId, since, till, grouping, filters));
                
                dispatch(getDataType('FollowersCount', AnalyticService.getFollowersCounts, setSecondData/*setFollowersCount*/, topicId, since, till, grouping, filters, subtopics, 'column', 'second', {label: 'field', title: 'INFLUENCE_OF_AUTHORS'}));
                dispatch(setThirdData([]))
                break;
            case 'TOP_20_MASS_MEDIA':
                //dispatch(setClearData());
                dispatch(getDataType('Top20', AnalyticService.getTop20BySentiment, setFirstData /*setTop20Sentiment*/, topicId, since, till, grouping, filters, subtopics, 'column', 'first', {label: 'followers_count', title: 'TOP_20_MASS_MEDIA', graphicType: {label: 'domain', data: 'sentiment'}}));
                dispatch(getDataType('Top20Languages', AnalyticService.getTop20ByLanguages, setSecondData/*setTop20Languages*/, topicId, since, till, grouping, filters, subtopics, 'column', 'second', {label: 'followers_count', title: 'TOP_20_MASS_MEDIA_BY_LANG'}));
                break;
            case 'SOURCE_TYPE':
                //dispatch(setClearData());
                dispatch(getDataType('MediaType', AnalyticService.getMediaTypesSentiments, setFirstData/*setMediaTypesSentiment*/, topicId, since, till, grouping, filters, subtopics, 'column', 'first', {label: 'followers_count', title: 'TONE_BY_MASS_MEDIA_TYPES', graphicType: {label: 'mediaType', data: 'sentiment'}}));
                dispatch(setSecondData(null))
                dispatch(setThirdData([]))
                break;
            case 'TAGS':
                //dispatch(setClearData());
                dispatch(getDataType('TagsList', AnalyticService.getTagsSentiments, setThirdData/*setSentiment*/, topicId, since, till, grouping, filters, subtopics, 'column', 'third', {label: 'tags', title: 'POSTS_BY_TAGS', tags: tags}));
                dispatch(getDataType('Tags', AnalyticService.getTagsSentiments, setFirstData/*setSentiment*/, topicId, since, till, grouping, filters, subtopics, 'column', 'first', {label: 'valueSentiment', title: 'POSTS_BY_TAGS', tags: tags}),);
                dispatch(getDataType('TagsRange', AnalyticService.getTagsRange, setSecondData/*setRangeData*/, topicId, since, till, grouping, filters, subtopics, 'line', 'second', {label: 'value', title: 'TAGS_BY_TIME', tags: tags}));
                break;
            case 'REACH_OF_SOURCE':
                //dispatch(setClearData());
                dispatch(getDataType('AuthorsInvolvementSentiment', AnalyticService.getInvolementSentiment, setFirstData/*setInvolvementSentiment*/, topicId, since, till, grouping, filters, subtopics, 'column', 'first', {label: 'involement', title: 'REACH_OF_SOURCE_BY_SENTIMENT'}));
                dispatch(getDataType('AuthorsInvolvementSentiment', AnalyticService.getInvolementSentiment, setSecondData/*setInvolvementSentiment*/, topicId, since, till, grouping, filters, subtopics, 'Pie', 'second', {label: 'involement', title: 'REACH_OF_SOURCE_BY_COUNT'}));
                dispatch(setThirdData([]))
                break;
            case 'KEY_WORDS':
                //dispatch(setClearData());
                dispatch(getDataType('KeyWords', AnalyticService.getKeywordsSentiment, setFirstData, topicId, since, till, grouping, filters, subtopics, 'column', 'first', {label: 'valueSentiment', title: 'POSTS_BY_KEYWORDS'}));
                dispatch(getDataType('KeyWordsRange', AnalyticService.getKeywordsSentimentRange, setSecondData, topicId, since, till, grouping, filters, subtopics, 'line', 'second', {label: 'value', title: 'KEYWORDS_BY_TIME'}));
                dispatch(getDataType('KeyWordsList', AnalyticService.getKeywordsSentiment, setThirdData, topicId, since, till, grouping, filters, subtopics, 'column', 'first', {label: 'keywords', title: 'POSTS_BY_KEYWORDS'}));
                break;
            case 'GEOGRAPHY_OF_AUTHORS':
                dispatch(getDataType('AuthorsLocations', AnalyticService.getAuthorsLocationsIds, setFirstData/*setAuthorLocations*/, topicId, since, till, grouping, filters, subtopics, 'Pie', 'first', {label: 'authorGeography', title: 'GEOGRAPHY_OF_AUTHORS'}));
                dispatch(setSecondData(null))
                dispatch(setThirdData([]))
                break;
            case 'AUTHOR_STATISTICS':
                //dispatch(setClearData());
                dispatch(getDataType('AuthorsAge', AnalyticService.getAuthorsAge, setFirstData/*setAuthorsAges*/, topicId, since, till, grouping, filters, subtopics, 'column', 'first', {label: 'ages', title: 'AUTHOR_STATISTICS_AGE'}));
                dispatch(getDataType('AuthorsGender', AnalyticService.getAuthorsGender, setSecondData /*setAuthorsGender*/, topicId, since, till, grouping, filters, subtopics, 'Pie', 'first', {label: 'genders', title: 'AUTHOR_STATISTICS_GENDER'}));
                dispatch(setThirdData([]))
                break;
            case 'POPULAR_WORDS'://getPopularWordsBurst
                dispatch(getDataType('PopularWordsBurst', PopularWordsService.getPopularWordsBurst, setFirstData /*setAuthorsGender*/, topicId, since, till, grouping, filters, subtopics, 'area', 'first', {label: 'PopularValue', title: 'POPULAR_WORDS_BY_TIME', graphicType: {label: 'date', data: 'language'}}));
                dispatch(getDataType('PopularWordsFacets', PopularWordsService.getPopularWordsFacets, setSecondData /*setAuthorsGender*/, topicId, since, till, grouping, filters, subtopics, 'column', 'second', {label: 'PopularValueSentiment', title: 'POPULAR_WORDS_BY_SENTIMENT'}));
                
                //dispatch(setSecondData(null))
                //dispatch(getDataType('PopularWordsFacets', PopularWordsService.getPopularWordsFacets, setSecondData /*setAuthorsGender*/, topicId, since, till, grouping, filters, subtopics, 'column', 'second', {label: 'valueSentiment', title: 'AUTHOR_STATISTICS'}));
                dispatch(getDataType('PopularWords', PopularWordsService.getPopularWords,setThirdData /*setAuthorsAges*/, topicId, since, till, grouping, filters, subtopics, 'column', 'third', {label: 'popular', title: 'POPULAR_WORDS'}));
                //dispatch(getDataType('PopularWordsBurst', PopularWordsService.getPopularWordsBurst, setThirdData /*setAuthorsGender*/, topicId, since, till, grouping, filters, subtopics, 'column', 'third', {label: 'valueSentiment', title: 'AUTHOR_STATISTICS'}));
                break;
            }
    }
}