import React, { useState, useEffect } from "react";
import { StandartButton } from "../../../../../commons/form-controls";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { InputNumber, InputText, SelectDropdown } from "../../../../../commons/input-controls";
import { getValue } from "../../../../../store/languages";
import s from "./telegram-bots.module.scss";

const TelegramBots = (props) => {

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [subscribers, setSubscribers] = useState(props.subscribers)
    const [bots, setBots] = useState([]);
    const [botUsername, setBotUsername] = useState(props.subscribers && props.subscribers.length ? props.subscribers[0].botUsername : null)

    useEffect(()=>{
        setSubscribers(props.subscribers);
        setBotUsername(props.subscribers && props.subscribers.length ? props.subscribers[0].botUsername : null)
    }, [props.subscribers])

    const groups = [
        {name: 'first'}
    ]

    const setSelectGroup = (group) => {
        setSelectedGroup(group)
    }

    const setBotItems = () => {
        props.addSubscriberToSubscription(props.subscriptionId)
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
    }

    const botItems = bots.map(item => <div>{{item}}</div>);

    const botSubscribers = subscribers.length && subscribers.map((k,i)=> 
                                            <div className={s.subscriberRow} key={i}>
                                                
                                                <div className={s.itemColName}>
                                                    <span className={s.labelText}>{i+1}</span>
                                                    <div title="Нажмите чтобы скопировать" onClick={copyToClipboard.bind(this, k.code)} className={s.subscriberItem}>
                                                        <span>{k.code}</span>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>)

    return (
        <div className={s.TelegramBots}>
            {/*<SelectDropdown selected={selectedGroup} items={groups} placeholder={props.getValue('THOOSE_NAME_OF_THE_GROUP')} labelText={props.getValue('THOOSE_NAME_OF_THE_GROUP')} setSelected={setSelectGroup}/>*/}
            <div className={s.itemRow}>
            {(subscribers && subscribers.length ) ?   <div className={s.Subscribers}>
                                            <div className={s.SubscriberText}>
                                                <span> Уважаемый пользователь!</span><br/>
                                                <span>Найдите бота в telegram и отправьте ему код</span> 
                                            </div>
                                            <div className={s.subscriberItemBlock}>
                                                
                                                <div className={s.itemCol}>
                                                    <span className={s.labelText}>Бот</span>
                                                    <div title="Нажмите чтобы скопировать" onClick={copyToClipboard.bind(this, botUsername)} className={s.subscriberItem}>
                                                        <span>{botUsername}</span>
                                                    </div>
                                                    <span className={s.labelText}>Код</span>
                                                </div>
                                                <div className={s.subscribers}>
                                                    {botSubscribers}
                                                </div>

                                            </div>
                                        </div> :   <div></div>}   
                                                                 
            </div>
            <div className={s.itemRow}><span className={s.titleText}>Добавить Telegram бота</span>
                                                <StandartButton width={34} icon={PlusIcon} stroke={'dark'} background="background" callback={setBotItems}/>
                                                </div>
            <div className={s.itemRow}>

            </div>
        </div>
    )
}

export default TelegramBots;