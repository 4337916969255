import React, { useEffect, useState } from "react";
import { StandartButton } from "commons/form-controls";
import { useNavigate } from "react-router-dom";

const TopicButton = (props) => {

    const navigate = useNavigate();

    const getPageName = (pageButtons, linkName) => {
        let pageName = pageButtons.filter(k => k.link === '/page/'+linkName)
        return pageName[0] && pageName[0].base_name ? props.getValue(pageName[0].base_name) : linkName
    }

    const getTopicsCountWord = (value, words) =>{  
        value = Math.abs(value) % 100; 
        var num = value % 10;
        if(value > 10 && value < 20) return words[2]; 
        if(num > 1 && num < 5) return words[1];
        if(num == 1) return words[0]; 
        return words[2];
    }

    const SetMenuSelected = (menuSelected) =>{
        props.setSelectedMenu(menuSelected)
    }

    const getTopicNameByUUId = (topicUUId, type = null) => {
        
        if(props.selectedUUId){
            if(props.topicData){
                let topic = props.topicData.filter(f => f.uuid === topicUUId )
                
                topic = props.selectedSubUUId ? topic[0] ? topic[0].subtopics.filter(s => s.uuid === props.selectedSubUUId) : topic : topic;
                return topic && topic[0] 
                        ? type ? topic[0][type] : topic[0].name
                        : topicUUId
            }
        }
        else if(props.selectedTopics){
            return 'Выбрано ' + props.selectedTopics.length + ' ' +getTopicsCountWord(props.selectedTopics.length, ['тема', 'темы', 'тем'])
        }
    }

    const [postsCount, setPostsCount] = useState(props.postsCount);
    const [topic, setTopic] = useState(getTopicNameByUUId(props.selectedUUId, 'name'));
    const [pageButtons, setPageButtons] = useState(props.headerButtons ? Object.values(props.headerButtons).map(k => k) : [])

    useEffect(() => {
        setTopic(getTopicNameByUUId(props.selectedUUId, 'name'));
        setPostsCount(props.postsCount);
        setPageButtons(props.headerButtons ? Object.values(props.headerButtons).map(k => k) : [])
    }, [props]);
    
    const goToPage = () => {
        let filters = props.filters

        props.setStartDate(new Date(filters.since));
        props.setEndDate(new Date(filters.till));
        props.setGrouping(props.grouping);
        delete filters.since;
        delete filters.till;
        props.setMySelectedTopicsFilter(filters)
        props.filtersChanged(true);
        props.setReturnBackFilters(null);
        props.setReturnBackPageName(null);
        props.setIsReturnedBack(true);
        //props.setReturnBackPageName(getLocationName())
        setTimeout(() => {
            props.filtersChanged(false);
            SetMenuSelected(props.menuSelected)
            navigate('/page/'+props.pageName, { replace: true })
        }, 300)

    }
    
    const button = props.pageName && <StandartButton styles={{width: 255, justifyContent: 'space-between'}} type="button" title={props.getValue("RETURN_BACK_TO")+" "+getPageName(pageButtons, props.pageName)} fill="white" callback={goToPage}/> 
                                //: <StandartButton styles={{width: 255, justifyContent: 'space-between'}} type="button" title={topic ? topic : ''} badge={postsCount ? postsCount : null} fill="white"/>

    return (
        <div>
            { button }
        </div>
    )
}

export default TopicButton
