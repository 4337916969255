import { header, getLanguage } from "./header";

const subscriptions = header('subscription/api/v1/subscriptions/');

const getAuth = () =>{
    let authorization = localStorage.getItem('auth')
    subscriptions.defaults.headers.common['Authorization'] =`Bearer ${authorization}` 
}

const setLanguage = () => {
    subscriptions.defaults.headers.common['Accept-Language'] = getLanguage()
}

export const SubscriptionsService = {
    createSubscription(uuid, daysLimit){
        setLanguage();
        getAuth();
        const params = {}
        //if(daysLimit) params['daysLimit'] = daysLimit
        params['daysLimit'] = 3
        
        return subscriptions.post(`/topics/${uuid}`, {...params}).then(response => {
            return response.data
        })
    },
    createSubtopicSubscription(uuid, subtopicUUId, daysLimit){
        setLanguage();
        getAuth();
        const params = {}
        params['daysLimit'] = 3
        return subscriptions.post(`/topics/${uuid}/subtopics/${subtopicUUId}`, {...params}).then(response => {
            return response.data
        })
    },
    getSubscriptions(){
        setLanguage();
        getAuth();
        return subscriptions.get(``).then(response => {
            return response.data
        })
    },
    updateSubscription(subscriptionId, topicUUId, subtopicId, daysLimit){
        setLanguage();
        getAuth();
        //let params = {topicId, subtopicId}subtopicId
        let subtopic = subtopicId ? `?subtopicId=${subtopicId}` : ''
        let daysLimits = `?daysLimit=3`
        return subscriptions.put(`${subscriptionId}/topics/${topicUUId}${subtopic}${daysLimits}`).then(response => {
            return response.data
        })
    },
    updateSubtopicSubscription(subscriptionId, topicUUId, subtopicUUId, daysLimit){
        setLanguage();
        getAuth();
        //let params = {topicId, subtopicId}
        let daysLimits = `?daysLimit=3`

        return subscriptions.put(`${subscriptionId}/topics/${topicUUId}/subtopics/${subtopicUUId}${daysLimits}`).then(response => {
            return response.data
        })
    },
    deleteSubscription(subscriptionId){
        setLanguage();
        getAuth();
        return subscriptions.delete(`${subscriptionId}`).then(response => {
            return response.data
        })
    },
    addSubscriberToSubscription(subscriptionId){
        setLanguage();
        getAuth();
        return subscriptions.post(`${subscriptionId}/subscribers`).then(response => {
            return response.data
        })
    },
    attachFiltersToSubscription(subscriptionId, filterId){
        setLanguage();
        getAuth();
        return subscriptions.put(`${subscriptionId}/filters/${filterId}`).then(response => {
            return response.data
        })
    },
    removeFilterFromSubscription(subscriptionId, filterId){
        setLanguage();
        getAuth();
        return subscriptions.delete(`${subscriptionId}/filters/${filterId}`).then(response => {
            return response.data
        })
    },//subscriptionId, policy.policyType, policy.deliveryType, policy.grouping, policy.target
    addAlertPolicy(subscriptionId, policy, deliverysystem, target, sentiment, grouping, metricType, policyStartTime){
        setLanguage();
        getAuth();
        let params = {}
        //let params = {target: target}
        //if(grouping) params.grouping = grouping
        //if(sentiment) params.sentiment = sentiment
        if(metricType) params.metricType = metricType
        if(target) params.target = target
        if(sentiment) params.sentiment = sentiment
        if(grouping) params.grouping = grouping
        if(policyStartTime) params.policyStartTime = policyStartTime
        /*let targetQuerry = target ? `?target=${target}` : '';
        let sentimentQuerry = sentiment ? `&sentiment=${sentiment}` : '';
        let groupingQuerry = grouping ? `&grouping=${grouping}` : '';
        let metricTypeQuerry = grouping ? `&metricType=${metricType}` : '';*/
        return subscriptions.post(`${subscriptionId}/alertpolicies/${policy}/${deliverysystem}`, {...params}).then(response => {
            return response.data
        })
    },
    addSubscriptionEmails(subscriptionId, emails){
        setLanguage();
        getAuth();
        return subscriptions.post(`${subscriptionId}/emails`, [...emails]).then( response => {
            return response.data;
        });
    },
    getSubscriptionEmails(subscriptionId){
        setLanguage();
        getAuth();
        return subscriptions.get(`${subscriptionId}/emails`).then( response => {
            return response.data;
        })
    },
    deleteSubscriptionEmails(subscriptionId, emailId){
        setLanguage();
        getAuth();
        return subscriptions.delete(`${subscriptionId}/emails/${emailId}`).then( response => {
            return response.data;
        })
    },
    updatedSubscriptionEmails(subscriptionId, emails){
        setLanguage();
        getAuth();
        return subscriptions.put(`${subscriptionId}/emails/`, [...emails]).then( response => {
            return response.data;
        })
    },
    resumePauseSubscription(subscriptionId, type){
        setLanguage();
        getAuth();
        return subscriptions.put(`${subscriptionId}/${type}`).then( response => {
            return response.data;
        })
    },
    deleteAlertPolicy(subscriptionId, policy, id){
        setLanguage();
        getAuth();
        return subscriptions.delete(`${subscriptionId}/alertpolicies/${policy}/${id}`).then( response => {
            return response.data
        })
    },
    getSubscriptionAlertPolicies(subscriptionId){
        setLanguage();
        getAuth();
        return subscriptions.get(`${subscriptionId}/alertpolicies`).then(response => {
            return response.data
        })
    },
    

}