import React, { useState, useEffect } from "react";
import s from "./panel-list-of-publications.module.scss";
import {ReactComponent as LeftPanelIcon } from "../../assets/icons/left-panel.svg";
import {ReactComponent as RightPanelIcon } from "../../assets/icons/right-panel.svg";
import {ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { StandartButton } from "../../commons/form-controls";
import { InputCheckBox } from "../../commons/input-controls";
import PaginatorContainer from "../../items/paginator/paginator-container";
import TopicButtonContainer from "./topic-button/topic-button-container";

const PanelListOfPublication = (props) => {
    const [counter, setCounter] = useState(props.counter);
    const [selected, setSelected] = useState((props.selectedPostItems && props.selectedPostItems.length) && props.selectedPostItems.length === props.showedItemsCount ? true : false);
    const [selectedAllList, setSelectedAllList] = useState(props.selectedAllList);
    const [selectedUUId, setSelectedUUId] = useState(props.selectedUUId)

    useEffect(() => {
        setSelected((props.selectedPostItems && props.selectedPostItems.length) && props.selectedPostItems.length === props.posts.length ? true : false);
        setSelectedUUId(props.selectedUUId)
        setSelectedAllList(props.selectedAllList)
        /*if(props.selectedUUId){
            const interval = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
                props.getNewPublicationsCount(props.selectedUUId, false)
              }, 3000);
              
              return () => clearInterval(interval);
        }*/

    },[props.selectedUUId, props.postsCount, props.selectedPostItems, props.selectedAllList])

    const showLeftMenuPanel = () => {
        props.setShowLeftMenu(!props.showLeftMenu);
    }

    const showRightMenuPanel = () => {
        props.setShowRightMenu(!props.showRightMenu);
    }

    const setAllPostSelected = () => {
        setSelected(!selected)
        if(!selected) props.setSelectedAllList(false)
        let posts = [];
        if(!selected){
            posts = props.posts.map((k,i) => {
                return {signature: k.signature, tags: k.tags}
            });
        }
        props.setSelectAllItems(posts)
    }

    const setSelectAllList = () => {
        props.setSelectedAllList(!selectedAllList)
        setSelected(false)
        props.setSelectAllItems([])
    }

    const closeModal = () => {
        
    }

    const getPanelTitle = (side) => {
        if(side === 'left'){
            return props.showLeftMenu ? props.getValue('HIDE_LEFT_PANEL') : props.getValue('SHOW_LEFT_PANEL');
        }
        else if(side === 'right'){
            return props.showRightMenu ? props.getValue('HIDE_RIGHT_PANEL') : props.getValue('SHOW_RIGHT_PANEL');
        }
    }

    const showDownloadReport = () => {
        props.modalShowToggle("Report", true,  closeModal, props.getValue("DOWNLOAD_REPORT"))
        
    }
    
    return (
        <div className={s.PanelListOfPublications}>
            <div className={s.panel}>
                <StandartButton type="button" icon={LeftPanelIcon} fill="white" callback={showLeftMenuPanel} name={getPanelTitle('left')}/>
                <TopicButtonContainer />
            </div>
            {props.paginator && <div className={s.centralBlock}>
                <div className={s.panel}>
                    <InputCheckBox isChecked={selected} name={props.getValue("ALL_ON_PAGE")} callback={setAllPostSelected} title={props.getValue("ALL_ON_PAGE")} />
                    <span className={s.labelText}>{ props.getValue('ALL_ON_PAGE') }</span>
                </div>
                <div style={{marginLeft: '24px'}} className={s.panel}>
                    <InputCheckBox isChecked={selectedAllList} name={props.getValue("ALL_LIST")} callback={setSelectAllList} title={props.getValue("ALL_LIST")} />
                    <span className={s.labelText}>{ props.getValue('ALL_LIST') }</span>
                </div>
                <div style={{marginLeft: '24px'}} className={s.panel}>
                    <PaginatorContainer />
                </div>
                { <div className={s.postsCount}>{props.getValue('NUMBER_OF_POSTS')}: <span>{props.postsCount ? props.postsCount : 0}</span></div>}
                {/* (props.newPostsCount && selectedUUId) ? <div className={s.postsCount}>{props.getValue('NUMBER_OF_NEW_POSTS')}: <span>{props.newPostsCount}</span></div> : null */}
            </div>}
            
            <div className={s.panel}>
                {props.role !== 'ROLE_MARKER' ? <StandartButton name={props.getValue('DOWNLOAD_REPORT')} type="button" icon={DownloadIcon} fill="white" callback={showDownloadReport}/> : null}
                <StandartButton type="button" icon={RightPanelIcon} fill="white" callback={showRightMenuPanel} name={getPanelTitle('right')}/>
            </div>
        </div>
    )
}

export default PanelListOfPublication;