import { createSelector } from "reselect";
import moment from "moment";

const getMediaTypesData = (state) => {
    return state.Analytic.mediaTypes;
}

const red = "#FF0000";
const green = "#00CA5F";
const grey = "#C6C6D1";
const gray = "#9AAAB5";

const getSentimentName = (sentiment) => {
    switch(sentiment){
        case 'neutral':
            return 'NEUTRAL_3'
        case 'undefined':
            return 'NOT_DETERMINDED_3'
        case "negative":
            return "NEGATIVE_3"
        case "positive":
            return "POSITIVE_3"
    }
}

const getToneColor = (sentiment) => {
    switch(sentiment){
        case 'neutral':
        case 'NEUTRAL_3':
            return grey;
        case 'undefined':
        case 'NOT_DETERMINDED_3':
            return gray;
        case "negative":
        case "NEGATIVE_3":
            return red;
        case "positive":
        case "POSITIVE_3":
            return green;
    }
}

const getLabels = (items, type = 'value') => {
    let labels = [];
    items.forEach((k, i) => {
            k.pivots.map((p, iterator)=>{
                
                if(p.value === 'null'){
                    return
                }
                let f = labels.indexOf(p[type])
                if(f === -1)
                    labels.push(p[type])

                if(type === 'field'){
                    if(p.value === 'false'){
                        labels[iterator] = 'not_'+labels[iterator]
                    }
                }
            })

    })
    
    return labels
}

const getTypeName = (type, label, value) => {
    if(type === 'field'){
        if(value === 'false'){
            
            return 'not_'+label
        }
        else return label
    }
    else return label
}

export const getMediaTypes = createSelector(getMediaTypesData, (mediaTypes) => {
    let d = {topic: [], values: [], labels: [], colors: ["#F58219", "#9AAAB5", "#9A9EC3", "#EE6766", "#3373B7"], topicIDs: []}
    d.itemType = mediaTypes.dataType;
    d.topicIDs = mediaTypes.topicIDs ? mediaTypes.topicIDs : [];
    if(mediaTypes && mediaTypes.data && mediaTypes.data.length){
        let it = 0
        let type = mediaTypes.dataType !== 'SPECIAL_MARKS' ? 'value' : 'field';
        let colit = 0
        d.labels = getLabels(mediaTypes.data, type);

        if(d.labels.length){
            mediaTypes.data.forEach((k, i) => {
                if(k.value !== 'total'){
                    d.topic[it] = k.value
                    d.labels.map((f,j) => {
                        if(!d.values[j]) d.values[j] = []
                        if(j > 4 && i === 1){
                            d.colors.push(d.colors[colit])
                            colit++
                            if(colit>4) colit = 0;
                        }
                        if(mediaTypes.dataType === 'SENTIMENTS'){
                            d.colors[j] = getToneColor(f)
                        }
                        let finded = k.pivots.findIndex((fi, index) => {return getTypeName(type, fi[type], fi.value) === f})
                                d.values[j][it] = 0
                            if(finded !== -1){
                                if(type === 'field' && k.pivots[finded].value === 'false'){
                                    d.values[j][it] = 0
                                }
                                else
                                    d.values[j][it] = k.pivots[finded].count
                            }
    
                    })
                   it++ 
                }
            })
        }


    }
    return d
})

