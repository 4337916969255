import { createSelector } from "reselect";
import axios from "axios";

const geoplace = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"geoplace/api/v1/"
})

const setLanguage = () => {
    geoplace.defaults.headers.common['Accept-Language'] = getLanguage()
}

const getGeoplace = (labels, data) => {
    let d = ''
    geoplace.post(`/geoplaces/names/dict`, [labels], {headers: { 'Content-Type': 'application/json'},data: {}}).then((response) => {
        if(response && response.data){  
            d = response.data[labels]
        }

    })
    return d
}

const mediaTypes = (state) => {
    return state.Filters.mediaTypes;
}

const filters = (state) => {
    return state.Filters.topicFilters;
}

const selectedFilters = (state) => {
    return state.Filters.selectedMyFilter
}

const selectMyFilter = (state) => {
    return state.Filters.myFilter
}
const getTags = (state) => {
    return state.Filters.tags
}

export const getMediaTypesSelector = createSelector(mediaTypes, (mediaTypes) => {
    return mediaTypes.map((k, i) => {
        if(k.description){
            return {name: k.description}
        }
        else{
            let keys = Object.keys(k)[0]
            return {name: keys, items: k[keys].map(key => {return {name: key.description}})}
            
        }
    })
})

const getFiltersName = (name) => {
    switch(name){
        case 'sentiment':
            return 'TONE';
        case 'tags':
            return 'USER_TAGS';
        case 'keywords':
            return 'KEY_WORDS';
        case 'language':
            return 'POST_LANGUAGE';
        case 'type':
            return 'POST_TYPE';
        case 'language':
            return 'POST_LANGUAGE';
        case 'media_type':
            return 'SOURCE_TYPE';
        case 'domain':
            return 'SOURCES'
        case 'media_scale':
            return 'AUDIENCE_MAS_MEDIA_COVERAGE_INVOLVEMENT';
        case 'author':
            return 'AUTHORS'
        case 'verification_status':
            return 'VERIFICATION_STATUS'
        /*case 'common_location_country_id':
            return 'commonLocationCountryId'*/
        default:
            return name;
    }
}

const getPostTypeName = (item) => {
    switch(item.value){
        case 'article':
            item.list = 'ARTICLE';
            item.name = 'ARTICLE_2';
            return item
        case 'post':
            item.list = 'POST';
            item.name = 'POST_2';
        return item 
        case 'share':
            item.list = 'SHARE';
            item.name = 'REPOST_2';
            return item;
        case 'extended_share':
            item.list = 'EXTENDED_SHARE';
            item.name = 'ADDED_REPOST_2';
            return item;
        case 'comment':
            item.list = 'COMMENT';
            item.name = 'COMMENT_2';
            return item;
        case 'video':
            item.list = 'VIDEO';
            item.name = 'TELEVISION_2';
            return item;
        default:
            /*item.field = getListName(item.field)
            item.name = item.value;
            item.list = item.value;*/
            return item;
    }
}

const getSentimentName = (item) => {
    switch(item.value){
        case 'neutral':
            item.list = 'NEUTRAL';
            item.name = 'NEUTRAL';
            return item
        case 'negative':
            item.list = 'NEGATIVE';
            item.name = 'NEGATIVE';
            return item 
        case 'positive':
            item.list = 'POSITIVE';
            item.name = 'POSITIVE';
            return item;
        case 'undefined':
            item.list = 'UNDEFINED';
            item.name = 'NOT_DETERMINDED_3';
            return item;
        default:
            /*item.field = getListName(item.field)
            item.name = item.value;
            item.list = item.value;*/
            return item;
    }
}

const getLanguageName = (item) => {
    switch(item.value){
        case 'ru':
            item.list = 'ru';
            item.name = 'RUSSIAN_2';
            return item
        case 'kz':
            item.list = 'kz';
            item.name = 'KAZAKH_2';
            return item 
        case 'en':
            item.list = 'en';
            item.name = 'ENGLISH_2';
            return item;
        default:
            item.list = 'OHTERS'
            item.name = 'OHTERS'
            return item;
    }
}

const getMediaTypeName = (item) => {
    switch(item.value){
        case 'social':
            item.field = getListName(item.field)
            item.list = 'SOCIAL';
            item.name = 'SOCIAL_NETWORKS';
            return item
        case 'news_agency':
            item.field = getListName(item.field)
            item.list = 'NEWS_AGENCY';
            item.name = 'NEWS_AGENCIES';
            return item 
        case 'internet':
            item.field = getListName(item.field)
            item.list = 'INTERNET';
            item.name = 'INTERNET_MASS_MEDIA';
            return item;
        case 'print':
            item.field = getListName(item.field)
            item.list = 'PRINT';
            item.name = 'PRINT_MAS_MEDIA';
            return item;
        case 'tv':
            item.field = getListName(item.field)
            item.list = 'TV';
            item.name = 'TELEVISION_2';
            return item;
        case 'MASS_MEDIA':
            item.field = getListName(item.field)
            item.list = 'MASS_MEDIA';
            item.name = 'MASS_MEDIA';
        default:
            /*item.field = getListName(item.field)
            item.name = item.value;
            item.list = item.value;*/
            return item;
    }
}

const getMediaScale = (item) => {
    switch(item.value){
        case 'international':
            item.field = getListName(item.field)
            item.list = 'INTERNATIONAL';
            item.name = 'INTERNATIONAL_MAS_MEDIA';
            return item;
        case 'central':
            item.field = getListName(item.field)
            item.list = 'CENTRAL';
            item.name = 'CENTRAL';
            return item;
        case 'regional':
            item.field = getListName(item.field)
            item.list = 'REGIONAL';
            item.name = 'REGIONAL_MAS_MEDIA';
            return item;
        default:
            /*item.field = getListName(item.field)
            item.list = item.value;
            item.name = item.value;*/
            return item
            
    }
}

const getIsAdvertising = (item) => {
    switch(item.value){
        case 'true':
            item.field = 'is_advertising'
            item.list = 'WITH_ADVERTISING';
            item.name = 'WITH_ADVERTISING';
            item.value = "WITH_ADVERTISING";
            item.withSelectAll = false
            return item
        case 'false':
            item.field = 'is_advertising'
            item.list = 'WITHOUT_ADVERTISING';
            item.name = 'WITHOUT_ADVERTISING';
            item.value = "WITHOUT_ADVERTISING";
            item.withSelectAll = false
            return item
        case 'null':
            item.field = 'is_advertising'
            item.list = 'WITHOUT_ADVERTISING_FILTERS';
            item.name = 'WITHOUT_ADVERTISING_FILTERS';
            item.value = "WITHOUT_ADVERTISING_FILTERS";
            item.withSelectAll = false
            return item
        case '\\!':
            item.field = 'UNKNOWN'
            item.list = 'UNKNOWN';
            item.name = 'UNKNOWN';
            item.withSelectAll = false
            return item;
        default:
            return item;
    }
}

const getIsSwearing = (item) => {
    switch(item.value){
        case 'true':
            item.field = 'is_swearing'
            item.list = 'WITH_SWEARING';
            item.name = 'WITH_SWEARING';
            item.value = "WITH_SWEARING";
            item.withSelectAll = false
            return item
        case 'false':
            item.field = 'is_swearing'
            item.list = 'WITHOUT_SWEARING';
            item.name = 'WITHOUT_SWEARING';
            item.value = "WITHOUT_SWEARING";
            item.withSelectAll = false
            return item
        case '\\!':
            item.field = 'UNKNOWN'
            item.list = 'UNKNOWN';
            item.name = 'UNKNOWN';
            item.withSelectAll = false
            return item;
        default:
            return item;
    }
}

const getCommonLocation = (item) => {
    item.field = 'commonLocationCountryId',
    item.list = item.value,
    item.name = item.value
    item.isException = true
    return item
}

const setFormatedFilter = (item) => {
    item.name = item.value; 
    item.list = item.value;
    item.isException = true
    return item
}

const setFormatedFilterUppercase = (item) => {
    item.name = item.value.toUpperCase();; 
    item.list = item.value;
    item.field = getListName(item.field)
    return item
}

const checkByType = (item) => {
    switch(item.field){
        case 'sentiment':
            return item.value !== '\\!' && getSentimentName(item);
        case 'tags':
            return setFormatedFilter(item);
        case 'verification_status':
            return setFormatedFilterUppercase(item);
        case 'keywords':
            return item.value !== '\\!' && setFormatedFilter(item);
        case 'author':
            return item.value !== '\\!' && setFormatedFilter(item);
        case 'language':
            return item.value !== '\\!' && setFormatedFilter(item);
        case 'type':
            return item.value !== '\\!' && getPostTypeName(item);
        case 'media_type':
            return item.value !== '\\!' && getMediaTypeName(item);
        case 'domain':
            return item.value !== '\\!' && setFormatedFilter(item);
        case 'media_scale':
            return item.value !== '\\!' && getMediaScale(item);
        case 'is_advertising':
            return item.value !== '\\!' && getIsAdvertising(item);
        case 'is_swearing':
            return item.value !== '\\!' && getIsSwearing(item);
        case 'common_location_country_id':
            return getCommonLocation(item)
        default:
            return getPostTypeName(item);
    }
}
//domain, is_swearing, media_scale
const getListName = (item) => {
    switch(item){
        case 'media_type':
            return 'mediaType';
        case 'media_scale':
            return 'mediaScale';
        case 'verification_status':
            return 'verificationStatus';
       case 'common_location_country_id':
            return 'commonLocationCountryId'
        default:
            return item
    }
}

const getNameList = (item) => {
    switch(item){
        case 'mediaType':
            return 'media_type';
        case 'mediaScale':
            return 'media_scale';
        default:
            return item
    }
}

const getLanguges = (languages) => {

    let langs = [];
    let index = -1;
    let count = 0;
    languages.forEach((k, i) => {
        if( k.value === 'kz' || k.value === 'ru' || k.value === 'en'){
            langs.push({field: 'language', name: k.value, list: k.value, count: k.count, isException: true})
        }
        else{
            if(index < 0) index = i
            count += k.count
            langs[index] = ({field: 'language', name: 'OTHERS', list: 'OTHERS', count: count})
        }
    });
    return langs;
}

const getSortNumber = (itemName) => {
    switch(itemName){
        case 'media_type':
            return 0
        case 'sentiment':
            return 1
        case 'domain':
            return 2
        case 'language':
            return 3
        case 'type':
            return 4
        case 'media_scale':
            return 5
        case 'author':
            return 6
        case 'keywords':
            return 7
        case 'common_location_country_id':
            return 8
        case 'tags':
            return 9
        case 'is_advertising':
            return 10
        case 'is_swearing':
            return 11
        case 'verification_status':
            return 12
        default:
            return 13
    }
}

export const getToipicFiltersSelector = createSelector(filters, (filters) => {
    let f = filters.data ? filters.data : {}
    let res = [];

    /*if(f.is_advertising && f.is_advertising.length > 1){
        let count = 0;
        let isAdvertising = false
        f.is_advertising.forEach((item, i)=>{
            if(item.name === "WITHOUT_ADVERTISING_FILTERS"){
                isAdvertising = true
            }
            count += item.count
        })
        let allDocuments = {
            field: "is_advertising",
            value: "WITHOUT_ADVERTISING_FILTERS",
            count: count,
            list: 'WITHOUT_ADVERTISING_FILTERS',
            name: "WITHOUT_ADVERTISING_FILTERS",
        }
        if(!isAdvertising) f.is_advertising.push(allDocuments)
    }
    if(f.is_swearing && f.is_swearing.length > 1){
        console.log("swearing", f)
        let count = 0;
        let isSwearing = false
        f.is_swearing.forEach((item, i)=>{
            if(item.name === "WITHOUT_SWEARING_FILTERS"){
                isSwearing = true
            }
            count += item.count
        })
        let allDocuments = {
            field: "is_swearing",
            value: "WITHOUT_SWEARING_FILTERS",
            count: count,
            list: 'WITHOUT_SWEARING_FILTERS',
            name: "WITHOUT_SWEARING_FILTERS"
        }
        if(!isSwearing) f.is_swearing.push(allDocuments)
    }*/

    Object.keys(f).forEach((k, i) => {
        if(k === 'tags'){
            if(f[k].length > 0){
                let t = f[k].pop();
                f[k].unshift(t)
                let withTag = null
                if(!f[k][0].list && f[k][0] !== '\\!')
                withTag = {value: '\\*', field: "tags"}
                if(withTag) f[k].unshift(withTag)
            }
        }
        else if (k === 'verification_status'){
            let t = f[k].pop();
            f[k].unshift(t)
            /*let any = null
            if(!f[k][0].list && f[k][0] !== '\\!')
            any = {value: 'ANY', field: "verification_status"}
            if(any) f[k].splice(1,0,any)*/
        }
        else if(k === 'is_advertising' || k === 'is_swearing'){
            f[k].withSelectAll = false
        }

        res[i] = {name: getFiltersName(k), list: getListName(k), withSelectAll: f[k].withSelectAll !== undefined ? f[k].withSelectAll : true, sorting: getSortNumber(k), items: k === 'language' 
                                                                ? getLanguges(f[k])/*f[k].map(f => checkByType(f)) */
                                                                : /*(k === 'media_type' 
                                                                                    ? [checkByType(f[k].find(j => j.value === 'social')),{name: 'MASS_MEDIA', count: f[k].filter(find => find.value !== 'social').reduce((a, b, i)=>{;return i == 1 ? a.count+b.count : a+b.count}), items: f[k].filter(find => find.value !== 'social').map(t => checkByType(t))}]
                                                                                    : */f[k].filter(f => checkByType(f))//), list: k
                 }
    })
    
    res = res.sort((a, b) => {return a.sorting - b.sorting});

    return res;
})

export const getSelectedFilters = createSelector(selectedFilters, (filter) => {
    let selected = []
    Object.keys(filter).forEach((k,i) => {
        if(filter[k] && typeof(f[k]) === 'object' && filter[k].length){
            selected[k] = f[k]
        }
    })
    return selected
})

const filtersName = {
    till: 'till',
    since: 'since',
    grouping: 'grouping',
    sort: 'sort'
}

export const getMyFilterItem = createSelector(selectMyFilter, (filter) => {
    let myFilter = []
    myFilter = Object.entries(filter).filter( k => {
        
        if(k[0] === 'isAdvertising'){

            let adv = k[1] || k[1] === false ? k[1] : 'null'

            switch(adv){
                case true:
                    k[1] = 'WITH_ADVERTISING';
                    break;
                case false:
                    k[1] = 'WITHOUT_ADVERTISING';
                    break;
                case 'null':
                    k[1] = 'WITHOUT_ADVERTISING_FILTERS';
                    break;
            }
            //k[1] = 'ALL_DOCUMENTS'
        }
        if(k[0] === 'isSwearing'){

            let swe = k[1] || k[1] === false ? k[1] : 'null'

            switch(swe){
                case true:
                    k[1] = 'WITH_SWEARING';
                    break;
                case false:
                    k[1] = 'WITHOUT_SWEARING';
                    break;
                /*case 'null':
                    k[1] = 'WITHOUT_SWEARING_FILTERS';
                    break;*/
            }
            //k[1] = 'ALL_DOCUMENTS'
        }
        return ((k[1] && k[1].length) && !filtersName[k[0]]) && k
    });

    return myFilter
})

