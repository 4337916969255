import React from "react";
import Topics from "./topics";
import TopicItemContainer from "./topic-item/topic-item-container";
import ControlButtons from "items/control-buttons/control-buttons";
import { getFormatDate } from "service/format-date";

class TopicsController extends React.Component{

    constructor(props){
        super(props);
        this.state = ({
            update: false,
            selectedTopic: 0,
            subtopics: props.topics && props.topics[0] && props.topics[0].subtopics ? props.topics[0].subtopics : null,
            hiddenColumns: [],
            selectedLanguage: props.selectedLanguage
        })
        //this.props.setSubtopicItem([]);
        this.controlButtons = [];
        this.subtopicControlButtons = [];
    }


    componentDidMount(){
        this.props.getMyTopics();
        if(this.props.topics){
            this.setState({
                subtopics: this.props.topics && this.props.topics[this.state.selectedTopic] && this.props.topics[this.state.selectedTopic].subtopics ? this.props.topics[this.state.selectedTopic].subtopics : null//this.props.topics[this.state.selectedTopic].subtopics
            })
        }
    }

    formatDate = (date) =>{
        return getFormatDate(date, "HH:mm DD.MM.YY");
    }

    closeModal = () => {
        this.props.modalShowToggle(false);
        this.props.setErrorInAction(null)
    }

    getTopics = (sortField, sortDirection) => {
        this.props.getMyTopics(this.props.role, sortField, sortDirection);
        this.setState({
            update: true
        })
    }


    
    componentDidUpdate(){
        if(this.props.topics){
            if(this.props.isDataUpdated || this.state.selectedLanguage !== this.props.selectedLanguage){
                
                this.props.getMyTopics()
                this.setState({
                    subtopics: this.props.topics && this.props.topics[this.state.selectedTopic] && this.props.topics[this.state.selectedTopic].subtopics ? this.props.topics[this.state.selectedTopic].subtopics : null,//this.props.topics[this.state.selectedTopic].subtopics
                    selectedLanguage: this.props.selectedLanguage
                })
                this.props.setUpdatedData(false)
                //this.props.getSubtopics(this.state.selectedTopic)
                
            }
            if((this.props.topics[this.state.selectedTopic] && this.props.topics[this.state.selectedTopic].subtopics) && this.state.update)
                this.setState({
                    subtopics: this.props.topics && this.props.topics[this.state.selectedTopic] && this.props.topics[this.state.selectedTopic].subtopics ? this.props.topics[this.state.selectedTopic].subtopics : null,//this.props.topics[this.state.selectedTopic].subtopics
                    update: false
                })

            /*if(this.props.subtopics === undefined && !this.state.update && this.props.topics[0]){
                //this.props.getSubtopics(this.props.topics[0].id);
                this.setState({
                    update: true,
                    selectedTopic: this.props.topics[0].id,
                    subtopics: this.props.topics && this.props.topics[0] && this.props.topics[0].subtopics ? this.props.topics[0].subtopics : null,
                })
            }*/
            

        }
        
    }

    componentWillUnmount(){
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    onSend = (type, themeId = null) => {
        this.showRightMenu(false, type, themeId);
    }

    getTitle = (type, isSubtopic) => {
        switch(type){
            case 'edit':
                return isSubtopic ? this.props.getValue('SUBTOPIC_SETTINGS_EDIT') : this.props.getValue('TOPIC_SETTINGS_EDIT');
            case 'show':
                return this.props.getValue('TOPIC_SETTINGS_VIEW');
            case 'create':
                return this.props.getValue('CREATE_TOPIC');
            case 'createSubtopic':
                return this.props.getValue('CREATE_SUBTOPIC');
        }
    }

    showRightMenu = (show, edit = null, index = null, id = null, isSubtopic = false) => {
        let topicItem = {}
        if(isSubtopic){
            if(edit === 'createSubtopic'){ 
                topicItem.name = ''
            }
            else{
                topicItem = this.props.topics[this.state.selectedTopic].subtopics[index];
            }
        }
        else{
            topicItem = (edit !== 'create' && edit !== 'createSubtopic') ? this.props.topics[index] : null;
        }
        let title = this.getTitle(edit, isSubtopic);

        show
            ? (this.props.setRightMenuComponent(
                                                <TopicItemContainer 
                                                        onClose={this.showRightMenu} 
                                                        isEdit={edit} 
                                                        topic={topicItem}
                                                        topicId={id}
                                                        callback={this.onSend}
                                                        getValue={this.props.getValue}
                                                        isSubtopic={isSubtopic}
                                                        />, 'ThemItem', null, title, true), this.props.setShowRightMenu(true), this.props.setThemeEditType(edit))
            : (this.props.setShowRightMenu(false), this.props.setRightMenuComponent(null))
    }

    indexSorting = (type, value)=>{
        switch(type){
            case 'index':
                this.props.setIndexSort(value);
                break;
            case 'name':
                this.props.setNameSort(value);
                break;
            case 'createdDate':
                this.props.setCreatedDateSort(value);
                break;
            case 'editedDate':
                this.props.setEditedDateSort(value);
                break;
        }
    }

    deleteItem = (key) => {
        this.props.deleteTopic(key);
    }

    onTableItemsClick = (type, key, index, isSubtopic) => {
        if(!key && key === undefined)
            return
        switch(type){
            case 'ACTIVE':
                this.props.pauseTopic(key);
                break;
            case 'PAUSED':
                this.props.startTopic(key);
                break;
            case 'delete':
                this.deleteItem(key)
                break;
            case 'edit':
                this.showRightMenu(true, 'edit', index, key, isSubtopic);
                break;
            case 'show':
                this.showRightMenu(true, 'show', index, key, isSubtopic);
                break;
            default:
                this.props.modalShowToggle("Alert", true,  this.closeModal, "Действие", "Действие данной кнопки находтся в стадии разработки")
                return;
        }
    }

    getControlButtonItems = (id, status, items, index, isSubtopic) => {
        let c = [];
        c = [...items];
        c.unshift(status)
        let sub = isSubtopic;
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index} isSubtopic={isSubtopic}/>
    }

    checkKeywords = (keywords) => {
        return keywords.length > 100
                                        ? <span title={keywords}>{keywords.substr(0, 100)+'...'}</span>
                                        : <span>{keywords}</span>
    }

    setTableValues = (tableValues, isSubtopic) => {
        if(!isSubtopic)
            this.controlButtons = []
        else{
            this.subtopicControlButtons = []
            
           
        }
        return tableValues.map( (k, i) => {
            let kw = k.keywords ? k.keywords : '';
            
            let sw = k.stopwords ? k.stopwords : '';

            let kkeywords = this.checkKeywords(kw)
            let kstopwords = this.checkKeywords(sw)
            let ctrl = [];
            ctrl = this.props.controlButtons 
            if(isSubtopic)
                this.subtopicControlButtons.push(this.getControlButtonItems(k.id, k.status, ctrl, i, isSubtopic))
            else
                this.controlButtons.push(this.getControlButtonItems(k.id, k.status, ctrl, i))
            return [i+1, k.name, this.formatDate(k.createdAt), this.formatDate(k.updatedAt), kkeywords, kstopwords];
        })
    }

    getSubtopics = (item, index) => {
        this.setState({
            selectedTopic: index,
            subtopics: this.props.topics && this.props.topics[index] && this.props.topics[index].subtopics ? this.props.topics[index].subtopics : null
        })
    }

    render(){
        return this.props.topics && <Topics {...this.props}
                                getTopics={this.getTopics}
                                getSubtopics={this.getSubtopics}
                                createTopic={this.showRightMenu}
                                hiddenColumns={this.state.hiddenColumns}
                                tableHeaders={this.props.tableHeaders ? this.props.tableHeaders : null }
                                subtopicTableHeader={this.props.subtopicTableHeader}
                                tableValues={this.props.topics ? this.setTableValues(this.props.topics) : null} 
                                tableSubtopics={(this.props.topics[this.state.selectedTopic] && this.props.topics[this.state.selectedTopic].subtopics) ? this.setTableValues(this.props.topics[this.state.selectedTopic].subtopics, true) : null}
                                controlButtons={this.props.controlButtons}
                                subtopicControlButtons={this.props.subtopicControlButtons}
                                subtopicControllerButtons={this.subtopicControlButtons}
                                controllerButtons={this.controlButtons}
                                selectedLanguage={this.props.selectedLanguage}
                                indexSorting={this.indexSorting}/>

    }

}

export default TopicsController;