import { AccountService } from "../connection/account-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_LANGUAGE = "SET-LANGUAGE";
const SET_UNAUTHORIZED_LANGUAGE = "SET-UNAUTHORIZED-LANGUAGE";

if(!sessionStorage.getItem('selectedLanguage')){
    sessionStorage.setItem('selectedLanguage', 'RUS');
}

let languagesPack = null;

let selectLanguageMenu = (selectedLanguage) => {
    sessionStorage.setItem('selectedLanguage', selectedLanguage)
}

let languageState = {
    selectedLanguage: sessionStorage.getItem('selectedLanguage'),
    unAuthorizedLanguage: null,
    icon: 'name',
    callback: selectLanguageMenu,
    LanguageItems: 
    [
        {name: "РУС", short_name: 'RUS', title: 'RUSSIAN'},
        {name: "КАЗ", short_name: 'KAZ', title: 'KAZAKH'},
        {name: "АНГ", short_name: 'ENG', title: 'ENGLISH'}
    ]
}

const getLanguageCode = (lang) => {
    switch(lang){
        case 'RUS':
            return 'ru';
        case 'ENG':
            return 'en'
        case 'KAZ':
            return 'kk';
        default:
            return 'ru'
    } 
}

let languages = (state = languageState, action) => {
    switch(action.type){
        case SET_LANGUAGE:
            return {...state, selectedLanguage: action.selectedLanguage, unAuthorizedLanguage: null}
        case SET_UNAUTHORIZED_LANGUAGE:
            return {...state, selectedLanguage: action.selectedLanguage, unAuthorizedLanguage: action.selectedLanguage}
        default:
            return state

    }
}

export default languages;



export const setSelectedUnAuthorizedLanguage = (type, selectedLanguage) => {
    selectLanguageMenu(selectedLanguage);
    retJs();
    return {
        type: SET_UNAUTHORIZED_LANGUAGE,
        selectedLanguage: selectedLanguage,
    }
}

export const setSelectedLanguage = (type, selectedLanguage) => {
    selectLanguageMenu(selectedLanguage);
    retJs();
    return {
        type: SET_LANGUAGE,
        selectedLanguage: selectedLanguage
    }
}

export const retJs = () => {
    let m = sessionStorage.getItem('selectedLanguage') ? sessionStorage.getItem('selectedLanguage').toLocaleLowerCase() : 'ru';
    
    if(m === undefined || m === 'undefined')
        m = "rus";
    languagesPack = require(`../assets/titles/tiltles_${m ? m : 'rus'}.js`).default;
}

export const getValue = (value) => {
        return (dispatch) => {
            if(!languagesPack)
                dispatch(retJs);
                return languagesPack[value] ? languagesPack[value] : value
            
        }    
}
export const setlocale = (type, selectedLanguage) => {
    return (dispatch) => {
        dispatch(setSelectedLanguage(type, selectedLanguage));
        AccountService.locale(getLanguageCode(selectedLanguage)).then(res => {
            
        }).catch(error => {
            //dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}