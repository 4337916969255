import BlackListController from "./black-list-controller";
import { connect } from "react-redux";
import { getValue } from "../../store/languages";
import { getMyTopics, setThemeTableData } from "../../store/theme-settings";
import { getTopicName, getSubtopicsDropdownFromState} from "../../selectors/theme-selector";
import { getTopicBlackListData } from "../../selectors/black-list-selector";
import { getTopicBlackList, setClearBlacklist, setBlacklistIsUpdated, removeFromBlackList } from "../../store/black-list";
import { profileFromBlackList } from "../../store/topic-item";

let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        topics: getTopicName(state),
        topicBlackList: getTopicBlackListData(state),
        tableHeader: state.BlackList.tableHeader,
        selectedTopics: state.Filters.selectedTopics,
        selectedTopicsIds: state.Filters.selectedTopicsIds,
        isUpdated: state.BlackList.isUpdated,
        isFetching: state.BlackList.isFetching
    }
}

export default connect(mapStateToProps, {getValue, getMyTopics, getTopicBlackList, setClearBlacklist, setBlacklistIsUpdated, profileFromBlackList, removeFromBlackList})(BlackListController)