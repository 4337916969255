import React, {useState} from "react";
import TableItem from "../../../../items/table/table";
import { StandartButton } from "../../../../commons/form-controls";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

const Telegram = (props) => {

    let [selectedItem, setSelectedItem] = useState(0);

    const selectTableITem = (item) => {
        if(selectedItem !== item){
            setSelectedItem(item);
        }
    }

    const createDigest = (type) => {
        props.onTableItemsClick(type)
    }

    const addItemButton = <StandartButton title={props.getValue('CREATE_TELEGRAM_NOTIFICATIONS')} icon={PlusIcon} fill='white' callback={createDigest.bind(this, "create")}/>

    return(
        <div>
            {<TableItem 
                        addItemButton={addItemButton} 
                        data={[props.tableHeader, props.data]}
                        hiddenColumns={props.hiddenColumns}
                        getValue={props.getValue}
                        selectedItem={selectedItem}
                        selectItem={selectTableITem}
                        titleText={props.getValue('NOTIFICATIONS')}/>}
        </div>
        
    )
}

export default Telegram;

