import RightMenuController from "./right-menu-controller";
import { connect } from "react-redux";
import { setShowRightMenu } from "store/side-bar";
import { getValue } from "store/languages";

let mapStateToProps = (state) => {
    return {
        showRightMenu: state.SideBarMenu.showRightMenu,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent
    }
}

export default connect(mapStateToProps, {getValue, setShowRightMenu})(RightMenuController)