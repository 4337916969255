import axios from "axios";

export const header = (url) => {
    return axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL}${url}`,
        headers: { 'Accept-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone},
        data: {}
    })
}

export const getLanguage = () => {
    let lang = sessionStorage.getItem('selectedLanguage');
    switch(lang){
        case 'RUS':
            return 'ru';
        case 'ENG':
            return 'en'
        case 'KAZ':
            return 'kk';
        default:
            return 'ru'
    }
}

export const getClauses = (filters) => {
    let search = {}
    search = {...filters.clauses}

    if(!search[0]){
        search = Object.entries(search).map(k => {
            let subclauses = (k[1] && Object.entries(k[1]).length) ? Object.entries(k[1]).map(p => {
                                                                    let pr = {searchExpression: p[0]}
                                                                    let sb = (p[1] && Object.entries(p[1]).length) ? Object.entries(p[1]).map(f => {return {searchExpression: f[1]}}) : null
                                                                    if(sb) pr['subclauses'] = sb
                                                                    return pr

                                                                }) : null
            let params = {searchExpression: k[0]}
            if(subclauses) params['subclauses'] = subclauses
            return {...params}
        })
        if(filters.clauses){
            filters.clauses = search
        }
    }
    return filters
}

export const getClausesFromFilter = (filters) => {
    let search = {}
    search = {...filters.clauses}
    let result = {}
    let rt = []
    if(search[0]){
        rt = Object.values(search).forEach( k => {
            result[k.searchExpression] = {}
            k.subclauses && k.subclauses.length && k.subclauses.forEach(p => {
                result[k.searchExpression][p.searchExpression] = {}
                p.subclauses && p.subclauses.length && p.subclauses.forEach(f => {
                    result[k.searchExpression][p.searchExpression][f.searchExpression] = f.searchExpression
                })          
            })
        })
    }
    return result
}

export const checkFilters = (filters) => {
    if(filters.clauses){
        filters = getClauses(filters)
    }
    if(filters.verificationStatus && filters.verificationStatus.indexOf('ANY') !== -1){
        filters.verificationStatus = ['VERIFIED', 'RESTORED']
    }
    if(filters.language){
        let languageFilters = [...filters.language]
        let OthersIndex = languageFilters.indexOf("OTHERS");
        if(OthersIndex !== -1){
            //languageFilters = languageFilters.splice(0, OthersIndex)
            let others = ['kz', 'ru', 'en'];
            let filteredOthers = [];
            others.forEach((k,i) => {
                let f = languageFilters.indexOf(k)
                if(f === -1){
                    //if(languageFilters.indexOf('\\!'+k) )
                    filteredOthers.push('\\!'+k);
                }
            })
            if(filteredOthers.length)
            filters.language = filteredOthers

        }
    }
    if(filters.is_advertising || filters.isAdvertising){
        let sf = filters.is_advertising ? filters.is_advertising[0] : filters.isAdvertising[0]
        switch(sf){
            case 'WITHOUT_ADVERTISING':
                filters.isAdvertising = false;
                break;
            case 'WITH_ADVERTISING':
                filters.isAdvertising = true;
                break;
            case 'WITHOUT_ADVERTISING_FILTERS':
                filters.isAdvertising = null;
                break;
        }
    }
    if(filters.is_swearing || filters.isSwearing){
        let sf = filters.is_swearing ? filters.is_swearing[0] : filters.isSwearing[0]
        switch(sf){
            case 'WITHOUT_SWEARING':
                filters.isSwearing = false;
                break;
            case 'WITH_SWEARING':
                filters.isSwearing = true;
                break;
            case 'WITHOUT_SWEARING_FILTERS':
                filters.isSwearing = null;
                break;
        }

    }

    delete filters.till
    delete filters.since
    delete filters.status
    delete filters.grouping
    delete filters.is_swearing
    delete filters.is_advertising
    return filters
}