import React from "react";
import { StandartButton } from "../../../../../../commons/form-controls";
import { ReactComponent as CloseIcon } from "../../../../../../assets/icons/close.svg";
import s from "./day-month-item.module.scss";

const DayMonthItem = (props) => {

    const removeItem = () => {
        props.removeDayButton(props.item)
    }

    return (
        <div className={s.DayMonthItem}>
            <span>{props.index}.</span>
            <div className={s.dayItem}>
                <span>{props.item}</span>
            </div>
            <StandartButton width={34} icon={CloseIcon} stroke={'dark'} background="background" callback={removeItem.bind(this, props.index)}/>
        </div>

        )
}

export default DayMonthItem;