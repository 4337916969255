import React, { useState, useEffect} from "react";
import { ReactComponent as WarningTriangleIcon } from 'assets/icons/warning-triangle.svg';
import s from "./error-message.module.scss";

const ErrorMessage = (props) => {

    const [message, setMessage] = useState(props.message);

    useEffect(() => {
        setMessage(props.message)
    }, [props.message]);
    
    return (
        <div className={s.ErrorMessage}>
            <WarningTriangleIcon />
            <div className={s.ErrorMessageBlock}>
                <div className={s.errorLabel}>
                    <span>{props.getValue('ERROR')}</span>
                </div>
                
                <span>{message}</span>
            </div>
            
            
        </div>
    )
}

export default ErrorMessage;