import React, {useState} from "react";
import s from "./settings-menu.module.scss";
import SwitchAdminModeContainer from "./switch-admin-mode/switch-admin-mode-container";
import Preload from "items/preload/preload";
import Bar from "items/preload/bar";

const SettingsMenu = (props) => {

    const setMenu = (item) => {
        if(!props.isFetching)
        props.setSelectedMenu(item)
    }

    const settingsMenuItems = (props.items && props.items.length) ? props.items.map((item,i)=>{
        return item.show 
                        ? <div className={props.selected === i ? s.MenuItem+' '+s.activeMenu : s.MenuItem} key={i} onClick={setMenu.bind(this, i)}>
                            <span className={s.itemName}>{props.getValue(item.title)}</span>
                            {props.isFetching && props.selected === i ? <div className={s.status}><Bar /></div> : null }
                        </div> : <div key={i}></div>
    }) : []

    return (
        <div className={s.SettingsMenu}>
            {(props.role === 'ROLE_SUPER_ADMIN' || props.role === 'ROLE_ADMIN') && props.adminMode ? <SwitchAdminModeContainer /> : null}
            {settingsMenuItems}
        </div>
    )
}

export default SettingsMenu;