import React, {useEffect, useState} from "react";
import s from "./short-analytic.module.scss";
import ShortAnalyticItem from "./short-analytic-item/short-analytic-item";
import DropdownController from "../../items/dropdown/dropdown-controller";
import ShortAnalyticChartItemContainer from "./short-analytic-chart-item/short-analytic-chart-item-container";

import ShortAnalyticLineChartContainer from "./short-analytic-line-chart/short-analytic-line-chart-container";

import moment from 'moment';

const ShortAnalyticPage = (props) => {

    const getDate = () =>{    
        return {
            dateFrom: moment().startOf('day').toString(),
            dateTo: moment().endOf('day').toString(),
          }
    }

    const getTopicRowBackground = (length) => {
        let back = []
        for(let i = 0; i < length; i++){
            back[i] = <div className={s.AnalyticRow}>
                        <div className={s.AnalyticItem}></div>
                        <div className={s.AnalyticItem}></div>
                        <div className={s.AnalyticItem}></div>
                        <div className={s.AnalyticItem}></div>
                        <div className={s.AnalyticItem}></div>
                    </div>
        }
        return back
    }

    const [topicData, setTopicData] = useState(props.topicData);
    const [selectedSort, setSelectedSort] = useState('CREATED_AT DESC');
    const [topicUUID, setTopicUUID] = useState(props.topic && props.topic.uuid ? props.topic.uuid : null);
    const [dates, setDate] = useState(getDate());
    const [rerender, setRerender] = useState(false);
    const [allSeted, setAllSeted] = useState(false);
    const [topicsRows, setTopicsRows] = useState(null);
    const [otherTopics, setOtherTopics] = useState([]);
    const [start, setStart] = useState(false);

    useEffect(() => {
        setTopicData(props.topicData)
        setDate(getDate());
        setTopicUUID(props.topic && props.topic.uuid ? props.topic.uuid : null);
        //setRerender(!rerender);
    }, [props.topicData])

    const sortTopic = (type, i) => {
        setSelectedSort(i);
        let items = i.split(" ");
        props.getShortAnalyticTopics(items[0], items[1])

    }

    const getHeaderComponents = (headers, selected) => {
        let retHeaders = headers ? headers.map((item, i) => {
            return {title: props.getValue(item.title), short_name: props.getValue(item.short_name), name: props.getValue(item.name)};
        }) : null
        return <DropdownController
            items={retHeaders}
            name={retHeaders.name}
            callback={sortTopic}
            selected={selectedSort}
            icon={null}
            type={retHeaders.type}
            showName={'title'}
            short_name={retHeaders.short_names}
            color={'var(----echoTextPrimary)'}
        />
    }

    let headers = getHeaderComponents(props.headerItems, 'ALPHABETIC ASC')

    //let topicsRows = []

    const getTopic = (topic, i) => {
        return topic ? <div key={i} className={s.AnalyticRow}>
                    <ShortAnalyticItem topicId={topic.uuid} topicName={topic.name} createdAt={topic.createdAt} updatedAt={topic.updatedAt} getValue={props.getValue} filters={dates} setTreeSelectedUUId={props.setTreeSelectedUUId}/>
                    <ShortAnalyticChartItemContainer index={i} topicId={topic.uuid} topic={topic} topicIds={topic.uuid} mediaType={'MASS_MEDIA'} filters={dates}/>
                    <ShortAnalyticChartItemContainer index={i} topicId={topic.uuid} topic={topic} topicIds={topic.uuid} mediaType={'SOCIAL_MEDIA'} filters={dates}/>
                    <ShortAnalyticChartItemContainer index={i} topicId={topic.uuid} topic={topic} topicIds={topic.uuid} mediaType={'TOTAL'} filters={dates}/>
                    <ShortAnalyticLineChartContainer index={i} topicId={topic.uuid} topic={topic} filters={dates}/>
                </div> : null
    }

    const getTopicRows = (topicData, offset, length) => {
        let rows = [];
        for(let i = offset; i < length; i++){
            rows.push(getTopic(topicData[i], i))
        }
        return rows
    }

    const getOtherTopics = () => {
        let t = []
        t = otherTopics;
        if(t.length < (props.topicData.length - 6))
            t = getTopicRows(props.topicData, 6, props.topicData.length);
        
        setOtherTopics(t);
        setStart(true)
    }

    const getTopics = ()=> {
        let t = getTopicRows(props.topicData, 0, 6)
        setTopicsRows(t);
        if(t.length < props.topicData.length && !start){
            let timeOutId = setTimeout(() => {
                getOtherTopics();
                clearTimeout(timeOutId)
            }, 2000)
        }
        setAllSeted(true)
        setRerender(!rerender)
    }

    //if(!topicsRows) props.topicData && getTopics() 
    const topicsAll = topicData.map((k,i) => getTopic(k, i))

    return (
        <div className={s.ShortAnalytic}>
            <div className={s.shorAnalyticWrapper}>
                <div className={s.AnalyticRow}>
                    <div className={s.AnalyticHeaderItem}>
                        <span>{props.getValue('TOPICS')}</span>
                        {headers}
                    </div>
                    <div className={s.AnalyticHeaderItem}>
                        <span>{props.getValue('MASS_MEDIA')}</span>
                    </div>
                    <div className={s.AnalyticHeaderItem}>
                        <span>{props.getValue('SOC_MEDIA')}</span>
                    </div>
                    <div className={s.AnalyticHeaderItem}>
                        <span>{props.getValue('TOTAL_1')}</span>
                    </div>
                    <div className={s.AnalyticHeaderItem}>
                        <span>{props.getValue('DIAGRAMS')}</span>
                    </div>
                </div>
                {topicsAll}
                {/*otherTopics*/}
            </div>

        </div>
        )
}

export default ShortAnalyticPage;