import TopicButtonController from "./topic-button-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages"
import { getTopics } from "selectors/theme-selector";
import { setStartDate, setEndDate, setGrouping, setDateChanged, setGroupingByPeriod } from "store/calendar";
import { setSelectedMyFilterMenu, setMySelectedFilter, setMySelectedTopicsFilter, filtersChanged } from "store/filters"
import { setReturnBackFilters, setReturnBackPageName, setIsReturnedBack } from "store/return-back-button";
import HeaderButtons from 'assets/header-buttons/header-buttons'
import { setSelectedMenu, setSettingsMenuToNull } from "store/settings-menu"

let mapStateToProps = (state) => {
    return {
        postsCount: state.storage.storageData.numFoundTotal,
        selectedTopics: state.Filters.selectedTopics,
        selectedUUId: state.Tree.selectedUUId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        settingsMenuItems: state.settingsPageMenu.items,
        topicData: getTopics(state),
        pageName: state.ReturnBack.pageName,
        grouping: state.ReturnBack.grouping,
        filters: state.ReturnBack.filters,
        menuSelected: state.ReturnBack.menuSelected,
        isReturnedBack: state.ReturnBack.isReturnedBack,
        headerButtons: HeaderButtons
    }
}

const TopicButtonContainer = connect(mapStateToProps, {getValue, setStartDate, setEndDate, setGrouping, setMySelectedFilter, setMySelectedTopicsFilter, setSelectedMenu, setSettingsMenuToNull, filtersChanged, setReturnBackFilters, setReturnBackPageName, setIsReturnedBack})(TopicButtonController);
export default TopicButtonContainer
