import jwtDecode from "jwt-decode";
import { AuthoriztionService } from "../connection/authorization-service";
import { getMe, clearAccount } from "./account";
import { setShowPanel } from "./header";
import { NotificationService }  from "../connection/notification-service";
import { selectPage } from "./header";
import{ setNotification, getErrorMessage, getErrorCodeMessage } from "./errors";

const SET_AUTHORIZATION = "SET-AUTHORIZATION";
const SET_NEW_PAGE_NUMBER = 'SET_NEW_PAGE_NUMBER';
const SET_ERROR_IN_RESEST_PASSWORD = 'SET_ERROR_IN_RESEST_PASSWORD';
const SET_ERROR_IN_REGISTRATION = "SET-ERROR-IN-REGISTRATION";
const SET_ERROR_IN_LOGIN = "SET_ERROR_IN_LOGIN";
const SET_REGISTRATION_IS_SEND = "SET-REGISTRATION-IS-SEND";

let user = {};

const setToStorage = (type, token) => {
    switch(type){
        case 'access_token':
            localStorage.setItem("auth", token);
            break;
        case 'refresh_token':
            localStorage.setItem("refresh", token);
            break;
        default:
            break;
    }   
}

const removeFromStorage = (type) => {
    switch(type){
        case 'access_token':
            localStorage.removeItem("auth");
            break;
        case 'refresh_token':
            localStorage.removeItem("refresh");
            break;
        default:
            break;
    }
    
}

const getFromStorage = (type) => {
    switch(type){
        case 'access_token':
            return localStorage.getItem("auth");
        case 'refresh_token':
            return localStorage.getItem("refresh");
        default:
            return null;
    }
    
}

if(getFromStorage('access_token')){
    let auth = jwtDecode(getFromStorage('access_token'));
    if(auth.session_state){
        user = {
            user_name: auth.preferred_username,
            access_token: getFromStorage('access_token'),
            refresh_token: getFromStorage('refresh_token'),
            session_state: auth.session_state,
            token_type: auth.typ
        }
    }
}

let auth= {
    isAuth: user.access_token ? true : false,
    showPassword: false,
    pageTypeNumber: 0,
    errorInResetPassword: null,
    user_name: user.user_name ? user.user_name : null,
    access_token: user.access_token ? user.access_token : null,
    refresh_token: user.refresh_token ? user.refresh_token : null,
    session_state: user.session_state ? user.session_state : null,
    token_type: user.token_type ? user.token_type : null,
    registrationError: null,
    loginError: null,
    registrationIsSend: false
}

const authorization = (state = auth, action) => {
    switch(action.type){
        case SET_AUTHORIZATION:
            action.access_token ? setToStorage("access_token",action.access_token) : removeFromStorage('access_token');
            action.refresh_token ? setToStorage("refresh_token", action.refresh_token) : removeFromStorage('refresh_token');
            return {...state, isAuth: action.isAuth, access_token: action.access_token, refresh_token: action.refresh_token, session_state: action.session_state, token_type: action.token_type}
        case SET_NEW_PAGE_NUMBER:
            return {...state, pageTypeNumber: action.pageTypeNumber};
        case SET_ERROR_IN_RESEST_PASSWORD:
            return {...state, errorInResetPassword: action.errorInResetPassword}
        case SET_ERROR_IN_REGISTRATION:
            return {...state, registrationError: action.registrationError}
        case SET_ERROR_IN_LOGIN:
            return {...state, loginError: action.loginError}
        case SET_REGISTRATION_IS_SEND:
            return {...state, registrationIsSend: action.registrationIsSend }
        default:
            return state;
    }
}

export default authorization;

const setAuthorization = (data) => {
    return {
        type: SET_AUTHORIZATION,
        isAuth: data.isAuth,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        session_state: data.session_state,
        token_type: data.token_type
    }
}

export const setNewPageNumber = (pageTypeNumber) =>{
    return {
        type: SET_NEW_PAGE_NUMBER,
        pageTypeNumber: pageTypeNumber
    }
}

export const setErrorInResetPassword = (errorMessage) => {
    return {
        type: SET_ERROR_IN_RESEST_PASSWORD,
        errorInResetPassword: errorMessage
    }
}

export const setErrorInRegistration = (registrationError) => {
    return {
        type: SET_ERROR_IN_REGISTRATION,
        registrationError: registrationError
    }
}

export const setErrorInLogin = (loginError) => {
    return {
        type: SET_ERROR_IN_LOGIN,
        loginError: loginError
    }
}

export const setRegistrationIsSend = (registrationIsSend) => {
    return {
        type: SET_REGISTRATION_IS_SEND,
        registrationIsSend: registrationIsSend
    }
}

export const signin = (login, password) => {
    return (dispatch) => {
        AuthoriztionService.loginAccount(login, password).then(data=>{
            let loginData = {
                            isAuth: true, 
                            access_token: data.data.access_token, 
                            refresh_token: data.data.refresh_token, 
                            session_state: data.data.session_state, 
                            token_type: data.data.token_type,
                            user_name: login
                        }
            dispatch(setAuthorization(loginData));
            //dispatch(setShowPanel(true));
            setTimeout(()=>{dispatch(getMe());}, 500) 
        }).catch(error => {
            dispatch(setErrorInLogin(getErrorCodeMessage(error)))
            //dispatch(setErrorInLogin(getErrorMessage(error)))
        })
    }
}

export const signup = (login, password, name, email, phone) => {
    return (dispatch) => {
        AuthoriztionService.createAccount(login, name, email, phone, password).then(data=>{
            dispatch(setRegistrationIsSend(true));
            dispatch(setNotification({title: 'Регистрация', message: 'Пользователь успешно зарегестрирован'}));
            setTimeout(()=>{dispatch(signin(login, password))},500)
        }).catch(error => {
            
            if(error.response && error.response.data && error.response.data.ApiError)
                dispatch(setErrorInRegistration(error.response.data.ApiError.message));
            else
                dispatch(setErrorInRegistration(error.message));

        })
    }
}

export const resetPassword = (type, value) => {
    return (dispatch) => {
        AuthoriztionService.resetPassword({[type]: value}).then(data=>{

        }).catch(error => {
            dispatch(setErrorInResetPassword(error.message))
        })
    }
}

export const exit = () => {
    
    return (dispatch) => {
        dispatch(selectPage('/page/'))
        dispatch(setAuthorization({isAuth: false, access_token: null, refresh_token: null, session_state: null, token_type: null, user_name: null}));
        dispatch(clearAccount({}));
        dispatch(setShowPanel(false));
        dispatch(window.location.href = "/page")      
    }
}

export const getTopics = (token) => {
    return (dispatch) => {
        NotificationService.getTopicsNotifications(token).then(data=>{

        });
    }
}